import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box, Chip } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import newUi from '../styles/newUi';
import { formatTimestampUTCOnlyHoursAndMinutes } from '../functions/customFormat';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import mainStyles from '../styles/mainstyles';
import { customFormat } from '../functions/customFormat';



export function ChartSignalTable({ signals, data }) {
  const classes2 = mainStyles();
  const newUiClass = newUi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({});
  const [items, setItems] = useState([]);  // To hold the items to be displayed
  const [hasMore, setHasMore] = useState(true);  // To control loading state
  const itemsPerPage = 20;  // Number of items to load per page

  useEffect(() => {
    setItems(signals.slice(0, itemsPerPage));
  }, [signals]);

  const fetchMoreData = () => {
    if (items.length >= signals.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setItems(prevItems => prevItems.concat(signals.slice(prevItems.length, prevItems.length + itemsPerPage)));
    }, 1500);
  };

  function getDirection(signal) {
    if (signal.pattern_direction === 'Pullback Uptrend' || signal.pattern_direction === 'Oversold Uptrend' || signal.patter_direction === 'Morning Star') {
      return "BULLISH";
    } else if (signal.pattern_direction === 'Overbought Downtrend' || signal.pattern_direction === 'Shooting Star' || signal.pattern_direction === 'Hanging Man' || signal.pattern_direction === 'Bearish Engulfing' || signal.pattern_direction === 'Evening Star') {
      return "BEARISH";
    } else if (signal.pattern_direction === 'Bullish Engulfing') {
      return "BULLISH";
    } else {
      return null;
    }
  }


  function getSignal(signal) {
    if (signal.pattern_direction === 'Pullback Uptrend') {
      return "Pullback in Uptrend";
    } else if (signal.pattern_direction === 'Oversold Uptrend') {
      return "Oversold in Uptrend";
    } else if (signal.pattern_direction === 'Overbought Downtrend') {
      return "Overbought in Uptrend";
    } else if (signal.pattern_direction === 'Hanging Man') {
      return "Hanging Man";
    } else if (signal.pattern_direction === 'Shooting Star') {
      return "Shooting Star";
    } else if (signal.pattern_direction === 'Bullish Engulfing') {
      return "Bullish Engulfing";
    } else if (signal.pattern_direction === 'Bearish Engulfing') {
      return "Bearish Engulfing";
    } else if (signal.pattern_direction === 'Morning Star') {
      return "Morning Star";
    } else if (signal.pattern_direction === 'Evening Star') {
      return "Evening Star";
    } else {
      return null;
    }
  }

  const handleRowClick = (uniqueKey) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [uniqueKey]: !prevState[uniqueKey]
    }));
  };
  function getBacktestResults(symbol, timeframe, frontendValue) {
    if (!data) {
      return null;
    }
  
    const relevantData = data.find((item) => item.symbol === symbol && item.timeframe === timeframe);
    const formattedValue = frontendValue.replace(/ /g, '_');
  
    if (relevantData) {
      const parsedData = JSON.parse(relevantData.backtest_data);
      const signalsArray = parsedData.signals;
      const signalKey = formattedValue;
  
      if (signalKey) {
        const specificSignal = signalsArray.find((signalObject) => signalObject.Signal === signalKey);
        if (specificSignal) {
          const probability = specificSignal["Best Probability (%)"];
          const profit = specificSignal["Best Average Change"];
          const amount = specificSignal["Detected Signals"];
          const days = specificSignal["Best Days"];
          let text1 = ""
          if (amount > 1) {
             text1 = `The signal has a win rate of ${probability}% based on ${amount} historic signals.`}
          let text2 = ""
          let text3 = ""
          
            if (days != undefined) {
              text3 = " - "
          text2 = `Historically, best results were achieved after ${days} days with average gains of ${profit}.`;
            }
          let text = text1 + text3 + text2
          return text;
        }
      }
    }
  
    return "";
  }
  

  function getBacktestRating(symbol, timeframe, frontendValue) {
    if (!data) return { label: "", color: "grey" };
    const relevantData = data.find((item) => item.symbol === symbol && item.timeframe === timeframe);
    const formattedValue = frontendValue.replace(/ /g, '_'); // Replace spaces with underscores

    if (relevantData) {
      const parsedData = JSON.parse(relevantData.backtest_data);
      const signalsArray = parsedData.signals;
      const signalKey = formattedValue;
      if (signalKey) {
        const specificSignal = signalsArray.find((signalObject) => signalObject.Signal === signalKey);
        if (specificSignal) {
          const helper = specificSignal["Best Probability (%)"];
          if (helper >= 80) {
            return { label: "HIGHEST PROBABILITY", color: "darkgreen" };
          } else if (helper >= 70) {
            return { label: "HIGH PROBABILITY", color: "green" };
          } else if (helper >= 60) {
            return { label: "MODERATE PROBABILITY", color: "gold" };
          } else if (helper >= 50) {
            return { label: "LOW PROBABILITY", color: "orange" };
          } else {
            return { label: "POOR PROBABILITY", color: "red" };
          }
        }
      }
    }
    return { label: "", color: "grey" };
  }
  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      endMessage={<p style={{ textAlign: 'center' }}><b>Yay! You have seen it all</b></p>}
    >
      <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
        {items.map((row, index) => {
          const isFirstInRow = index % 3 === 0;
          const isThirdInRow = index % 3 === 2;
          const uniqueKey = `${row.symbol}-${index}`; // Create a unique key
          const backtestText = getBacktestResults(row.symbol, row.timeframe, row.pattern_direction)
          const backtestRating = getBacktestRating(row.symbol, row.timeframe, row.pattern_direction);

          return (
            <Grid item xs={12} md={4} key={uniqueKey} style={{
              paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px',
              paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
              alignItems: 'stretch'
            }}>
              <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => handleRowClick(uniqueKey)}>
                <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ flexShrink: 0 }}>
                    <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                  </div>
                  <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                    <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                      <b>{row.symbol}</b><br />
                      <span style={{ color: '#AAAAAA', fontSize: '0.8em' }}>
                        {row.fullName}
                      </span>
                    </Typography>
                  </div>
                </div>
                <div>
                  <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                    <Chip className={newUiClass.standardChip} style={{ background: '#F5F7FE' }} label={getSignal(row)} />
                    <Chip className={newUiClass.standardChipColorless} style={{ background: getDirection(row) === "BEARISH" ? "darkred" : "darkgreen" }} label={getDirection(row)} />
                    <Chip className={newUiClass.standardChip1} style={{ background: backtestRating.color,  }} label={backtestRating.label} />
                  </Box>
                </div>
                
                <LazyLoadImage
                  alt="signal"
                  effect="blur"
                  src={row.url}
                  style={{ width: '100%', marginLeft: '-15px' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                    Detected: {formatTimestampUTCOnlyHoursAndMinutes(row.timestamp)} UTC
                  </Typography>
                </div>
                {expandedRows[uniqueKey] && (
                  <>
                  <div style={{ marginTop: '25px' }}>
                      <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Backtest</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                        {backtestText}
                      </Typography>
                    </div>
                  
                    <div style={{ marginTop: '25px' }}>
                      <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Current Price (24h Change)</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(row.pctChange) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
                        <span style={{ paddingRight: '5px', fontWeight: '600' }}>${customFormat(row.price)}</span>({customFormat(row.pctChange)}%)
                      </Typography>
                    </div>
                    <MinimizedTokenInfo token={row.symbol} />
                  </>
                )}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScroll>
  );
}

export default ChartSignalTable;
