import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Avatar, ListItem, ListItemAvatar, ListItemText, Link } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import newUi from '../../styles/newUi';
import { settings } from '../../elements/carousel';
import { Link as RouterLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

export const LatestBlogPosts = () => {
  const classes2 = newUi();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setError('');
        setLoading(true);
        const response = await axios.get('/api/latest-blog-messages');
        setMessages(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchMessages();
  }, []);

  const extractContent = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
  
    // Extract the <h3> text
    const h3Element = tempDiv.querySelector('h3');
    let h3Text = '';
    if (h3Element) {
      h3Text = h3Element.textContent || h3Element.innerText;
      h3Element.remove(); // Remove the <h3> from the content to avoid duplication
    }
  
    // Get the remaining text content and reduce it to 20 words
    const remainingText = (tempDiv.textContent || tempDiv.innerText || '')
      .split(' ')
      .slice(0, 20)
      .join(' ') + '...';
  
    return { h3Text, remainingText };
  };
  
  
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box className={classes2.mainPageBox} style={{ paddingTop: '0px' }} >
      <Carousel {...settings}>
        {messages.map((message, index) => {
          const { h3Text, remainingText } = extractContent(message.content);

          return (
            <Box key={index} className={classes2.mainPageBox}>
              <Typography className={classes2.mainPageBoxTitle}>
                Latest Content
              </Typography>
              <ListItem style={{ paddingTop: '4px', paddingBottom: '4px', alignItems: 'flex-start' }}>
                <ListItemAvatar>
                  <Avatar src={message.logo_url} style={{ width: '30px', height: '30px', marginTop: '5px' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography component="h3" style={{ color: '#1a1a1a', fontWeight: 'bold', fontSize: '0.95em', marginBottom: '8px' }}>
                        {message.username}
                      </Typography>
                      <Typography component="h3" style={{ fontWeight: '600', fontSize: '0.75em', marginBottom: '8px', color: '#1a1a1a' }}>
                        {h3Text}
                      </Typography>
                
                    </>
                  }
                  secondary={
                    <>
                      <Typography style={{ color: '#555', fontSize: '0.75em', marginBottom: '8px' }}>
                        {remainingText}
                      </Typography>
                      <Link component={RouterLink} to={`/tokens/${message.symbol}`} style={{ fontSize: '0.75em', color: '#3961FB' }}>
                        Read more
                      </Link>
                      {message.attachment_file_path && (
                        message.attachment_file_type === 'jpg' ? (
                          <img
                            src={message.attachment_file_path}
                            alt="attachment"
                            className={classes2.image}
                            style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                          />
                        ) : (
                          <video
                            src={message.attachment_file_path}
                            controls
                            className={classes2.video}
                            style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                          />
                        )
                      )}
                    </>
                  }
                  style={{ color: '#fff' }}
                />
              </ListItem>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );};

export default LatestBlogPosts;
