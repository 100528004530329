export const createMappings = (classes2) => ({
    trendClassMapping: {
        'Strong Uptrend': classes2.trendUpChip1,
        'Uptrend': classes2.trendUpChip1,
        'Neutral': classes2.neutralBotChip,
        'Downtrend': classes2.trendDownChip1,
        'Strong Downtrend': classes2.trendDownChip1,
        '': classes2.noShow,
    },
     adxClassMapping: {
        'Strong Trend': classes2.trendUpChip1,
        'Light Trend': classes2.neutralBotChip,
        'Weak': classes2.trendDownChip1
    },
     rsi1ClassMapping: {
        'Oversold': classes2.trendUpChip1,
        'Neutral': classes2.neutralBotChip,
        'Overbought': classes2.trendDownChip1
    },
     rsiClassMapping: {
        'Uptrend': classes2.trendUpChip1,
        'Neutral': classes2.neutralBotChip,
        'Downtrend': classes2.trendDownChip1,
        'Overbought': classes2.trendDownChip1,
        'Oversold': classes2.trendUpChip1
    },
     macdClassMapping: {
        'Bullish': classes2.trendUpChip1,
        'Bearish': classes2.trendDownChip1
    },
     patternClasseMapping : {
        'Double Top': classes2.trendDownChip1,
        'Tripple Top': classes2.trendDownChip1,
        'Double Bottom': classes2.trendUpChip1,
        'Triple Bottom': classes2.trendUpChip1,
        'Three Black Crows': classes2.trendDownChip1,
        'Wedge': classes2.neutralBotChip,
        'Triangle': classes2.neutralBotChip,
     }});
     
     
     export const emaMappings = [
        { label: "Short EMA", key: "short_ema" },
        { label: "Medium EMA", key: "medium_ema" },
        { label: "Long EMA", key: "long_ema" },
        { label: "Short SMA", key: "short_sma" },
        { label: "Medium SMA", key: "medium_sma" },
        { label: "Long SMA", key: "long_sma" },
        { label: "Short WMA", key: "short_wma" },
        { label: "Medium WMA", key: "medium_wma" },
        { label: "Long WMA", key: "long_wma" },
    ];
    
    export const smaMappings = [
        { label: "Short SMA", key: "short_sma" },
        { label: "Medium SMA", key: "medium_sma" },
        { label: "Long SMA", key: "long_sma" },
    ];

    export const wmaMappings = [
        { label: "Short WMA", key: "short_wma" },
        { label: "Medium WMA", key: "medium_wma" },
        { label: "Long WMA", key: "long_wma" },
    ];
    