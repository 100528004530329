import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, CircularProgress, Typography } from "@material-ui/core";
import { SelectControl } from "../elements/SelectControl";
import { addMultiplePrices2 } from "../functions/addPrices";
import { limitResultsForTier } from "../functions/subscriptionCheck";
import newUi from "../styles/newUi";
import Alert from '@mui/material/Alert';
import ChartSignalTable from "../elements/chartSignalTable";
import { ChartSignalDashboard } from "./dashboards/chartSignalDashboard";
import { AuthContext } from '../AuthContext';


function ChartSignals() {
  const newUiClass = newUi();
  const [data, setData] = useState([]);
  const { auth, logout } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPriceLoading, setIsPriceLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filters, setFilters] = useState({ date: "today", timeframe: "1H" });
  const [totalElements, setTotalElements] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [helper2, setHelper2] = useState(false);
  const [sortOrder, setSortOrder] = useState('Latest');
  const [open1, setOpen1] = useState(false);
  const [additionalFilter, setAdditionalFilter] = useState('all');
  const [backtestData, setBacktestData] = useState([]);

  const toggleDrawer = () => { setOpen1(!open1); };

  useEffect(() => { window.scrollTo(0, 0); }, []);


  useEffect(() => {
    setIsLoading(true);
    
    Promise.all([
      fetch('/api/screener2').then((res) => res.json()),
      fetch('/api/chart-backtest').then((res) => res.json())
    ])
      .then(([screenerData, backtestData]) => {
        // Handle screener data
        const validScreenerData = screenerData.filter(item => item.symbol !== null);
        const formattedScreenerData = validScreenerData.map((item) => ({
          ...item,
          detectedDate: new Date(item.timestamp),
        }));
        setTotalElements(formattedScreenerData.length);
        setData(formattedScreenerData);
        applyFilters(formattedScreenerData);
  
        if (backtestData.error) { console.error(backtestData.error);
        } else {  setBacktestData(backtestData); }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Failed to load data.');
        setOpen(true);
      })
      .finally(() => setIsLoading(false));
  }, []);
  


  useEffect(() => {
    if (isLoading == true) {return;}
    applyFilters(data);
  }, [filters, data, sortOrder, additionalFilter]);

  const sortData = (data) => {
    switch (sortOrder) {
      case 'Latest':
        return data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      case 'Highest Success Rate':
        return data.sort((a, b) => b.backtest_value - a.backtest_value);
      case 'Most signals':
        return data.sort((a, b) => b.backtest_amount - a.backtest_amount);
      case 'Alphabetical':
        return data.sort((a, b) => a.symbol.localeCompare(b.symbol));
      default:
        return data;
    }
  };

  const applyFilters = async (dataToFilter) => {
    setIsLoading(true);
    setHelper2(false);

    let filtered = dataToFilter.filter(item => {
      const timeframeMatch = filters.timeframe === 'all' || item.timeframe === filters.timeframe;
      const additionalFilterMatch = additionalFilter === 'all' ||
        (additionalFilter === 'pullback_uptrend' && item.pattern_direction === 'Pullback Uptrend') ||
        (additionalFilter === 'rsi_trend_oversold' && item.pattern_direction === 'Oversold Uptrend') ||
        (additionalFilter === 'rsi_trend_overbought' && item.pattern_direction === 'Overbought Downtrend') ||
        (additionalFilter === 'hanging_man' && item.pattern_direction === 'Hanging Man') ||
        (additionalFilter === 'shooting_star' && item.pattern_direction === 'Shooting Star') ||
        (additionalFilter === 'bullish_engulfing' && item.pattern_direction === 'Bullish Engulfing') ||
        (additionalFilter === 'hammer' && item.pattern_direction === 'Hammer') ||
        (additionalFilter === 'morning_star' && item.pattern_direction === 'Morning Star') ||
        (additionalFilter === 'evening_star' && item.pattern_direction === 'Evening Star') ||
        (additionalFilter === 'bearish_engulfing' && item.pattern_direction === 'Bearish Engulfing');
      
      return  timeframeMatch && additionalFilterMatch;
    });

    filtered = sortData(filtered);
    const limitedData = limitResultsForTier(filtered, hasAccess);
    /// PROD
    
    setFilteredData(limitedData);
    const dataWithPrices = await addMultiplePrices2(limitedData);
    setFilteredData(dataWithPrices);
    setIsPriceLoading(false);
    setIsLoading(false);
  };

  const handleFilterChange = (filterName, filterValue) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  return (
    <div>
      <div style={{ paddingLeft: '30px', paddingTop: '15px', marginBottom: '25px' }}>
        <Typography className={newUiClass.componentTitle}>Chart Signals</Typography>
        <Typography className={newUiClass.componentSubtitle}><i>Discover the latest chart pattern signals.</i></Typography>
      </div>
      <Grid container spacing={2} className={newUiClass.headerGrid} style={{ marginBottom: '20px', marginTop: '10px' }}>
        {(!hasAccess) && <Alert className={newUiClass.infoBar} severity="warning">Results are limited. Please login or sign up to use this feature.</Alert>}
        <ChartSignalDashboard />
      </Grid>
      
      <div style={{ marginLeft: '15px' }}>
        <div className={newUiClass.filterBox} style={{ marginLeft: '10px' }}>
          <Grid container spacing={2} className={newUiClass.filterGrid}>
            <Grid item xs={12} md={12}>
              <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
              <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
            </Grid>
            <Grid item xs={12} md={6} style={{ width: '100%' }}>
              <SelectControl label="Chart Pattern" value={additionalFilter} onChange={(e) => setAdditionalFilter(e.target.value)}
                options={[
                  { value: "all", label: "All" },
                  { value: "pullback_uptrend", label: "Bullish Pullback" },
                  { value: "rsi_trend_oversold", label: "RSI Oversold Uptrend" },
                  { value: "rsi_trend_overbought", label: "RSI Overbought Downtrend" },
                  { value: "hanging_man", label: "Hanging Man" },
                  { value: "shooting_star", label: "Shooting Star" },
                  { value: "bullish_engulfing", label: "Bullish Engulfing" },
                  { value: "bearish_engulfing", label: "Bearish Engulfing" },
                  { value: "evening_star", label: "Evening Star" },
                  { value: "morning_star", label: "Morning Star" },
                  { value: "hammer‚", label: "Hammer" },

                ]}
              />
            </Grid>
         
            <Grid item xs={12} md={3} style={{ width: '100%' }}>
              <SelectControl label="Timeframe" value={filters.timeframe} onChange={(e) => handleFilterChange('timeframe', e.target.value)}
                options={[{ value: "1D", label: "1D" }, { value: "1H", label: "1H" } ]}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ width: '100%' }}>
              <SelectControl label="Sort By" value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                options={[
                  { value: "Latest", label: "Latest" },
                  { value: "Alphabetical", label: "Alphabetical" }
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
       <ChartSignalTable signals={filteredData} data={backtestData} />
      {(isLoading || isPriceLoading) &&
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '40px', marginBottom: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
          <CircularProgress />
        </div>
      }
    </div>
  );
}

export default ChartSignals;
