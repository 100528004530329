import React, {useState, useEffect, useContext} from 'react';
import {Grid, Button, TextField, InputAdornment, Typography, FormControl, Paper, Tooltip, Chip, Snackbar } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import { AuthContext } from '../../AuthContext';
import { getKeys } from '../../functions/tradingData';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import newUi from '../../styles/newUi';
import { SelectControlPlusLogos, SelectControl } from '../../elements/SelectControl';
import { backtestStrategyTranslatorThree, botStrategies,  } from '../../data/backtestTranslator';
import { signalDirectionTimeline3 } from '../../data/signalMapping';
import BotTable from '../../elements/botTable';


const CreateBot = ({ onEventTrigger }) => {
    const { auth, logout } = useContext(AuthContext);
    const newUiClass = newUi();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackbarMessage, setSnackbarMessage] = useState();
    let email = auth?.user?.email || '';
    const [isShown, setIsShown] = useState(false);
    const [ready, setReady] = useState(false);
    const [chartTimeframe, setChartTimeframe] = useState('1D');
    const hasAccess = auth?.isAuthenticated;
    const [exchange, setExchange] = useState();
    const [privateKey, setPrivateKey] = useState();
    const [secretKey, setSecretKey] = useState();
    const [totalPrice, setTotalPrice] = useState(25); 
    const [takeProfit, setTakeProfit] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [symbol, setSymbol] = useState("");
    const [token, setToken] = useState([]);
    const [directionTrade, setDirectionTrade] = useState('');
    const [backtestData, setBacktestData] = useState([]);
    const [filteredSignals, setFilteredSignals] = useState([]);
    const [selectedSignal, setSelectedSignal] = useState();
    const [availableExchanges, setAvailableExchanges] = useState([]);

    const handleTokenChange = (event) => { setSymbol(event.target.value); };
    const handleChartTimeframeChange = (event) => { setChartTimeframe(event.target.value); };
    
    const handleSelect = (signal, direction) => {   
        openTrade(signal, direction);
     };
    

   
    const renderTokenOption = (option) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={option.logo_url} style={{ width: '25px', marginRight: '10px' }} />
            <span style={{ paddingLeft: '10px', fontSize: '0.9em', fontWeight: '500' }}>{option.label}</span>
        </div>
    );


    useEffect(() => {
        if (!symbol || !chartTimeframe || !backtestData) {
            if (filteredSignals.length !== 0) setFilteredSignals([]); 
            return;
        }
    
        const backtestEntry = backtestData.find(b => b.symbol === symbol && b.timeframe === chartTimeframe);
        if (!backtestEntry) {
            if (filteredSignals.length !== 0) setFilteredSignals([]); 
            return;
        }
    
        const signals = JSON.parse(backtestEntry.backtest_data).signals;
    
        const filteredSignalsUpdated = signals.filter(sig => {
            const readableSignalName = backtestStrategyTranslatorThree[sig.Signal] || sig.Signal;
            return !readableSignalName.includes('+ ADX');
        }).map(sig => {
            const readableSignalName = backtestStrategyTranslatorThree[sig.Signal] || sig.Signal;  
            return {
                value: sig.Signal || '',
                label: readableSignalName,  
                successRate: sig['Best Probability (%)'] || 0,  
                signals: sig['Detected Signals'] || 0 
            };
        });
    
        if (JSON.stringify(filteredSignals) !== JSON.stringify(filteredSignalsUpdated)) {
            setFilteredSignals(filteredSignalsUpdated);  
        }
    }, [symbol, chartTimeframe, backtestData, filteredSignals]);
        

    useEffect(() => {
        let helper = backtestStrategyTranslatorThree[selectedSignal]
        let direction = signalDirectionTimeline3[helper]
        setDirectionTrade(direction)
    }, [selectedSignal])
    

    useEffect(() => {
        fetch('https://app.crypto-os.pro/api/cryptologos')
            .then((res) => res.json())
            .then((data) => {
            if (data.error) {  console.error(data.error); } 
            else { 
                setToken(data.map(item => ({ symbol: item.symbol, logo_url: item.logo_url }))); } });
    }, []);


    useEffect(() => {
        fetch('https://app.crypto-os.pro/api/available-exchanges')
            .then((res) => res.json())
            .then((data) => {
            if (data.error) {  console.error(data.error); } 
            else { setAvailableExchanges(data)
            }; })
    }, []);

    useEffect(() => {
        if (!token || !availableExchanges || !exchange) { return; }
        if (token.length > 0 && availableExchanges.length > 0 && exchange) {
          const exchangeKey = exchange.toLowerCase(); 
          const filteredTokens = token.filter(token => {
            const exchangeInfo = availableExchanges.find(e => e.symbol === token.symbol);
            return exchangeInfo && exchangeInfo[exchangeKey] === 1;
          });
          setToken(filteredTokens);  
        }
      }, [exchange]);
      

    useEffect(() => {
        fetch('https://app.crypto-os.pro/api/get-all-backtest')
            .then((res) => res.json())
            .then((data) => {
            if (data.error) {  console.error(data.error); } 
            else { setBacktestData(data) }});
    }, []);
    
    useEffect(() => {
      const fetchData = async () => { 
        const keysData = await getKeys(email);
        setExchange(keysData.exchange);
        setPrivateKey(keysData.privateKey);
        setSecretKey(keysData.secretKey);  
        if(keysData.exchange == '') {
           alert("Please configure your exchange before executing a trade. Go to Settings/Exchange Settings")
        }
        setReady(true)     
      };      
      fetchData();
    }, [email]);

    useEffect(() => {
      if (!symbol || !exchange) { return; }  
      fetch(`https://app.crypto-os.pro/api/checkSymbolExchange?symbol=${encodeURIComponent(symbol)}&exchange=${encodeURIComponent(exchange)}`)
        .then(response => {
          if (!response.ok) { throw new Error('Network response was not ok'); }
          return response.json();
        })
        .then(data => { if (!data.available) { alert("This token is not available for trading on your exchange. Your bot won't trigger any successful trades.") }  })
        .catch(error => { console.error('Fetch Error:', error); })
    }, [symbol, exchange]);

    
    const handleTotalPriceChange = (e) => {
      const newTotalPrice = e.target.value === '' ? '' : Number(e.target.value);
      setTotalPrice(newTotalPrice);
    };

    const handleTakeProfitChange = (e) => {
      const newTakeProfit = e.target.value === '' ? '' : parseFloat(e.target.value).toFixed(2);
      setTakeProfit(newTakeProfit);
    };
    
    const handleStopLossChange = (e) => {
      const newStopLoss = e.target.value === '' ? '' : parseFloat(e.target.value).toFixed(2);
      setStopLoss(newStopLoss);
    };

    function showSnackBar(severity, text) {
        setSnackbarSeverity(severity);
        setSnackbarMessage(text);
        setIsShown(true);
    }

    const openTrade = (signal, direction) => {
    const shortSignal = botStrategies[signal] || signal;
      if (!symbol || symbol === '') {
        showSnackBar('warning', 'Please select a token!')
        return
    }

    if (!chartTimeframe || chartTimeframe === '') {
        showSnackBar('warning', 'Please select a trade timeframe!');
        return
    }

    if (!shortSignal || shortSignal === '') {
        showSnackBar('warning', 'Please select a trade signal!');
        return
    }
      setIsLoading(true);
      
        
      if (!hasAccess) {
        showSnackBar('warning', 'Trading features are available in Trading Tier only. Please upgrade.')
        setIsLoading(false);
        return
      }

      if (!privateKey || !secretKey || !exchange) {
        showSnackBar('warning', 'Please configure your exchange before executing a trade. Go to Settings/Exchange Settings')
        setIsLoading(false);
        return
      }     
      

      const finalTakeProfit = takeProfit / 100;
      const finalStopLoss = stopLoss / 100;

      fetch('https://app.crypto-os.pro/api/addBot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          email: email,
          symbol: symbol,
          direction: direction,
          timeframe: chartTimeframe,
          alert: shortSignal,
          details: [{
            amount: totalPrice,
            direction: directionTrade,
            stopLoss: finalStopLoss,
            takeProfit: finalTakeProfit
          }]
        })
      })
      .then(response => {
        if (!response.ok) {
          const message = 'That did not work. Please try again or contact Support.';
          showSnackBar('warning', message);
          throw new Error(message); // Stop the promise chain by throwing an error
        }
        return response.json(); 
      })
      .then(data => {
        
        showSnackBar('success', data.message);
        onEventTrigger();
      })
      .catch((error) => { 
        console.error('Fetch Error:', error);
        // Ensure the error message is a string
        showSnackBar('error', error.message || 'An unexpected error occurred');
      })
      .finally(() => {
        setIsLoading(false);
      });
    }

    
  
  return (
    <div style={{width: '100%', }}>
        <div className={newUiClass.filterBox} style={{width: '99%', marginRight: '25px'}}>
            <Grid container spacing={2} fullWidth className={newUiClass.filterGrid} style={{paddingLeft: '5px', }}>
                <Grid item xs={12} md={3}>
                    <form style={{maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
                        <FormControl id='myFormControl'  fullWidth  >
                            <Paper style={{ boxShadow: 'none'}}>
                                {(token && token.length > 0) && (
                                    <SelectControlPlusLogos label="Token" value={symbol} onChange={handleTokenChange}
                                        options={token.map(t => ({ value: t.symbol, label: t.symbol, logo_url: t.logo_url }))}
                                        renderOption={renderTokenOption}
                                    />
                                )}
                            </Paper>
                        </FormControl>
                    </form>
                </Grid> 
                <Grid item xs={12} md={3}>
                    <SelectControl label="Chart Timeframe" value={chartTimeframe} onChange={handleChartTimeframeChange} options={[{ value: "1D", label: "1D" }, {value: "1H", label: "1H"}, {value: "15M", label: "15M"}]} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <div>
                        <TextField fullWidth  type="number" value={totalPrice} onChange={handleTotalPriceChange} variant="outlined" margin="normal" className={newUiClass.inputField} style={{marginTop: '0px',border: '1px solid #ddd', borderRadius: '5px'}}
                                InputProps={{ inputProps: { step: "any",  },
                                className: newUiClass.inputBase,
                                
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon className={newUiClass.inputIcon}  /> <b>Trade Size</b>
                                    </InputAdornment>
                                ),
                                }}
                            />        
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Tooltip title={exchange === 'BINANCE' ? '' : 'This option is not available for your exchange.'}>
                        <span>
                            <TextField  fullWidth  style={{marginTop: '0px'}}  type="number"  value={takeProfit}   onChange={handleTakeProfitChange} 
                                variant="outlined"  margin="normal" disabled={exchange !== 'BINANCE'}  
                                className={newUiClass.inputField}
                                InputProps={{  inputProps: { step: "0.01", min: "0" }, className: newUiClass.inputBase,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <b>TP %</b>
                                        </InputAdornment>
                                        ),
                                    }}
                            />
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Tooltip title={exchange === 'BINANCE' ? '' : 'This option is not available for your exchange.'}>
                        <span>
                            <TextField  style={{marginTop: '0px'}} fullWidth  type="number"  value={stopLoss}  onChange={handleStopLossChange} 
                                variant="outlined"  margin="normal" disabled={exchange !== 'BINANCE'}  className={newUiClass.inputField}
                                InputProps={{ inputProps: { step: "0.01", min: "0" },
                                    className: newUiClass.inputBase,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <b>SL %</b>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
        <BotTable data ={filteredSignals} timeframe={chartTimeframe} onSelect={handleSelect} />
        {selectedSignal && <Alert severity="warning">You are creating a <b>{symbol}</b> bot on the <b>{chartTimeframe} chart</b>. The bot will execute <b>{directionTrade}</b> orders whenever the <b>{backtestStrategyTranslatorThree[selectedSignal]}</b> signal is identified.</Alert>}
        
            <Snackbar  open={isShown} autoHideDuration={6000} onClose={() => setIsShown(false)}>
                <Alert  onClose={() => setIsShown(false)}  severity={snackbarSeverity}  sx={{ width: '100%' }} >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    
    );
};

export default CreateBot;
