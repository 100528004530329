import React, { useState, useEffect, useContext } from "react";
import Snackbar from '@mui/material/Snackbar';
import { TextField, Typography, InputAdornment, Grid, IconButton, Container, Chip, AppBar, Toolbar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from '@material-ui/core';
import newUi from "../styles/newUi";
import { standardHighlight } from '../data/basics';
import Lock from "@mui/icons-material/Lock";
import CloseIcon from '@material-ui/icons/Close'; 
import { AuthContext } from '../AuthContext';

function Preferences({ isOpen, onClose }) {
    const newUiClass = newUi();
    const { auth, logout } = useContext(AuthContext);
    let email = auth?.user?.email || 'none';
    const hasAccess = auth?.isAuthenticated;
    const navigate = useNavigate();
    const availableExchanges = ['BLOFIN', 'BINANCE', 'BYBIT', 'MEXC'];
    const handleClose3 = () => { setOpen1(false); };
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleClickOpen = () => { setOpen1(true); };
    const handleClose2 = () => { setOpen1(false); };
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [privateKey, setPrivateKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [selectedExchange, setSelectedExchange] = useState('');
    const [passphrase, setPassphrase] = useState('');
    const [nickname, setNickname] = useState('');

    useEffect(() => {
      fetch('/api/get-keys', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ mail: email, })   
      })
      .then(response => {
          if (response.status === 404) { return { privateKey: '', secretKey: '' }; }
          if (!response.ok) { throw new Error(`HTTP error! Status: ${response.status}`); }
          return response.json(); // Only call json() once
      })
      .then(data => {
          if (data.privateKey) { setPrivateKey(data.privateKey); } 
          else { setPrivateKey(''); }
          if (data.secretKey) { setSecretKey(data.secretKey); } 
          else { setSecretKey(''); }
          if (data.exchange) { setSelectedExchange(data.exchange);}
          else { setSecretKey('');} // This seems like a typo. Did you mean setSelectedExchange('')?
          if (data.passphrase) { setPassphrase(data.passphrase);}
      })
      .catch((error) => { console.error('Fetch Error:', error); });
  }, [email]);

  useEffect(() => {  
    const fetchDisplayName = async () => {
      try {
        const response = await fetch(`/api/get-display-name/${email}`, {
          method: 'GET', headers: { 'Content-Type': 'application/json' }});
        if (response.status === 404) {
          return;
        }
        if (!response.ok) { throw new Error(`HTTP error! Status: ${response.status}`); }
        const data = await response.json();
        if (data.display_name) { setNickname(data.display_name);}
      } catch (error) { console.error('Fetch Error:', error); }
    };
    if (email) { fetchDisplayName(); }
  }, [email]);

const handleSubmit = async () => {
  if (!email || email === 'none') {
    setMessage("Trading features are available for registered users only. Please login or register.");
    setOpen(true);
    return;
  }
  fetch('/api/save-keys', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mail: email, privateKey, secretKey, selectedExchange, passphrase })
  })
  .then(response => response.json())
  .then(data => {
      if (data.error) { setMessage(data.error);  } 
      else { setMessage(data.message || 'Saved successfully!'); }
      setOpen(true);
  })
  .catch(error => {
      setMessage('Network error. Please try again.');
      setOpen(true);
      console.error('Network error:', error);
  });
}

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpen(false);
    };
    
    const handleDelete = async () => {  
        try {
          const response = await axios.post('/api/delete-user', { email });
          if (response.status === 200) { setOpen2(true); }
        } catch (error) {
          setMessage("We couldn't delete your user. Please contact support.")
          setOpen(true);
        }
        handleClose();
    };

    const handleSaveNickname = async () => {
      
      if (!email || email === 'none') {
        setMessage("Only registered user can set a nickname. Please login or register.");
        setOpen(true);
        return;
      }
      try {
        const response = await fetch('/api/set-display-name', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: email, display_name: nickname })   
        });

        const data = await response.json();
        

        if(data.error == "Display name already in use") {
          setMessage("This name is already in use, please try another one.")
          setOpen(true);
        }

        if (response.ok) {
          setMessage('Settings saved successfully');
          setOpen(true);          
        }

      } catch (error) {
        console.error('Save Error:', JSON.stringify(error));
          setMessage('Failed to save settings');
        setOpen(true);
      }
    };

    const panelClasses = `${newUiClass.settingsPanel} ${isOpen ? newUiClass.settingsPanelOpen : ''}`;

    const handleExchangeSelection = (exchange) => { setSelectedExchange(exchange); };

    return (
      <div className={panelClasses} style={{padding: '15px', color: '#1a1a1a'}}>
        <div style={{width: '100%'}}>
          <IconButton onClick={onClose} className={newUiClass.settingsPanelCloseButton}><CloseIcon /></IconButton>
        </div>
        <div style={{paddingLeft: '30px', paddingTop:'15px', }}>
          <Typography className={newUiClass.componentTitle}>Settings</Typography>
          <Typography className={newUiClass.componentSubtitle}><i>Configure your exchange, contact support, and more.</i></Typography>
        </div>
        <div style={{padding: '30px', marginBottom: '20px'}}>
          <div style={{color: '#1a1a1a'}}>
            <h3 >Configure Your Exchange</h3>
          </div>
          <div style = {{ marginBottom: '10px'}}>
            <span >Select your exchange and enter the relevant key information. <b>Key are transmitted, processed, and stored encrypted.</b></span><br/>
          </div>
            {availableExchanges.map((exchange) => (
              <Chip  key={exchange} label={exchange} onClick={() => handleExchangeSelection(exchange)} 
                className={newUiClass.standardChip1}  
                style={{ marginLeft: '5px' , backgroundColor: selectedExchange === exchange ? '#3558FF' : '#333', color: "white" }}
              />
            ))}
          <br/>
          <div style={{marginTop: '25px', marginBottom: '20px'}}>
            <TextField style={{ border: '1px solid #ddd', borderRadius: '5px' }}
              fullWidth value={privateKey} onChange={(e) => setPrivateKey(e.target.value)}  className={newUiClass.inputField}
              InputProps={{ className: newUiClass.inputBase,
                startAdornment: (<InputAdornment position="start"><Lock className={newUiClass.inputIcon}  /> <span>Private Key: </span></InputAdornment>),
              }}
            />      
            <TextField style={{ marginTop: '10px', marginBottom: '5px',  border: '1px solid #ddd', borderRadius: '5px'  }}
              fullWidth value={secretKey} onChange={(e) => setSecretKey(e.target.value)}  className={newUiClass.inputField}
              InputProps={{ className: newUiClass.inputBase,
                startAdornment: ( <InputAdornment position="start"><Lock className={newUiClass.inputIcon}  /> <span>Secret Key: </span> </InputAdornment> ),
              }}
            />  
            <TextField style={{  marginTop: '5px', marginBottom: '30px',  border: '1px solid #ddd', borderRadius: '5px'  }}
              fullWidth value={passphrase} onChange={(e) => setPassphrase(e.target.value)}  className={newUiClass.inputField}
              InputProps={{ className: newUiClass.inputBase,
                startAdornment: (<InputAdornment position="start"><Lock className={newUiClass.inputIcon}  /> <span >Passphrase (only for BloFin): </span></InputAdornment>  ),
              }}
            />  
            <Button  onClick={handleSubmit} style={{backgroundColor: '#3558FF', marginTop: '6px', marginBottom: '30px'}} className={newUiClass.loginButton}>Save Settings</Button>          
          </div>
          <div style={{color: '#1a1a1a', marginTop: '20px'}}>
            <h3 >Set your Nickname</h3>
          </div>
          <div style = {{ marginBottom: '10px'}}>
            <span>Choose a nickname to use our community feature. Don't enter your email address or other sensitive data.</span><br/>
          </div>
          <div style={{marginTop: '25px', marginBottom: '20px'}}>
            <TextField style={{ border: '1px solid #ddd', borderRadius: '5px', marginBottom: '30px' }}
              fullWidth value={nickname} onChange={(e) => setNickname(e.target.value)}  className={newUiClass.inputField}
              InputProps={{ className: newUiClass.inputBase,
                startAdornment: (<InputAdornment position="start"> <span>Nickname: </span></InputAdornment>),
              }}
            />      
           
            <Button  onClick={handleSaveNickname} style={{backgroundColor: '#3558FF', marginTop: '6px', marginBottom: '30px'}} className={newUiClass.loginButton}>Save Nickname</Button>          
          </div>


          <div style={{color: '#1a1a1a', marginTop: '20px'}}>
            <h3 >Delete User</h3>
            <label >Careful! If you want to delete your account, push the button below.</label>
          </div>
          <div style={{marginTop: '20px', marginBottom: '40px'}}>
            <Button style={{background: '#3558FF', marginBottom: '20px'}} className={newUiClass.loginButton} onClick={handleClickOpen}>
              Delete Account
            </Button>
            <br/>
          </div>  
          
            <div style={{marginBottom: '20px', marginTop: '20px'}} >
              <h3 >Contact Support</h3>
              <label >In case of issues, please send an email to <a style={{color: standardHighlight}} href="mailto:support@crypto-os.pro">our support inbox</a>.</label>
            </div>
            <div style={{marginBottom: '20px', marginTop: '40px'}} >
              <h3>Disclaimer & Policy</h3>
              <label>{new Date().getFullYear()} Crypto OS — A Marketing X100 Company </label>
              <br />
              <Link style={{color: '#1a1a1a', marginRight: '5px'}} href="https://cryptoknowledge.info/cryptoknowledge-app-disclaimer/" >
                ➡️ Disclaimer 
              </Link>
              <Link style={{color: '#1a1a1a'}} href="https://cryptoknowledge.info/cryptoknowledge-app-privacy-policy/">
                ➡️ Privacy Policy
              </Link>
            </div>
        </div>

         
        {errorMessage && <div style={{ color: "red", marginTop: '20px' }}>{errorMessage}</div>}
        <Dialog open={open1} onClose={handleClose2}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete your account? This action is irreversible.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose2} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} message={message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={handleClose}>
                Close
              </Button>
            </React.Fragment>
          }
        />
    <Dialog>
        <DialogTitle>{"Success"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Your user has been deleted successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => { handleClose3(); navigate('/login');  }} color="primary" >
                OK
            </Button>
            </DialogActions>
    </Dialog>
    </div>
    );
  };

export default Preferences;
