import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SingleSignal from '../elements/singleSignal';

const TradeSignalDetail = () => {
  const { id } = useParams();
  const [signal, setSignal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSignal = async () => {
      try {
        const response = await fetch(`https://app.crypto-os.pro/api/get-signle-signal/${id}`);
        if (!response.ok) { throw new Error('Failed to fetch trade signal'); }
        const data = await response.json();
        setSignal(data); } 
      catch (error) { setError(error.message); } 
      finally { setLoading(false); }
    };
    fetchSignal();
  }, [id]);

  if (loading) { return <div>Loading...</div>; }
  if (error) { return <div>Error: {error}</div>; }
  if (!signal) { return <div>No signal found.</div>; }

  return (
    <div>
      <SingleSignal signal={signal} />
    </div>
  );
};

export default TradeSignalDetail;
