import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import { color } from '@mui/system';

  const newUi = makeStyles((theme) => ({
    filterButton: {
      fontSize: '0.8em !important',
      border: '1px solide green !important'

    },
    tokenSightSearchInput: {
      color: 'black !important',
      width: '300px',
      '& .MuiInputBase-input': {
            color: 'black', // input text color
        },
        '& .MuiInputBase-input::placeholder': {
          color: 'grey', // placeholder text color
          fontSize: '0.8em'
      },
  },
  tokenSightSearchContainer2: {
    position: 'relative',  // This allows the dropdown to be positioned relative to the container
    display: 'inline-block',  // Makes sure the container size is only as large as necessary
    width: '300px',  // Or any width you prefer for the search box
    zIndex: 1000,  // Ensure the container has a high z-index to appear above other elements
},
tokenSightList2: {
    position: 'absolute',
    top: '100%',  // Position it right below the TextField
    left: '0',    // Align it with the left edge of the container
    maxHeight: '500px',
    overflowY: 'auto',
    width: '100%', // Ensure the dropdown width matches the container
    backgroundColor: '#fff',
    borderRadius: '5px',
    paddingBottom: '2px',
    paddingTop: '2px',
    boxShadow: theme.shadows[3],
    zIndex: 1001, // Ensure the list appears above other elements
}
,
  tokenSightListItemText: {
      color: 'black !important',
      fontSize: '0.8em !important',
  },
  tokenSightList: {
    position: 'absolute',  
    maxHeight: '500px',
      overflow: 'auto',
      width: '300px',
      marginTop: '8px',
      backgroundColor: '#fff',
      borderRadius: '5px',
      paddingBottom: '2px',
      paddingTop: '2px',
      boxShadow: theme.shadows[3],
      zIndex: 1,
  },
  tokenSightAvatar: {
      width: theme.spacing(3), // make the avatar 1/3 smaller
      height: theme.spacing(3),
  },
    newStartPadding: {
      marginLeft: '10%', 
      marginRight: '10%', 
      [theme.breakpoints.down('sm')]: { 
       marginRight: '5px', 
        marginLeft: '5px',
      }
    },
    newStartMargin: {
      marginTop: '20px',
      [theme.breakpoints.down('sm')]: {  marginTop: '0px', }
    },
  colorPrimary: {
    backgroundColor: '#F5F7FE !important',  // Color of the unfilled part
  },

colorPrimary: {
  backgroundColor: '#F5F7FE !important',  // Color of the unfilled part
},
  barColorPrimary:  {
    backgroundColor: 'blue'
  },
  mainOutlet: {
    paddingBottom: '60px', 
    marginTop: '80px !important', 
    paddingRight: '5px', 
    paddingLeft: '5px',
    [theme.breakpoints.down('sm')]: { // This corresponds to max-width: 600px
      paddingTop: '100px'
    }
  },
  blogContainer: {
    marginTop: '20px',
    padding: '20px',
    
  },
  componentTitle: {
    fontWeight: '800',
    marginBottom: '20px',
    color: '#1e90ff', // Blue tone for the title
  },
  messageBox: {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  username: {
    fontWeight: '600',
    color: '#333',
  },
  timestamp: {
    color: '#999',
    marginBottom: '10px',
  },
  content: {
    color: '#333',
    marginBottom: '10px',
  },
  image: {
    width: '100%',
    marginTop: '20px',
    borderRadius: '8px',
  },
  video: {
    width: '100%',
    marginTop: '20px',
    borderRadius: '8px',
  },
  likeButtonContainer: {
    paddingLeft: '20px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  divider: {
    marginTop: '20px',
  },

  tokenLink: {
    textDecoration: 'none',
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      textDecoration: 'none', // Ensures the box itself doesn't add underline

    },
  },
  tokenCard: {
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'left',
    background: 'linear-gradient(135deg, #1F3389, #00bfff)',
    color: '#fff',
    '&:hover': {
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      background: 'linear-gradient(135deg, #1F0FAA, #00bfff)',

    },
  },
  tokenLogo: {
    width: '40px',
    borderRadius: '15px',
    marginBottom: '10px',
  },
  tokenName: {
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  iconInBox: {
    marginRight: '20px', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white', 
        borderRadius: '50%', 
        width: '50px', 
        height: '50px', 
  },
  componentTitle: {
    fontSize: '1.8em !important', 
    color: '#1a1a1a !important', 
    fontWeight: '800 !important'
  },
  headerTitle: {
    fontSize: '1.4em', 
    color: '#1a1a1a', 
    fontWeight: '600'
  },
  componentSubtitle: {
    fontSize: '1em !important', 
    color: '#333 !important',
    marginBottom: '20px'
  },
  filterBoxTitle: {
    fontSize: '1em !important', 
    color: '#333 !important', 
    fontWeight: '600 !important'
  },
  filterBoxSubtitle: {
    fontSize: '0.7em !important', 
    color: '#333 !important'
  },

  floatingToolbar: {
    position: 'fixed', // Ensuring it's a positioned ancestor
    left: '70%',
    top: '100px',
    transform: 'translateX(-50%)',
    display: 'flex',
    background: 'rgba(255,255,255,1)', // Change as needed
    boxShadow: '3px 3px 15px rgba(200,200,200,1)',
    padding: '10px',
    borderRadius: '10px',
    zIndex: '1000',
    [theme.breakpoints.down('sm')]: { // This corresponds to max-width: 600px
        left: '10px',
        transform: 'none',
        width: '95%',
        marginRight: '10px'
    }
},
toolbarIcon: {
  position: 'relative', // Ensuring it's a positioned ancestor
  minWidth: '40px',
    '& img': {
        width: '30px',
        height: '30px',
        cursor: 'pointer',
    }
},
submenu: {
    position: 'absolute',
    marginTop: '70px',
    backgroundColor: 'white',
    boxShadow: '3px 3px 15px rgba(200,200,200,1)',
    borderRadius: '5px',
    padding: '10px',
    top: '-10px',
    left: '10px',
    width: '400px'
},


  mainClass: {
      //background: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)', 
      background: '#FFF',
      zIndex: -1,
      paddingLeft: '5px',
      paddingRight: '5px', 
      
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start', 
      alignItems: 'center', 
      width: '100%', // Cover the full width
      minHeight: '100vh', // Cover the full viewport height
      boxSizing: 'border-box',
      '&::before': { // This targets the ::after pseudo-element
        content: '""', // Note the double quotes inside the single quotes for the content property
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        //background: 'linear-gradient(0deg, rgba(41, 210, 239, 0.1) 0%, rgba(29, 43, 239, 0.1) 50%, rgba(252, 176, 69, 0.1) 100%)',
        pointerEvents: 'none',
      },
    },  
    accordionSummary: {
      height: '32px',
      '&::before': {
        backgroundColor: 'rgba(255,255,255,1) !important',
      },
    },
    
    
    dashboardBoxHeader: {
      marginBottom: '10px',
      fontSize: '1.1em',
      fontWeight: '600 !important'
    },
    dashboardBoxSubheader: {
      fontSize: '0.9em',
      fontWeight: '400 !important'
    },
    dashboardBox: {
      display: 'flex !important', 
      alignItems: 'center !important', 
      justifyContent: 'space-between !important', 
      textAlign: 'left !important', 
      minHeight: '120px !important' 
    },
    dashboardBigNumber: {
      fontSize: '2.6em', 
      fontWeight: '600', 
    },
    
    accordionHeader: {
      backgroundColor: '#F5F7FE !important',
      borderRadius: '5px !important',
      color: '1a1a1a !important',
      marginBottom: '10px',
      '&::before': {
        backgroundColor: 'rgba(255,255,255,0) !important',
      },
    },
  mobileHeader: {
      color: '#fff', 
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontWeight: 400,
      letterSpacing: '1px',
      fontSize: '2.2em', 
      marginBottom: '10px', 
    },
    mobileHeaderTwo: {
      color: '#fff', 
      textAlign: 'left !important',
      fontFamily: 'Raleway',
      fontWeight: 800,
      fontSize: '1.4em', 
    },
  mobileSubHeader: {
      color: '#fff', 
      textAlign: 'center',
      fontStyle: 'italic',
      marginBottom: '40px', 
    },
  standardButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.06) !important',
      borderRadius: '20px !important',
      color: '#fff !important',
      height: 55,
      marginBottom: '20px',
      textTransform: 'none', 
      fontSize: theme.typography.button.fontSize, 
      fontWeight: theme.typography.fontWeightRegular, 
      padding: theme.spacing(1, 2), 
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.06)', 
      },
      '& .MuiButton-startIcon': {
        margin: 0, 
        marginRight: theme.spacing(1), 
      },
    },
    
  inputField: {
      '& .MuiInputBase-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.06)',
          borderRadius: '5px', 
          color: '#1a1a1a',
          border: '1px solid #ddd'
      },
      '& .MuiInputLabel-root': {
        color: '#1a1a1a !important',
      },
      '& label.Mui-focused': {
        color: '#1a1a1a', 
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid #ddd', 
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid #ddd', 
      },
      '& .MuiInput-underline:after': {
        borderBottom: '1px solid #ddd', 
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent', 
        },
        '&:hover fieldset': {
          borderColor: 'transparent', 
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', 
        },
      },
    },
  inputIcon: {
      color: '#1a1a1a !important', 
      marginRight: '10px'
    },
  textLink: {
      cursor: 'pointer',
      color: '#ffffff', 
      fontSize: '0.9em !important',
      marginRight: '10px',
      '&:hover': {
        textDecoration: 'underline', 
        textDecorationColor: '#ffffff', 
      },
  },
  loginButton: {
    // background: 'linear-gradient(90deg, rgba(165,55,253,1) 0%, rgba(255,35,98,1) 50%, rgba(255,100,100,1) 100%)', // Adjusted the last color
    background: '#11047A',
    borderRadius: '5px !important',
    border: 0,
    width: '100%',
    color: 'white !important',
    fontSize: '16px', // Increase font size if needed
    letterSpacing: '0.5px', // Adjust letter-spacing to match the design
    textTransform: 'uppercase', // If the text should be uppercase
    marginTop: '0px !important',
    height: '55px', // Adjust to your preference
    position: 'relative',
    //boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Updated to give a smoother shadow
    transition: 'box-shadow 0.2s ease-in-out', // Smooth transition for shadow
    '&:hover': {
      background: '#1c3ee6',
      
    },
  },

  faceIdIcon: {
      position: 'absolute',
      right: 0, 
      top: '50%',
      marginRight: '5%',
      background: 'rgba(100, 100, 100, 0.7)',  
        transform: 'translateY(-50%)',
      color: 'white', 
    },
  dividerContainer: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: '25px',
      marginBottom: '25px',
      color: theme.palette.text.secondary,
    },
  dividerBorder: {
      borderBottom: '1px solid #6c757d', 
      flexGrow: 1,
    },
  dividerText: {
      margin: theme.spacing(0, 1),
      padding: theme.spacing(0, 1),
    },
    standardGridLeft: {
      marginBottom: '5px',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '25px !important',
      },
      [theme.breakpoints.down('sm')]: {
        
      },
    },
    standardGridNormal: {
      marginBottom: '5px',
      [theme.breakpoints.down('sm')]: {
      
      },
    },
    standardTableLeft: {
      paddingLeft: '15px', 
      marginTop: '20px', 
      marginBottom: '20px', 
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px'
      },
    },

    standardTableRight :{
      paddingLeft: '15px', 
      paddingRight: '10px', 
      marginTop: '20px', 
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px'
      },
    },
   
  cardContent: {
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
      color: '#fff',
      borderColor: '#333856', 
      padding: '10px',
      overflow: 'hidden',
  },
  cardContentReadMore: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
      color: '#fff',
      borderColor: '#333856', 
      padding: '5px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px'
  },
  cardActionItems: {
    color: '#fff',
    padding: '5px',
  },
  standardChip: {
    height: '25px !important', 
    marginRight:'5px !important', 
    backgroundColor: '#333 !important',
    borderRadius: '5px !important',
    margin: '5px !important',
    color: 'white !important'
  },
  standardChipColorless: {
    height: '25px !important', 
    marginRight:'5px !important', 
    borderRadius: '5px !important',
    margin: '5px !important',
    color: 'white !important'
  },
  standardChipColorless2: {
    height: '25px !important', 
    marginRight:'5px !important', 
    borderRadius: '5px !important',
    margin: '5px !important',
    
  },
  standardChipColorless1: {
    height: '25px !important', 
    marginRight:'5px !important', 
    borderRadius: '5px !important',
    margin: '5px !important',
    marginLeft: '0px !important',
    color: 'white !important'
  },
  standardChip1: {
    height: '25px !important', 
    marginRight:'5px !important', 
    borderRadius: '5px !important',
    margin: '5px !important',
    color: 'white !important',
    marginLeft: '0px !important'
  },
  standardChipExtended: {
    height: '25px !important', 
    marginRight:'5px', 
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginTop: '5px',
    color: 'white !important',
  },
  actionItemFrame: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginLeft: '5px',
    marginRight: '5px',
    padding: '7px',
    
  },
  actionItem: {
    width: '20px !important',
    height: '20px !important'
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    padding: theme.spacing(2), // Add padding as per your design
  },
  avatar: {
    marginRight: theme.spacing(2), // Adjust the spacing between the avatar and the text
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9 Aspect Ratio
  },
  clamp: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  clampExpanded: {
    WebkitLineClamp: 'initial', // Removes line clamping when expanded
  },
  clampCollapsed: {
    WebkitLineClamp: 6, // Change this number to the number of lines you want to show when collapsed
  },
  priceChart: {
    height: '300px !important', 
    marginTop: '10px', 
    paddingBottom: '10px'
  },
  ellipses: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '&::before, &::after': {
      content: '""', // This is necessary for pseudo-elements
      display: 'block',
      width: '4px', // Ellipse width
      height: '4px', // Ellipse height
      backgroundColor: '#FFFFFF', // Ellipse color
      borderRadius: '50%', // Make it round
      transition: 'transform 0.3s ease-in-out',
    },
    '&::before': {
      marginRight: '8px', // Space between the dots
    },
    '&:hover::before': {
      transform: 'translateX(-10px)', // Hover effect if needed
    },
    '&:hover::after': {
      transform: 'translateX(10px)', // Hover effect if needed
    },
  },
  featureHeaderContainer: {
    
    color: '#1a1a1a',
    borderRadius: '20px',
    padding: '10px',
    overflow: 'hidden', 
    marginLeft: '8px !important',
    marginRight: '8px !important'
  },

  filterBox: {
    marginLeft: '15px', 
    marginRight: '15px', 
    maxWidth: '100%', 
    borderRadius: '5px', 
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px !important',
      marginRight: '0px'
      }
  },
  featureHeaderMasterContainer: {
    backgroundColor: '#fff',
    boxShadow: 'rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 0px 1px 2px !important',
    color: '#1a1a1a !important',
    borderRadius: '5px !important',
    padding: '10px !important',
    position: 'relative !important', 
    overflow: 'hidden !important', 
    marginLeft: '8px !important',
    marginBottom: '10px !important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px !important',
      marginRight: '0px'
      }
  },
  featureHeaderMasterContainerLeft: {
    backgroundColor: '#fff !important',
    boxShadow: 'rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 0px 1px 2px !important',
    color: '#1a1a1a !important',
    borderRadius: '5px !important',
    padding: '10px !important',
    position: 'relative !important', 
    overflow: 'hidden !important',
    marginBottom: '10px !important',
  },


  stickyTableHeaderStyle: {
    position: 'sticky',
    top: 0,
    background: '#FFF',
    zIndex: 1000,
  },
  
  stickyTableFirstColumn: {
    position: 'sticky',
    left: 0,
    background: '#FFF',
    zIndex: 999,
    borderRight: '1px solid #ddd',

  },

  backtestAlertPopup: {
    position: 'fixed !important', 
    bottom: 50, 
    right: 0, 
    backgroundColor: 'white !important', 
    width: '40% !important', 
    zIndex: 9999 ,
    [theme.breakpoints.down('sm')]: {
      width: '90% !important'
    }
  },

  featureHeaderLogoContainer: {
    width: '50px', 
    height: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    backgroundColor: 'rgba(255,255,255,0.2) !important'
  },
  featureHeaderLogo: {
    objectFit: 'contain',
    width: '50px',
    height: '50px',
    color: '#29D2EF',
  },
  featureHeaderTextContainer: {
    flex: 1,
    paddingLeft: '10px'
  },
  featureHeaderTitle: {
    marginTop: '15px',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '10px'
  },
  featureHeaderSubtitle: {
    marginBottom: '20px',
    paddingLeft: '10px'
  },
  featureHeaderDecorativeCircles: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  newsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', 
    height: '100%',
    marginBottom: '10px',
    marginTop: '0px',
    padding: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    borderRadius: '10px',
    color: '#1a1a1a',
    [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    marginRight: '0px'
    }
  },
  youtubeBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', 
    height: '100%',
    marginBottom: '10px',
    marginTop: '0px',
    padding: '10px',
    marginLeft: '10px',
    marginRight: '0px',
    paddingRight: '0px',
    borderRadius: '10px',
    color: '#1a1a1a',
    [theme.breakpoints.down('sm')]: {
    marginLeft: '15px',
    marginRight: '10px'
    }
  },
  newsBox1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', 
    height: '100%',
    marginBottom: '10px',
    marginTop: '0px',
    padding: '10px',
    marginLeft: '0px',
    marginRight: '0px',
    paddingRight: '0px',
    borderRadius: '0px',
    color: '#1a1a1a',
    [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: '0px',
    padding: '0px'
    }
  },
  
  newsGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '25px !important',
    },
  },
  newsGrid1: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px !important',
    },
  },
  newsOuterGrid: {
    paddingLeft: '15px', 
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    },
  },

  adsContainer: {
    paddingLeft: '15px', 
    margin: '20px 0', 
    width: '100%', 
    position: 'relative',
    [theme.breakpoints.down('sm')]: {  
      paddingLeft: '0px',
      marginBottom: '0px'
     }
  },
  
  newsBoxContent: {
    flexGrow: 1, // This is crucial, it makes the content grow to fill available space
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Helps push the link to the bottom
    },

  newsBoxImage: {
    width: '100%', // Ensure the image scales properly
    height: 'auto', // Maintain aspect ratio
    marginBottom: '10px', // Space between image and title
  },

  newsBoxLink: {
    textDecoration: 'none !important', 
    fontWeight: '600 !important',
    color: '#11047A !important',
    '&:hover': { // This is how you specify a hover state in makeStyles
      textDecoration: 'underline !important', 
      color: '#11047A !important',
      fontWeight: 'bold !important' 
    }
  },

  communityTextColor: {
    color: '#1a1a1a !important',
  },
  

  communityContainer: {
    height: '70vH',
    overflowY: 'auto',
    border: '1px solid #ddd',
    padding: '10px',
    marginBottom: '20px'
  },

  mainPageBox: {
    boxShadow: 'rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 0px 1px 2px',
            borderRadius: '8px',
            position: 'relative',
            height: '350px',
            paddingBottom: '12px',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'flex-start', // Align to the top
            paddingTop: '10px',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '25px !important',
            },
  },
  mainPageBoxTitle: {
    textAlign: 'left', 
          paddingLeft: '20px',
          paddingBottom: '10px', 
          paddingTop: '10px', 
          color: '#1a1a1a', 
          fontSize: '1.1em', 
          fontWeight: '600' 
  },
  
  
  tableFrontPageStyle: {
    fontSize: '0.9em',
    fontWeight: 'bold !important',
    color: '#333 !important', // White color for headers
    backgroundColor: '#fff', // Darker color for header background
    textAlign: 'left',
    borderRadius: '5px, 5px, 0, 0',
    borderBottom: '1px solid #EDEDED', 
    background: 'rgba(255,255,255,0)', 
    borderTop: '1px solid #EDEDED', 
    paddingTop: '15px', 
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },


  tableHeaderStyle: {
    fontSize: '0.9em',
      fontWeight: 'bold !important',
      color: '#333 !important', // White color for headers
      backgroundColor: '#fff', // Darker color for header background
      textAlign: 'left',
      borderRadius: '5px, 5px, 0, 0',
      [theme.breakpoints.up('md')]: {
        padding: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '8px',
        paddingTop: '16px',
        paddingBottom: '16px',
      }
  },
  topHeaderImageGrid: {
    margin: '15px !important', 
    marginTop: '0px !important',
    [theme.breakpoints.down('sm')]: {
      margin: '5px !important'
    }
  },
  topHeaderImage: {
    width: '100% !important',
    height: 'auto', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    borderRadius: '5px', 
    border: '10px solid #fff',
    [theme.breakpoints.down('sm')]: {
      border: '5px solid #fff'
  }
},


  tableHeaderStyleRight: {
    fontSize: '0.9em',
    fontWeight: 'bold !important',
    color: '#333', // White color for headers
    backgroundColor: '#fff', // Darker color for header background
    textAlign: 'right',
    borderRadius: '5px, 5px, 0, 0',
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },

  specialTableGrid: {
    color: '#fff', // White color for headers
    [theme.breakpoints.up('md')]: {
      paddingLeft: '20px',
      paddingRight: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px',
      paddingRight: '5px'
    }
  },
  infoBar: {
    width: '100% !important', 
    marginBottom: '30px !important',  
    backgroundColor: '#fff !important', 
    borderRadius: '5px !important', 
    color: '#1A1A1A !important'
  },

  tableCellStyles: {
    color: '#1a1a1a', // Lighter text for content
    backgroundColor: '#rgba(255,255,255,0)', // Dark cells
    textAlign: 'left',
    display: 'flex', // Set display to flex to use flexbox properties
    alignItems: 'center', // This will align items vertically in the center
    justifyContent: 'flex-start', 
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },


  tableCellStyles2: {
    color: '#1a1a1a', // Lighter text for content
    backgroundColor: 'rgba(255,255,255,0)', // Dark cells
    textAlign: 'left',
    
    alignItems: 'center', // This will align items vertically in the center
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },


  tableCellStylesRight2: {
    color: '#1a1a1a', // Lighter text for content
    backgroundColor: 'rgba(255,255,255,0)', // Dark cells
    textAlign: 'right',
    alignItems: 'right', // This will align items vertically in the center
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },

  tableCellStylesTwoLines: {
    color: '#fff', // Lighter text for content
    backgroundColor: '#101935', // Dark cells
    textAlign: 'left',

    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },
  lastItem: {
    borderBottom: 'none !important',
    
  },

  gridItem: {
    padding: '0px !important',
    borderBottom: '1px solid #EDEDED',
    '&:hover': {
        backgroundColor: '#f9f9f9',  // Very light grey background on hover
    },
},


  tableCellStylesRight: {
    color: '#1a1a1a', // Lighter text for content
    backgroundColor: '#fff', // Dark cells
    textAlign: 'right',
    display: 'flex', // Set display to flex to use flexbox properties
    alignItems: 'center', // This will align items vertically in the center
    justifyContent: 'flex-end', 
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      paddingTop: '16px',
      paddingBottom: '16px',
    }
  },
  headerGrid: {
    maxWidth: '100% !important',  
    marginTop: '10px !important', 
    marginBottom: '30px !important', 
    marginLeft: 'auto !important', 
    marginRight: 'auto !important', 
    padding: '5px !important', 
    paddingLeft: '15px !important', 
    paddingRight: '15px !important',
    borderRadius: '5px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important'
    },
  },
  headerGrid3: {
    maxWidth: '100% !important',  
    marginTop: '10px !important', 
    marginBottom: '5px !important', 
    marginLeft: 'auto !important', 
    marginRight: 'auto !important', 
    padding: '5px !important', 
    paddingLeft: '15px !important', 
    paddingRight: '15px !important',
    borderRadius: '5px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important'
    },
  },

  headerGrid2: {
    maxWidth: '100% !important',  
    marginTop: '10px !important', 
    marginBottom: '30px !important', 
    marginLeft: 'auto !important', 
    marginRight: 'auto !important', 
    
   
    borderRadius: '5px !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important'
    },
  },
    filterGrid: {
      maxWidth: '100% !important',  
      marginTop: '10px !important', 
      marginBottom: '10px !important', 
      marginLeft: 'auto !important', 
      marginRight: 'auto !important', 
      borderRadius: '5px !important',
      padding: '10px !important', 
      paddingLeft: '15px !important', 
      paddingRight: '15px !important',
      backgroundColor: 'white',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0px',
        paddingRight: '0px'
      },
  },
  resultTableNew: {
    width: '100%', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    paddingLeft: '25px', 
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    },
  },

  headerGridTableSpecial: {
    maxWidth: '100%',  
    marginTop: '10px', 
    marginBottom: '30px', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    padding: '5px', 
    paddingLeft: '40px', 
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px'
    },
  },
  settingsPanel: {
    position: 'fixed',
    top: 20,
    bottom: 20,
    right:'-200px',
    overflowY: 'auto',
    width: '40%',
    borderRadius: '5px',
    height: '95vh',
    backgroundColor: 'white',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.2)',
    transition: 'right 1s ease-in-out !important',
    zIndex: 1300, 
    marginRight: '20px', 
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginRight: '0px',
      right: '0',
      top: 10,
      right: 10

    },
  },
  settingsPanelSmall: {
    position: 'fixed',
    top: 20,
    bottom: 20,
    right:'-200px',
    overflowY: 'auto',
    width: '40%',
    borderRadius: '5px',
    height: '550px',
    backgroundColor: 'white',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.2)',
    transition: 'right 1s ease-in-out !important',
    zIndex: 1300, 
    marginRight: '20px', 
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginRight: '0px',
      right: '0',
      top: 10,
      right: 10

    },
  },
  tokenPaper: {
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0), 0 0px 0px rgba(0, 0, 0, 0) !important',
    backgroundColor: 'rgba(255, 255, 255) !important',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px !important',
    borderRadius: '5px',
    marginRight: 'auto', 
    marginLeft: 'auto', 
    height: '850px',
    [theme.breakpoints.down('sm')]: {
      height: '850px'
    }
  },
  tokenPaperNew: {
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0), 0 0px 0px rgba(0, 0, 0, 0) !important',
    backgroundColor: 'rgba(255, 255, 255) !important',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    
    marginRight: 'auto', 
    marginLeft: 'auto', 
  },
  tokenPaperVideos: {
    boxShadow: '0 0px 0px rgba(0, 0, 0, 0), 0 0px 0px rgba(0, 0, 0, 0) !important',
    backgroundColor: 'rgba(255, 255, 255) !important',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    height: '350px',
    marginRight: 'auto', 
    marginLeft: 'auto', 
  },
  settingsPanelOpen: {
    right: '20px',  // Adjust to account for the right margin
  },
  settingsPanelCloseButton: {
    position: 'absolute !important',
    right: '30px !important',  // Adjusted to 10px from the right
    top: '30px',  // 10px from the top to align it nicely
    backgroundColor: '#ddd !important',  // Grey background
    borderRadius: '50%',  // Makes the background circle
    width: '40px',  // Specific size for the button
    height: '40px',  // Specific size for the button
    display: 'flex !important',  // Center the icon inside the button
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  


  tokenSightOuter: {
    paddingLeft: '25px', 
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    },
  },
  tokenSightSelect: {
    
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    },
  },
  
  paperStyles: {
    backgroundColor: '#101935', 
    boxShadow: 'none !important',
    margin: 'auto',
    width: '100%',
    borderRadius: '5px',
    overflow: 'hidden', 
    border: '0px !important'
  },

 
  tableLogo: { 
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '20px'
    },
  },
  resultsDecorativeCircles: {
    position: 'absolute',
    top: 0,
    left: 0,
    // Add your SVG or CSS to render the circles here
  },
  resultCardHeader: {
    cardHeader: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative', // For positioning the graphic absolutely
    },
    resultBottomGraphic: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      width: '50px', // Adjust size
      height: '50px', // Adjust size
      backgroundSize: 'cover',
    },
    resultsContainer: {


    },
    username: {
      fontWeight: '600 !important',
      fontSize: '1.2em',
    },
    timestamp: {
      color: '#888',
      fontSize: '0.8rem',
    },
    content: {
      marginTop: theme.spacing(1),
      fontSize: '1rem',
    },
    image: {
      marginTop: theme.spacing(1),
      maxWidth: '100%',
      borderRadius: '8px',
    },
    
  }

  }));

  export default newUi;