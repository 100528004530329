import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Grid, Box } from '@material-ui/core';
import newUi from '../../styles/newUi';



export const SupportResistanceDashboard = (() => {
    const classes2 = newUi();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [m, setM] = useState(0);
    const [h, setH] = useState(0);
    const [d, setD] = useState(0);
    

    useEffect(() => {
        const fetchSignals = async () => {
            try {
                setError('');
                setLoading(true);
                const res = await fetch('/api/supportAndResistance');
                const data = await res.json();
                const today = new Date().toISOString().split('T')[0];
                const counts = {
                    "15M": 0,
                    "1H": 0,
                    "1D": 0,
                };
    
                data.forEach(item => {
                    if (item.alert_date.startsWith(today)) { // Check if the alert_date is today
                        counts[item.timeframe] += 1; // Increment the count for the respective timeframe
                    }
                });
    
                // Update state variables with the counts
                setM(counts["15M"]);
                setH(counts["1H"]);
                setD(counts["1D"]);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchSignals();
    }, []);

  
  if (error) return <div>Error: {error}</div>;

  return (
    <>
            <Grid md={4} xs={12} >
                <Box className={classes2.featureHeaderMasterContainerLeft} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
                    <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, 15M Chart</i> </span> <br/>
                     {(!loading) &&  <span className={classes2.dashboardBigNumber}>{m}</span>}
                     {(loading) &&  <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }}/>}
                    </div>
                </Box>
            </Grid>
            <Grid md={4} xs={12}>
                <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
                <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, 1H Chart</i> </span> <br/>
                    {(!loading) &&  <span className={classes2.dashboardBigNumber}>{h}</span>}
                     {(loading) &&  <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }}/>}
                    </div>
                </Box>
            </Grid>
            <Grid md={4} xs={12}>
            <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
            <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, Daily Chart</i> </span> <br/>
                    {(!loading) &&  <span className={classes2.dashboardBigNumber}>{d}</span>}
                     {(loading) &&  <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }}/>}
                    </div>
                </Box>
            </Grid>
   </>
  );
});