import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Grid, Box, Button, TextField, Snackbar } from '@material-ui/core';
import newUi from '../styles/newUi';
import { getPrice } from '../functions/cryptoPrices';
import { customFormat } from '../functions/customFormat';
import { longColor, shortColor } from '../data/basics';
import CmeTable from '../elements/cmeTable';
import MuiAlert from '@mui/material/Alert';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Cme() {
    const classes2 = newUi();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [trades, setTrades] = useState([]);
    const [btcPrice, setBtcPrice] = useState(null);  
    const [tradeRunning, setTradeRunning] = useState("No");
    const [direction, setDirection] = useState();
    const [duration, setDuration] = useState();
    const [percentage, setPercentage] = useState();
    const [checker, setChecker] = useState(0);
    const [email, setEmail] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
   

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }; 

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async () => {
        
        try {
            const response = await fetch('/api/add-cme-user', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (data.message === 'User added successfully') {
                setSnackbarMessage('Thank you for registering! We will send you an email once the feature is available!');
                setSnackbarSeverity('success');
                const response = await fetch('/api/send-cme-welcome', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email }),
                });
            } else if (data.message === 'Email already registered') {
                setSnackbarMessage('This email is already registered.');
                setSnackbarSeverity('warning');
            } else {
                setSnackbarMessage('An unexpected error occurred. Please try again later or contact support.');
                setSnackbarSeverity('error');
            }
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error submitting data:', error);
            setSnackbarMessage('Sorry, an error occurred. Please try again later or contact support.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {

        }
    };


    useEffect(() => {
        window.scrollTo(0, 0); 
        async function fetchData() {
            setLoading(true);
            try {
                const btc = await getPrice(['BTC']);
                setBtcPrice(btc);  
                const res = await fetch('/api/latest-cme-alerts');
                const fetchedData = await res.json();
                setTrades(fetchedData);
                if (fetchedData.length > 0 && fetchedData[0].status === 'open') {
                    setTradeRunning("Yes");
                    checkOpenTrade(fetchedData[0], btc);  
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error.toString());
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []); 

    
    function formatDuration(durationMs) {
        const seconds = Math.floor(durationMs / 1000); 
        const minutes = Math.floor(seconds / 60); 
        const hours = Math.floor(minutes / 60); 
        const remainingMinutes = minutes % 60; 
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
        return `${formattedHours}h ${formattedMinutes}m`;
    }
    

    function checkOpenTrade(trade, btc) {
        const openTime = new Date(trade.open).getTime();
        const currentTime = Date.now();
        const durationOpen = currentTime - openTime;
        setDuration(formatDuration(durationOpen));
        if (btc && btc.BTC && btc.BTC.price) {
            const currentPrice = btc.BTC.price;
            const profitOrLossPercentage = trade.direction === 'long' 
                ? ((currentPrice - trade.entry_price) / trade.entry_price) * 100 
                : ((trade.entry_price - currentPrice) / trade.entry_price) * 100;
            setPercentage(customFormat(profitOrLossPercentage));
            setDirection(trade.direction.charAt(0).toUpperCase() + trade.direction.slice(1));
        }
    }

    
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
             <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
                <Typography className={classes2.componentTitle}>CME Strategy</Typography>
                <Typography className={classes2.componentSubtitle}><i>The most reliable weekend trading strategy.</i></Typography>
            </div>      
            <Grid container spacing={2} className={classes2.headerGrid}>
            <Grid md={3} xs={12}>
                <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '180px', display: 'block', padding: '30px'}}>
                    <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px', paddingLeft: '10px'}}><b>Trade Running?</b> <br/> <span style={{fontSize: '0.8em'}}><i>Current CME Strategy Status</i> </span> <br/>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <div className={classes2.iconInBox}>
                                <AutorenewIcon style={{ color: (tradeRunning == "No") ? 'darkred' : 'darkgreen', fontSize: '24px' }} />
                            </div>              
                            {tradeRunning && <span className={classes2.dashboardBigNumber}>{tradeRunning}</span>}
                        </div>
                    </div>
                </Box>
            </Grid>
            <Grid md={3} xs={12}>
                <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '180px', display: 'block', padding: '30px'}}>
                    <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Trade Direction</b> <br/> <span style={{fontSize: '0.8em'}}><i>Long or Short</i> </span> <br/>
                        {(direction == "Long" || direction == "Short") && <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <div className={classes2.iconInBox}>
                               {direction == "Long" && <KeyboardDoubleArrowUpIcon style={{ color: 'darkgreen', fontSize: '24px' }} />}
                                {direction == "Short" && <KeyboardDoubleArrowDownIcon style={{ color: 'darkred', fontSize: '24px' }} />}
                            </div> 
                            <span className={classes2.dashboardBigNumber}>{direction}</span>
                        </div>}
                    </div>
                </Box>
            </Grid>
            <Grid md={3} xs={12}>
                <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '180px', display: 'block', padding: '30px'}}>
                    <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Duration</b> <br/> <span style={{fontSize: '0.8em'}}><i>Trade has been open since</i> </span> <br/>
                        {(duration) && <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <div className={classes2.iconInBox}>
                                <AvTimerIcon style={{ color: 'darkred', fontSize: '24px' }} />
                            </div> 
                            <span className={classes2.dashboardBigNumber}>{direction}</span>
                        </div>}
                    </div>
                </Box>
            </Grid>
            <Grid md={3} xs={12}>
                <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '180px', display: 'block', padding: '30px'}}>
                    <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Profit/Loss</b> <br/> <span style={{fontSize: '0.8em'}}><i>Current Trade Performance</i> </span> <br/>
                    {(percentage) && <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <div className={classes2.iconInBox}>
                                <CurrencyBitcoinIcon style={{color: (percentage >= 0 ? longColor : shortColor), fontSize: '24px'}}  />
                            </div> 
                            <span className={classes2.dashboardBigNumber}>{direction}</span>
                            <span className={classes2.dashboardBigNumber}>{percentage}%</span>
                        </div>}
                    </div>
                </Box>
            </Grid>
           
            <Grid md={12} xs={12} style={{marginTop: '25px', marginLeft: '5px'}}>
                <CmeTable  signals={trades} />
            </Grid>
            <Grid container style={{background: 'linear-gradient(to right, #483D8B, #6A5ACD)', marginTop: '90px', borderRadius: '5px', padding: '20px', paddingBottom: '60px', marginBottom: '50px' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{marginTop: '40px', width: '100%' }}>
                <div className={classes2.featureHeaderContainer} style={{fontSize: '1.5em', fontWeight: '600', marginBottom: '5px', color: 'white'}}>Never miss a CME Trade Alert</div><br/>
            </Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginBottom: '20px'}}>
                <div className={classes2.featureHeaderContainer} style={{fontSize: '1.3em', marginBottom: '5px', color: 'white'}}>Sign up for our waitlist! We will soon add a feature to send out email alerts  whenever CME trade signal happens.</div>
            </Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{width: '100%'}}>
                <Grid item style={{width: '60%'}}>    
                    <TextField className={classes2.inputField}  style={{width: '100%'}} label="Email" variant="outlined" value={email} onChange={handleInputChange} />
                </Grid>
                <Grid item style={{width: '20%'}}>
                    <Button className={classes2.loginButton} variant="contained" onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Loading...' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                {snackbarMessage}
            </Alert>
        </Snackbar> 
    </div>
    );
};

export default Cme;
