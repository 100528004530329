import React, { useState, useEffect } from "react";
import { Box, Chip, Typography } from "@material-ui/core";
import mainStyles from '../../styles/mainstyles';
import { useParams } from 'react-router-dom';
import { createMappings, emaMappings } from '../../data/tokensightData';
import { longColor, shortColor } from '../../data/basics';
import newUi from "../../styles/newUi";
import LinearProgress from '@mui/material/LinearProgress';

export function MinimizedTokenInfoWoChart(props) {
    const newUiClass = newUi();
    const classes2 = mainStyles();
    const { trendClassMapping, adxClassMapping, rsi1ClassMapping, rsiClassMapping, macdClassMapping, patternClasseMapping } = createMappings(classes2);
    const [coreData, setCoreData] = useState([]);
    const [coreData2, setCoreData2] = useState([]);
    const [supportResistanceData, setSupportResistanceData] = useState([]);
    const [supportResistanceData2, setSupportResistanceData2] = useState([]);
    const { tokenSymbol } = useParams();
    const [dataReady, setDataReady] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const token = props.token;  // Use props.token directly

    function EMAGridItem({ label, value, trendMapping }) { return (  <Chip label={label} className={trendMapping[value]} /> ); }


    useEffect(() => {
        setIsLoading1(true);
        Promise.all([
            fetch(`/api/single-indicators-daily?token=${token}`).then((res) => res.json()),
            fetch(`/api/single-supportResistance?token=${token}`).then((res) => res.json())
        ]).then(([indicatorsData, supportResistanceData]) => {
            if (indicatorsData.error) { console.error(indicatorsData.error); } 
            else { setCoreData(indicatorsData); }
            if (supportResistanceData.error) { console.error(supportResistanceData.error); } 
            else {
                setSupportResistanceData(supportResistanceData);
                setSupportResistanceData2(supportResistanceData);
            }
            const core = indicatorsData;
            setCoreData2(core);
            
            setDataReady(true);
        }).catch((error) => { console.error('Error fetching data:', error); });
    }, [token]);
    

    return (
        <>
        {(!dataReady) && <LinearProgress />}
        {(dataReady) &&
            <>
                <div style={{marginTop: '15px'}}>
                    <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Trend Indicator</i></Typography>
                    {coreData2 && coreData2.length > 0 ? (
                        emaMappings.map(mapping => (
                            <EMAGridItem key={mapping.key} label={mapping.label} value={coreData2[0][mapping.key]} trendMapping={trendClassMapping} />
                        ))
                    ) : null}
                    {(coreData2.length > 0) && <Chip label={`ADX: ${coreData2[0].adx}`} className={adxClassMapping[coreData2[0].adx]} />}
                </div>
                <div style={{marginTop: '25px'}}>   
                    <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Momentum Indicator</i></Typography>     
                    {(coreData2.length > 0) && <Chip label={'RSI'} className={rsi1ClassMapping[coreData2[0].rsi]} />}
                    {(coreData2.length > 0) && <Chip label={'MACD'} className={macdClassMapping[coreData2[0].macd]} />}
                    {(coreData2.length > 0) && <Chip label={'Stochastic Oscillator'} className={rsiClassMapping[coreData2[0].stochastic_oscillator]} />}
                    {(coreData2.length > 0) && <Chip label={'WilliamsR'} className={rsiClassMapping[coreData2[0].willr]} />}
                    {(coreData2.length > 0) && <Chip label={'MFI'} className={rsiClassMapping[coreData2[0].mfi]} />}
                    {(coreData2.length > 0) && <Chip label={'Stochastic RSI'} className={rsi1ClassMapping[coreData2[0].stoch_rsi]} />}    
                </div>
                <div style={{marginTop: '25px'}}>   
                    <Typography style={{fontSize: '0.7em', color: '#333', marginBottom: '10px'}}><i>Performance</i></Typography>     
                    <Chip label={`7D Change: ${(coreData2.length > 0) && `${coreData2[0].change_7d}%`}`}
                      className={newUiClass.standardChip1}
                      style={{  backgroundColor: (coreData2.length > 0) ? (coreData2[0].change_7d < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`30D Change: ${(coreData2.length > 0) && `${coreData2[0].change_30d}%`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].change_30d < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`365D Change: ${(coreData2.length > 0) && `${coreData2[0].change_365d}%`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].change_365d < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`1Y High: ${(coreData2.length > 0) && `${coreData2[0].highest_value}`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].highest_value < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`1Y Low: ${(coreData2.length > 0) && `${coreData2[0].lowest_value}`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].lowest_value < 0 ?shortColor : longColor) : 'transparent',}}
                    /> 
                </div>
            </>
        }
        </>
    );
}
