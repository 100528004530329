import { makeStyles } from '@material-ui/core/styles';

const sharedStyles = makeStyles((theme) => ({
    loginEffect: {
        animation: `$fadeIn 5s ease-in-out`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed', 
        width: '100vw', 
        height: '100vh', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-15%',
        paddingLeft: '20px',
        paddingRight: '20px'
      },


      
      '@keyframes fadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 },
      },
    field: {
      flex: '1 1 25%',
      width: '20% !important',
      padding: theme.spacing(1),
    },
    field30: {
        flex: '1 1 32%',
        width: '32% !important',
        padding: theme.spacing(1),
    },
    boxi: {
        width: '100% !important;'
    },
    submitButton: {
        width: '20%',
        marginTop: '8px',
        height: '55px'
    },
    tableRight: {
        textAlign: 'right'
    },
    standardButton: {
        backgroundColor: '#5B798E', 
        color: 'white', 
        border: 'none', 
        borderRadius: '5px', 
        padding: '5px 15px',
        height: '100%', 
        cursor: 'pointer' 
    },
    standardButton2: {
        backgroundColor: '#5B798E', 
        color: 'white', 
        border: 'none', 
        borderRadius: '5px', 
        padding: '5px 15px',
        height: '60px', 
        cursor: 'pointer' 
    },
    tokenCardText: {
        padding: '3px !important'
    },
    tokenCardTextLast: {
        paddingLeft: '3px !important',
        paddingRight: '3px !important',
        paddingBottom: '3px !important'
    },
    tokenCardTitle: {
        fontSize: '1.2em !important',
        fontWeight: '800 !important',
        paddingLeft: '3px !important'
    },
    tokenCardLogo: {
        paddingLeft: '3px !important',
        paddingTop: '12px !important',
        marginTop: '18px !important'
    },
    screenerButton: {
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        minWidth: '75px'
    },
    alertText: {
        color: 'red',
        fontWeight: '900',
        fontSize: '16px',
        paddingTop: '12px'
    },
    mobileHeader: {
        textAlign: 'center',
        fontWeight: '800',
        fontSize: '1.6em',
        paddingBottom: '5px'
    },
    mobileSubHeader: {
        textAlign: 'center !important',
        fontSize: '1.1em',
        fontWeight: '400',
        paddingBottom: '20px'
    },
    slideUpComponent:
     {
        transition: 'all 0.5s ease',
        transform: 'translateY(100%)',
        maxHeight: '0',
        overflow: 'hidden'
    },
    slideUpComponentVisible:
    {
        transform: 'translateY(0)',
        maxHeight: '100vh',
        overflow: 'auto'
    },
    slideUpComponentHidden: 
    {
        transform: 'translateY(100%)',
        maxHeight: '0',
        overflow: 'hidden'
    },
   
}));

export default sharedStyles;
