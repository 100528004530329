import './App.css';
import suppressWarnings from './suppres';
import React, { useEffect, useState } from "react";
import Layout from './Layout';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signals from './forms/Signals';
import Screener from './forms/Screener';
import Tokens from './forms/Tokens';
import Forecast from './forms/Forecast';
import { UserContext } from './functions/userContext';
import axios from 'axios';
import NewStart from './forms/NewStart';
import Preferences from './forms/Preferences';
import { getEmail } from './functions/getEmail';
import BacktestScreener from './forms/Backtest';
import Bots from './forms/Bots';
import SupportAndResistance from './forms/SupportAndResistance';
import Cme from './forms/Cme';
import Footer from './elements/footer';
import PatternForecast from './forms/PatternForecast';
import ChartSignals from './forms/ChartSignals';
import Community from './forms/community/Community';
import AnalysisChart from './forms/Analysis';
import AuthPage from './forms/Login';
import { AuthProvider } from './AuthContext';
import AuthTestPage from './forms/LoginTest';
import TradeSignalDetail from './forms/TradeSignalDetail';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase"; // Import the initialized messaging instance
import TokenBlog from './forms/community/TokenBlog';




const darkTheme = createTheme({
  palette: {
    type: 'light',
    primary: { main: '#FDFDFF' },
    secondary: { main: '#0a0a0a' },
    background: { default: '#FDFDFF', paper: '#FFF' },
    text: { primary: '#ffffff', secondary: '#aaaaaa' },
  },
  shape: { borderRadius: 5, },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // State to keep track of login status
  const [email, setEmail] = useState(getEmail());
  const [tier, setTier] = useState('');
  const [userData, setUserData] = useState(null);


  function handleFetch() {
    fetchData().then(data => {
      setUserData(data);
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
  }

  async function fetchData() {
    const response = await axios.get('/api/get-user');
    return response.data;
  }

  useEffect(() => { handleFetch(); }, []);
  
  const suppressedWarnings = /(The width\(0\) and height\(0\) of chart should be greater than 0)/;

const originalWarn = console.warn;

console.warn = (message, ...args) => {
  if (suppressedWarnings.test(message)) {
    return;
  }
  originalWarn.call(console, message, ...args);
};



  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={darkTheme}>
          <UserContext.Provider value={userData}>
            <Router>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/auth/callback" element={<NewStart />} />
                  <Route index element={<NewStart />} />
                  <Route path="/blog/:symbol" element={<TokenBlog />} />
                  <Route path="start" element={<NewStart />} />
                  <Route path="/signals" element={<Signals />} />
                  <Route path="/screener" element={<Screener />} />
                  <Route path="/settings" element={<Preferences />} />
                  <Route path="/tokens" element={<Tokens />} />
                  <Route path="/tokens/:tokenSymbol?" element={<Tokens />} />
                  <Route path="/forecast" element={<Forecast />} />
                  <Route path="/newstart" element={<NewStart />} />
                  <Route path="/backtest" element={<BacktestScreener />} />
                  <Route path="/bots" element={<Bots />} />
                  <Route path="/supportResistance" element={<SupportAndResistance />} />
                  <Route path="/cme" element={<Cme />} />
                  <Route path="/patternForecast" element={<PatternForecast />} />
                  <Route path="/chartSignals" element={<ChartSignals />} />
                  <Route path="/community" element={<Community />} />
                  <Route path="/charts" element={<AnalysisChart />} />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route path="/authtest" element={<AuthTestPage />} />
                  <Route path="/signal/:id" element={<TradeSignalDetail />} />
                </Route>
              </Routes>
            </Router>
            <Footer />
          </UserContext.Provider>
        </ThemeProvider>
      </AuthProvider>
    </>
  );
}

export default App;
