import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@material-ui/core';
import GaugeChart from 'react-gauge-chart';
import newUi from '../../styles/newUi';
import useWindowSize from '../../hooks/useWindowsSize';

export const BullBearIndex = ({ onLoad }) => {
  const classes2 = newUi();
  const [signals, setSignals] = useState({ '1D': { Long: 0, Short: 0 } });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const size = useWindowSize(); // Get the window size

  useEffect(() => { if (onLoad) onLoad(); }, [onLoad]);

  useEffect(() => {
    const fetchSignals = async () => {
      try {
        setError('');
        setLoading(true);
        const response = await axios.get('/api/get-bull-bear-counts');
        setSignals(response.data); 
        console.log(response.data);
      } catch (err) { 
        setError(err.message); 
      } finally {
        setLoading(false);
      }
    };
    fetchSignals();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const calculateIndex = (longCount, shortCount) => {
    const total = longCount + shortCount;
    return total > 0 ? (longCount / total) : 0.5;
  };

  const timeframe = '1D';

  // Determine the chart width based on the window size
  const chartWidth = size.width > 960 ? 400 : size.width > 600 ? 300 : 200;

  return (
    <Box className={classes2.mainPageBox}>
      <Typography className={classes2.mainPageBoxTitle}>
        Bull/Bear Index - {timeframe}
      </Typography>
      <Box style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
        <GaugeChart
          id={`gauge-chart-${timeframe}`}
          nrOfLevels={5}
          colors={['#FF5F6D', '#FFC371', '#00C851']}
          arcWidth={0.1}
          percent={calculateIndex(signals[timeframe].Long, signals[timeframe].Short)}
          arcPadding={0.02} 
          needleColor="#000"
          needleBaseColor="#000"
          textColor="#1a1a1a"
          formatTextValue={(value) => `${Math.round(value)}%`}
          hideText={false}
          animate={true}
          style={{ width: chartWidth }} // Dynamically adjust the chart width
        />
      </Box>
    </Box>
  );
};

export default BullBearIndex;
