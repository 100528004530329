import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Grid, Box } from '@material-ui/core';
import newUi from '../../styles/newUi';



export const BacktestDashboard = (() => {
    const classes2 = newUi();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [m, setM] = useState(0);
    const [h, setH] = useState(0);
    const [d, setD] = useState(0);
    const [counts, setCounts] = useState({ '1H': 0, '15M': 0, '1D': 0 });

    

    useEffect(() => {
        setLoading(true);
        const today = new Date().toISOString().split('T')[0]; // Format today's date as "YYYY-MM-DD"
        
        fetch('/api/backtestScreener')
            .then(res => res.json())
            .then(fetchedData => {
                const timeframes = { '1H': 0, '15M': 0, '1D': 0 };

                // Process each item in the fetched data
                fetchedData.forEach(item => {
                    if (item.detected_date && item.detected_date.startsWith(today)) { // Check if the detected date is today
                        if (item.timeframe in timeframes) {
                            timeframes[item.timeframe]++; // Increment the count for the timeframe
                        }
                    }
                });

                setCounts(timeframes); // Update state with the counts
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

  
  if (error) return <div>Error: {error}</div>;

  return (
    <>
            <Grid md={4} xs={12} >
                <Box className={classes2.featureHeaderMasterContainerLeft} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
                    <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px', paddingLeft: '10px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, 15M Chart</i> </span> <br/>
                     {(!loading) &&  <span className={classes2.dashboardBigNumber}>{counts['15M']}</span>}
                     {(loading) &&  <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }}/>}
                    </div>
                </Box>
            </Grid>
            <Grid md={4} xs={12}>
                <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
                <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, 1H Chart</i> </span> <br/>
                    {(!loading) &&  <span className={classes2.dashboardBigNumber}>{counts['1H']}</span>}
                     {(loading) &&  <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }}/>}
                    </div>
                </Box>
            </Grid>
            <Grid md={4} xs={12}>
            <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
            <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, Daily Chart</i> </span> <br/>
                    {(!loading) &&  <span className={classes2.dashboardBigNumber}>{counts['1D']}</span>}
                     {(loading) &&  <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }}/>}
                    </div>
                </Box>
            </Grid>
   </>
  );
});