import React, { useState, useEffect } from 'react';
import { Link, Grid, Paper, Chip, Box, Typography, IconButton, LinearProgress, Tooltip } from '@mui/material';
import newUi from '../styles/newUi';
import InfoIcon from '@mui/icons-material/Info';
import mainStyles from '../styles/mainstyles';
import { customFormat, formatTimestampUTC } from '../functions/customFormat';
import { useTheme } from '@material-ui/core/styles';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SelectControl } from './SelectControl';


function PatternForecastTable(props) {
  const { rows, indicator } = props;
  const [expandedRow, setExpandedRow] = useState({});
  const [sortedRows, setSortedRows] = useState(rows);
  const [sortOrder, setSortOrder] = useState('highestCorrelation');
  const newUiClass = newUi();
  const classes2 = mainStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sortFunctions = {
    highestCorrelation: (a, b) => b.average_correlation - a.average_correlation,
    highestPercentageChange: (a, b) => b.average_percentage_change - a.average_percentage_change,
    lowestPercentageChange: (a, b) => a.average_percentage_change - b.average_percentage_change,
    highestAmount: (a, b) => b.num_similar_pattern - a.num_similar_pattern,
    alphabeticalAsc: (a, b) => a.symbol.localeCompare(b.symbol),
    alphabeticalDesc: (a, b) => b.symbol.localeCompare(a.symbol)
  };
  
const handleSort = (event) => {
    const newSortValue = event.target.value;
    setSortOrder(newSortValue); 
    const newSortedRows = rows.sort(sortFunctions[newSortValue]);
    setSortedRows(newSortedRows);
};

function highCorrelationChip(correlation) {
  if (correlation > 0.98) { return( <Chip style={{backgroundColor: 'darkgreen'}} className={newUiClass.standardChip1} label="HIGH CORRELATION" /> )}
  }



  useEffect(() => {
    const sorted = [...rows].sort(sortFunctions['alphabeticalAsc']);
    setSortedRows(sorted);
  }, [rows]);
    
  return (
    <>
    <div style={{paddingLeft: '15px', marginBottom: '25px', marginRight: '10px'}}>
        <Grid container spacing={2} className={newUiClass.filterGrid} style={{paddingLeft: '5px'}}>
          <Grid item xs={12} md={12}>
            <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
            <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <SelectControl label="Sorting" value={sortOrder}
              onChange={handleSort}
              options={[
                {value: "highestCorrelation", label: "Highest Correlation"}, 
                {value: "highestPercentageChange", label: "Highest Increase"}, 
                {value: "lowestPercentageChange", label: "Highest Decrease"}, 
                {value: "highestAmount", label: "Most Historical Pattern"},
                {value: "alphabeticalAsc", label: "Alphabetical (A-Z)"},
                {value: "alphabeticalDesc", label: "Alphabetical (Z-A)"}, ]}

                 newUiClass={newUiClass}
            />
          </Grid>
        </Grid>
      </div>
    <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
      {(indicator == 'ai') &&
      sortedRows.map((row, index) => {
        const isFirstInRow = index % 3 === 0;
        const isThirdInRow = index % 3 === 2;
        return (
          <Grid item xs={12} md={4} key={row.symbol} style={{
            paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px', 
            paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
            alignItems: 'stretch'  
          }}>
            <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
              setExpandedRow(prevState => ({
              ...prevState,
              [row.symbol]: !prevState[row.symbol]
              }));
            }}>
              <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexShrink: 0 }}>
                      <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                    </div>
                    <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                      <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                        <b>{row.symbol}</b><br />
                        <span style={{ color:'#1a1a1a', fontSize:'0.8em' }}>
                          {row.fullName}
                        </span>
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right", width: '100%' }}>
                      <Typography style={{ color: row.average_percentage_change > 0 ? "darkgreen" : "darkred", fontSize: '2em', fontWeight: '800' }}>
                      {customFormat(row.average_percentage_change)}%
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                     {highCorrelationChip(row.average_correlation)}
                    </Box>
                  </div>
                  {expandedRow[row.symbol] && (
                    <>
                    <div style={{marginTop: '25px'}}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Current Price</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                        <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.latest_price)}</span>
                      </Typography>
                      </div>
                      <div style={{ marginTop: '5px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Pattern Forecast</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                      <span style={{ paddingRight: '5px', fontWeight: '600' }}>Change: {customFormat(row.average_percentage_change)}%</span>
                      </Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                      <span style={{ paddingRight: '5px', fontWeight: '600' }}>Found Pattern: {row.num_similar_pattern}</span>
                      </Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                      <span style={{ paddingRight: '5px', fontWeight: '600' }}>Average Correlation: {customFormat(row.average_correlation)}</span>
                      </Typography>
                      </div>
                      <div style={{ marginTop: '5px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Details</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{ fontSize: '0.9em', }}>
                        <span style={{paddingRight: '5px'}}>{row.gpt_text}</span>
                      </Typography>
                      </div>
                      <MinimizedTokenInfo token={row.symbol} />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                          Last Updated: {formatTimestampUTC(row.timestamp)} UTC
                        </Typography>
                        <div>
                          <Link to={`/tokens/${row.symbol}`} style={{ textDecoration: 'none' }}>
                            <IconButton style={{ boxShadow: '#ebebeb 1px 1px 1px 1px' }} aria-label="account-balance">
                              <InfoIcon style={{ color: '#333' }} />
                            </IconButton>
                          </Link>
                         </div>
                      </div>
                    </>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        </>
      
    );
    
}  

export default PatternForecastTable;