import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import './App.css';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Logo from './images/logo.png';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, TextField, ListItemAvatar, Avatar, CssBaseline, Typography, Button, Tooltip, List, ListItemText, Divider, ListItem, Drawer, IconButton, Box } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import newUi from './styles/newUi';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Preferences from './forms/Preferences';
import { AuthContext } from './AuthContext';
import { SignalCount } from './forms/dashboards/todaysSignals';
import { Menu as MenuIcon, AutoAwesome, BatchPrediction as BatchPredictionIcon, Troubleshoot as TroubleshootIcon, OnlinePrediction as OnlinePredictionIcon, Home as HomeIcon, Grading as GradingIcon, Settings as SettingsIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  appBar: { zIndex: theme.zIndex.drawer + 1, border: '0px' },
  secondaryAppBar: {
    background: '#fff',
    marginTop: '64px',
    marginBottom: '64px',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    background: '#fff',
    borderBottom: '1px solid #DDD',
    borderTop: '1px solid #DDD',
  },
  menuButton: { marginRight: theme.spacing(2) },
  drawer: { width: 250 },
  drawerPaper: { width: 350, backgroundColor: 'white', color: '#1a1a1a', fontSize: '0.9em', fontWeight: '600' },
  content: { flexGrow: 1, padding: theme.spacing(3) },
  listItem: {
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
      '& $listItemText': {
        fontWeight: 'bold',
        color: 'black'
      },
    },
  },
  listItemText: {},
  submenuContainer: {
    paddingLeft: theme.spacing(2),
    backgroundColor: 'white',
  },
  submenu: {
    paddingLeft: theme.spacing(4),
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: 'black'
    },
  },
}));

export default function ClippedDrawer() {
  const { auth, logout } = useContext(AuthContext);
  const [tokens, setTokens] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTokens, setFilteredTokens] = useState([]);
  const classes = useStyles();
  const newUiClass = newUi();
  const [buttonTitle, setButtonTitle] = useState('');
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [appBarBackground, setAppBarBackground] = useState('#eeeeee');
  const theme = useTheme();
  

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const submenuRef = useRef(null);

  const toggleSettings = () => { setSettingsOpen(!isSettingsOpen); };
  const closeSettings = () => { setSettingsOpen(false); };
  const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); };
  const handleMenuClick = (menu) => { setOpenSubMenu(openSubMenu === menu ? null : menu); };
  const handleSubMenuClick = (event) => { event.stopPropagation(); };
  const NavContainer = styled(Box)(({ theme }) => ({  flex: 1, }));

  const tradingSignals = [
    { name: 'TA Signals', text: 'Technical indicator & strategy signals', icon: <AutoAwesome />, path: '/signals' },
    { name: 'Pattern Signals', text: 'Chart pattern trade signals', icon: <AutoAwesome />, path: '/chartSignals' },
    { name: 'Curated Signals', text: 'Detailed trade signals from our team', icon: <AutoAwesome />, path: '/community' },
    { name: 'Support & Resistance', text: 'Check crucial levels', icon: <AutoAwesome />, path: '/supportResistance' },
  ];

  const tokenInformation = [
    { name: 'Analytics Charts', text: 'Pre-defined and insightful graphical analyses', icon: <OnlinePredictionIcon />, path: '/charts' },
    { name: 'Deep AI Forecast', text: 'Check the latest 7-days and 30-days forecasts', icon: <OnlinePredictionIcon />, path: '/forecast' },
    { name: 'Crypto Screener', text: 'Screen more than 200 crypto tokens', icon: <BatchPredictionIcon />, path: '/screener' },
    { name: 'TokenSight', text: 'All token information in one place', icon: <TroubleshootIcon />, path: '/tokens' },
  ];

  const isAllowed = auth.isAuthenticated;
  let email = auth?.user?.email;

  useEffect(() => {
    if (isAllowed) { setButtonTitle("Logout"); }
    else { setButtonTitle("Login"); }
  }, [isAllowed]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setOpenSubMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside, { passive: true });
    return () => {  document.removeEventListener('mousedown', handleClickOutside); };
  }, [submenuRef]);

  const initiateLogin = () => {
    if (isAllowed) {
      logout();
      navigate('/auth');
    } else { window.location.href = 'https://app.crypto-os.pro/auth'; }
  };

  /** Fetch complete data for logos and forecasts **/
  useEffect(() => {
    fetch('/api/cryptologos')
        .then((res) => res.json())
        .then((data) => {
            if (data.error) {  console.error(data.error); } 
            else { setTokens(data.map(item => ({ symbol: item.symbol, logo_url: item.logo_url }))); }
        });
}, []);

useEffect(() => {
  if (searchTerm.length > 0) {
      const results = tokens.filter(token => token.symbol.toLowerCase().includes(searchTerm.toLowerCase()) );
      setFilteredTokens(results); } 
  else { setFilteredTokens([]); }
}, [searchTerm, tokens]);


/** Main Handler for Token Change */
const handleTokenChange = (tokenValue) => {
  navigate(`/tokens/${tokenValue}`);

  setSearchTerm('');  
};

  const drawer = (
    <div>
    <NavContainer>
      <NavLink to="/" style={{ color: '#1a1a1a', display: 'flex', alignItems: 'center', textDecoration: 'none', paddingLeft: '15px', paddingTop: '20px', paddingBottom: '10px' }}>
        <img src={Logo} style={{ width: '20px' }} alt="Crypto OS Logo" />
        <Typography style={{ marginLeft: '5px', color: 'black', fontSize: '1.1em' }}>
          Crypto OS
        </Typography>
      </NavLink>
      <List>
        <ListItem button component={NavLink} to="/" className={classes.listItem}>
          <ListItemText>
            <Typography style={{ fontSize: '1.1em', fontWeight: '600' }}>
              Home
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleMenuClick('signals')} className={classes.listItem}>
          <ListItemText>
            <Typography style={{ fontSize: '1.1em', fontWeight: '600' }}>
              Trading Signals
            </Typography>
          </ListItemText>
          {openSubMenu === 'signals' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItem>
        {openSubMenu === 'signals' && (
          <div className={classes.submenuContainer} ref={submenuRef}>
            {tradingSignals.map(signal => (
              <ListItem button key={signal.path} component={NavLink} to={signal.path} className={classes.submenu} onMouseDown={handleSubMenuClick}>
                <ListItemText>
                  <Typography style={{ fontSize: '1em', fontWeight: '600' }}>
                    {signal.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </div>
        )}
        <Divider />
        <ListItem button component={NavLink} to="/bots" className={classes.listItem}>
          <ListItemText>
            <Typography style={{ fontSize: '1.1em', fontWeight: '600' }}>
              Trading Bots
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleMenuClick('info')} className={classes.listItem}>
          <ListItemText>
            <Typography style={{ fontSize: '1.1em', fontWeight: '600' }}>
              Data & Forecasts
            </Typography>
          </ListItemText>
          {openSubMenu === 'info' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItem>
        {openSubMenu === 'info' && (
          <div className={classes.submenuContainer} ref={submenuRef}>
            {tokenInformation.map(signal => (
              <ListItem button key={signal.path} component={NavLink} to={signal.path} className={classes.submenu} onMouseDown={handleSubMenuClick}>
                <ListItemText>
                  <Typography style={{ fontSize: '1em', fontWeight: '600' }}>
                    {signal.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </div>
        )}
        <Divider />
        <ListItem button component="a" href="https://docs.crypto-os.pro" target="_blank" rel="noopener noreferrer" className={classes.listItem}>
          <ListItemText>
            <Typography style={{ fontSize: '1.1em', fontWeight: '600' }}>
              Documentation
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider />
      </List>
    </NavContainer>
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
      <ListItem button onClick={toggleSettings} className={classes.listItem}>
        <Typography style={{ fontSize: '1.1em', fontWeight: '600', marginLeft: '10px' }}>
          Preferences
        </Typography>
      </ListItem>
    </Box>
  </div>

  );

  return (
    <>
      <CssBaseline />
      <div className={newUiClass.mainClass}>
        <AppBar position="fixed" className={classes.appBar} style={{ boxShadow: '0px', backgroundColor: '#fff' }}>
          <Toolbar style={{ display: 'flex', alignItems: 'center', paddingTop: '15px', paddingBottom: '25px' }}>
          {!isMobile &&
          <>
          
          <Typography style={{ textAlign: 'center', paddingBottom: '10px', paddingRight: '10px', color: '#1a1a1a', fontSize: '0.9em', fontWeight: '400' }}>
            Today Signal Count: <SignalCount /></Typography>      
            
        
            
</>
          }
          {isMobile && 
          <Typography style={{ textAlign: 'center', paddingBottom: '10px', paddingRight: '10px',paddingTop: '5px', color: '#1a1a1a', fontSize: '0.9em', fontWeight: '400' }}>
          <i>Crypto OS</i> </Typography>
          }
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginBottom: '15px', marginTop: '0px' }}>
              {!isAllowed && !isMobile && (
                <a href='https://app.crypto-os.pro/auth/'>
                  <Button style={{ background: '#3558FF', border:'2px solid #3558FF',  color: '#fff', textTransform: 'none', fontSize: '0.9em', marginRight: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
                    Sign Up For Free 🔥
                  </Button>
                </a>
              )}
              {!isAllowed && isMobile && (
                <a href='https://app.crypto-os.pro/auth'>
                  <Button style={{ background: '#3558FF', marginRight: '10px', paddingLeft: '20px', textTransform: 'none', paddingRight: '20px' }}>
                    Sign Up
                  </Button>
                </a>
              )}
              <Button onClick={initiateLogin} style={{ textTransform: 'none', background: 'rgba(255,255,255,0', border:'1px solid #212C4D', fontSize: '0.9em', paddingLeft: '25px', paddingRight: '25px', color: '#212C4D', marginRight: '10px' }}>
                {buttonTitle}
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <AppBar position="fixed" className={classes.secondaryAppBar}>
          <Toolbar className={classes.toolbar}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              {!isMobile && (
                <>
                  <NavLink to="/" style={{ color: '#1a1a1a', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <img src={Logo} style={{ width: '20px' }} />
                    <Typography className={newUiClass.mobileHeaderTwo} style={{ marginLeft: '5px', marginRight: '25px', color: 'black', fontSize: '1.1em' }}>
                      Crypto OS
                    </Typography>
                  </NavLink>
                  <Button style={{ color: '#1a1a1a', marginRight: '25px', textTransform: 'none' }} onClick={() => handleMenuClick('signals')}>
                    Trading Signals
                    {openSubMenu === 'signals' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    {openSubMenu === 'signals' && (
                      <div className={newUiClass.submenu} id="signals">
                        {tradingSignals.map(signal => (
                          <div key={signal.path} style={{ marginBottom: '15px', paddingLeft: '15px', paddingRight: '15px', marginTop: '10px' }} onMouseDown={handleSubMenuClick}>
                            <NavLink to={signal.path} style={{ color: '#1a1a1a', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '40px', height: '40px', borderRadius: '5px', backgroundColor: '#efefef', marginRight: '10px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <KeyboardDoubleArrowRightIcon />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                                  <span style={{ fontWeight: '500', fontSize: '1em', lineHeight: '1.2', textAlign: 'left' }}>{signal.name}</span>
                                  <span style={{ color: '#333', fontWeight: '400', fontSize: '0.9em', textAlign: 'left' }}>{signal.text}</span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    )}
                  </Button>
                  <NavLink to="/bots" style={{ color: '#1a1a1a', textDecoration: 'none', marginRight: '25px' }}>
                    <Button style={{ color: '#1a1a1a', textTransform: 'none' }}>Trading Bots</Button>
                  </NavLink>
                  <Button style={{ color: '#1a1a1a', marginRight: '25px', textTransform: 'none' }} onClick={() => handleMenuClick('info')}>
                    Data & Forecasts
                    {openSubMenu === 'info' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    {openSubMenu === 'info' && (
                      <div className={newUiClass.submenu} id="info">
                        {tokenInformation.map(signal => (
                          <div key={signal.path} style={{ marginBottom: '15px', paddingLeft: '15px', paddingRight: '15px', marginTop: '10px' }} onMouseDown={handleSubMenuClick}>
                            <NavLink to={signal.path} style={{ color: '#1a1a1a', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '40px', height: '40px', borderRadius: '5px', backgroundColor: '#efefef', marginRight: '10px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <KeyboardDoubleArrowRightIcon />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                                  <span style={{ fontWeight: '500', fontSize: '1em', lineHeight: '1.2', textAlign: 'left' }}>{signal.name}</span>
                                  <span style={{ color: '#333', fontWeight: '400', fontSize: '0.9em', textAlign: 'left' }}>{signal.text}</span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    )}
                  </Button>
                  <Tooltip title="Documentation">
                    <a href="https://docs.crypto-os.pro" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1a1a1a' }}>
                      <Button style={{ color: '#1a1a1a', textTransform: 'none' }}>Documentation</Button>
                    </a>
                  </Tooltip>
                  <div style={{ flexGrow: 1 }} />
                  <div className={newUiClass.tokenSightSearchContainer2}>
                    {tokens.length > 0 && (
                        <TextField  
                            variant="outlined" 
                            size="small" 
                            fullWidth={true} 
                            value={searchTerm} 
                            placeholder="Search Token"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className={newUiClass.tokenSightSearchInput}
                        />
                    )}
                    {filteredTokens.length > 0 && (
                        <List className={newUiClass.tokenSightList2}>
                            {filteredTokens.map((token) => (
                                <ListItem button key={token.symbol} onClick={() => handleTokenChange(token.symbol)}>
                                    <ListItemAvatar>
                                        <Avatar src={token.logo_url} className={newUiClass.tokenSightAvatar} />
                                    </ListItemAvatar>
                                    <ListItemText primary={token.symbol} className={newUiClass.tokenSightListItemText} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </div>
                  <NavLink onClick={toggleSettings} style={{ color: '#1a1a1a', textDecoration: 'none' }}>
                <Tooltip title="Settings">
                  <IconButton color="inherit">
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </NavLink>
                </>
              )}
              {isMobile && (
                <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerToggle} className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Drawer
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            {drawer}
          </Drawer>
        </nav>
        <main className={newUiClass.mainOutlet} style={{ paddingTop: '60px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <Outlet />
        </main>
        {isSettingsOpen && <Preferences isOpen={isSettingsOpen} onClose={closeSettings} />}
      </div>
    </>
  );
}
