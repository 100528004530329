import React from 'react';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement, 
    Filler,
    TimeScale 
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    TimeScale
);

const MinimalLineChart = ({ data }) => {
    const sortedData = data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    // Determine the color based on whether the chart is rising or declining
    const isDeclining = sortedData[0]?.close > sortedData[sortedData.length - 1]?.close;
    const lineColor = isDeclining ? '#F1948A' : '#82E0AA'; // Stronger red if declining, stronger green if rising


    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    parser: 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'',
                },
                grid: {
                    display: false,  // Hide x-axis grid lines
                },
                ticks: {
                    display: false,  // Hide x-axis ticks
                },
                title: {
                    display: false,  // Hide x-axis title
                },
                border: {
                    display: false,  // Hide x-axis border
                },
            },
            y: {
                beginAtZero: false,
                grid: {
                    display: false,  // Hide y-axis grid lines
                },
                ticks: {
                    display: false,  // Hide y-axis ticks
                },
                title: {
                    display: false,  // Hide y-axis title
                },
                border: {
                    display: false,  // Hide y-axis border
                },
            },
        },
        elements: {
            point: {
                radius: 0,  // Hide data points
            },
            line: {
                borderColor: lineColor,  // Dynamic line color
                borderWidth: 2,
                tension: 0.1,
                fill: 'start',
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        return null;
                    }
                    return getGradient(ctx, chartArea, lineColor);
                },
            },
        },
        plugins: {
            legend: {
                display: false,  // Hide the legend
            },
            tooltip: {
                enabled: false,  // Disable tooltips
            },
            datalabels: {
                display: false,  // Hide data labels
            },
        },
        maintainAspectRatio: false,
    };

    const chartData = {
        datasets: [
            {
                data: sortedData.map(entry => ({
                    x: entry.timestamp,
                    y: parseFloat(entry.close),
                })),
            },
        ],
    };

    const chartRef = React.useRef(null);

    const getGradient = (ctx, chartArea, color) => {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(255,255,255,255)');  // Start with the line color
        gradient.addColorStop(1, 'rgba(255,255,255,255)');   // Transparent color at the top

        return gradient;
    };

    return (
        <Line
            data={chartData}
            options={options}
            ref={chartRef}
        />
    );
};

export default MinimalLineChart;
