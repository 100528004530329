export const getKeys = async (email, attempt = 1) => {
    try {
        const response = await fetch('/api/get-keys', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mail: email })
        });

        if (response.status === 404) { return { privateKey: '', publicKey: '' }; }
        if (!response.ok) { throw new Error(`HTTP error! Status: ${response.status}`); }

        const data = await response.json();
        if (data.exchange) return data;
        return { privateKey: '', publicKey: '' };
        
    } catch (error) {
        if (error.code === 'ERR_NETWORK_CHANGED' && attempt <= 3) {
            
            return getKeys(email, attempt + 1);
          } else {
            throw error;
          }}
};
