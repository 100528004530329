import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import newUi from '../../styles/newUi';

export const SignalDashboard = () => {
  const classes2 = newUi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [m, setM] = useState(0);
  const [h, setH] = useState(0);
  const [d, setD] = useState(0);

  useEffect(() => {
    const fetchSignals = async () => {
      try {
        setError('');
        setLoading(true);
        const response = await axios.get('/api/get-today-signal-counts');
        const data = response.data;
        setD(data['1D'] || 0);
        setH(data['1H'] || 0);
        setM(data['15M'] || 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSignals();
  }, []);

  

  if (loading) return null;
  if (error) return <div>Error: {error}</div>;

  const signalData = [
    { title: "Signal Count", subtitle: "Today, 15M Chart", value: m },
    { title: "Signal Count", subtitle: "Today, 1H Chart", value: h },
    { title: "Signal Count", subtitle: "Today, Daily Chart", value: d }
  ];

  return (
    <>
        <Grid md={4} xs={12} >
            <Box className={classes2.featureHeaderMasterContainerLeft} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
              <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, 15M Chart</i> </span> <br/>
                <span className={classes2.dashboardBigNumber}>{m}</span>
              </div>
            </Box>
            </Grid>
          
        <Grid md={4} xs={12}>
            <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
            <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, 1H Chart</i> </span> <br/>
                    <span className={classes2.dashboardBigNumber}>{h}</span>
                </div>
            </Box>
        </Grid>
        <Grid md={4} xs={12}>
           <Box className={classes2.featureHeaderMasterContainer} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
                <div className={classes2.featureHeaderContainer} style={{fontSize: '1.2em', marginBottom: '5px'}}><b>Signal Count</b> <br/> <span style={{fontSize: '0.8em'}}><i>Today, Daily Chart</i> </span> <br/>
                    <span className={classes2.dashboardBigNumber}>{d}</span>
                </div>
            </Box>
        </Grid>
   </>
  );
};

