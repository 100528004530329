  // useAddPrices.js
  import { useState } from 'react';
  import { getPrice } from './cryptoPrices';

  const useAddPrices = () => {
    const [selectedData, setSelectedData] = useState([]);
    
    const addPrices = async (data) => {
      const addedPrices = await Promise.all(
        data.map(async item => {
          if(item.symbol !== '') {
            const price1 = await getPrice([item.symbol]); 
            if (price1 && price1[item.symbol]) {
              const { price, percentChange24h, name } = price1[item.symbol];
              return {
                ...item,
                price,
                pctChange: percentChange24h,
                fullName: name
              };
            }
            return item;
          }
          return item;
        })
      );
  
      const filteredData = addedPrices.filter(item => 
        item.price !== null && 
        item.price !== undefined && 
        item.price !== ''
      );
  
      setSelectedData(filteredData);
  };
  
    
    return { selectedData, addPrices };
  };

  export default useAddPrices;
