import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import newUi from '../../styles/newUi';
import { settings } from '../../elements/carousel';

export const TrendingSignals = ({ onLoad }) => {
  const classes2 = newUi();
  const [signals, setSignals] = useState({ '1D': [], '4H': [], '1H': [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => { if (onLoad) onLoad(); }, [onLoad]);

  useEffect(() => {
    const fetchSignals = async () => {
      try {
        setError('');
        setLoading(true);
        const response = await axios.get('/api/get-latest-five-signals');
        const groupedSignals = response.data.reduce((acc, signal) => {
          if (!acc[signal.timeframe]) {  acc[signal.timeframe] = []; }
          acc[signal.timeframe].push(signal);
          return acc;
        }, { '1D': [], '4H': [], '1H': [] });
        setSignals(groupedSignals); } 
      catch (err) { setError(err.message); } 
      finally {setLoading(false); }
    };
    fetchSignals();
  }, []);
 
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const timeframes = Object.keys(signals);

  return (
    <Box className={classes2.mainPageBox} style={{paddingTop: '0px'}} >
      <Carousel {...settings}>
        {timeframes.map((timeframe, index) => (
          <Box key={index} className={classes2.mainPageBox} >
               <Typography className={classes2.mainPageBoxTitle}>
              <span role="img" aria-label="fire">🔥</span> Latest Trade Signals {timeframe}
            </Typography>
            <List style={{ width: '100%' }}>
              {signals[timeframe].map((signal, i) => (
                <ListItem key={i} style={{paddingTop: '4px', paddingBottom: '4px'}}>
                  <ListItemAvatar>
                    <Avatar src={signal.logo_url} style={{width: '30px', height: '30px'}} />
                  </ListItemAvatar>
                  <ListItemText
                  
                    primary={`${signal.symbol} - ${signal.trade_signal}`}
                    secondary={signal.direction}
                    primaryTypographyProps={{ style: { color: '#1a1a1a', fontWeight: 'bold', fontSize: '0.85em' } }}
                    secondaryTypographyProps={{ style: { color: signal.direction == 'Short' ? 'red' : 'green', fontSize: '0.75em' } }}
               
                    style={{ color: '#fff'}}
                  />
                  <ListItemSecondaryAction>
                    <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: '0.9em', textAlign: 'right', color:'#1a1a1a'}}>
                       {signal.profit_potential}%<br/><div style={{color: 'white', textAlign:'right', fontWeight: '200', fontSize: '0.7em', color:'#1a1a1a'}}><i>Potential</i></div>
                    </Typography>
                    
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default TrendingSignals;
