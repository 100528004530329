export const getPrice = async (tokensArray) => {
  console.log(tokensArray);
    try {
        const tokensString = tokensArray.join(',');
        const response = await fetch(`/api/crypto-prices?tokens=${tokensString}`);
        const prices = await response.json();
        return prices;
    } catch (error) {
        console.error("Error fetching prices from server:", error);
    }
}


export const getPrice2 = async (tokensArray) => {
    try {
      const tokensString = tokensArray.join(',');
      const response = await fetch(`/api/crypto-prices?tokens=${tokensString}`);
      const prices = await response.json();
      return prices;
    } catch (error) {
      console.error("Error fetching prices from server:", error);
      return {}; // Return an empty object in case of error
    }
  };
  
