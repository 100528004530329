const getPrice = async (tokensArray) => {
  try {
      const tokensString = tokensArray.join(',');
      const response = await fetch(`/api/crypto-prices?tokens=${tokensString}`);
      const prices = await response.json();
      return prices;
  } catch (error) {
      console.error("Error fetching prices from server:", error);
  }
}


const getPrice2 = async (tokensArray) => {
  try {
    const tokensString = tokensArray.join(',');
    const response = await fetch(`/api/crypto-prices?tokens=${tokensString}`);
    const prices = await response.json();
    return prices;
  } catch (error) {
    console.error("Error fetching prices from server:", error);
    return {}; // Return an empty object in case of error
  }
};

export const addPrices = async (symbol) => {
    const priceData = await getPrice([symbol]);  
    const price = priceData[symbol].price;
    const pctChange = priceData[symbol].percentChange24h;
    const fullName = priceData[symbol].name;
    return {
        symbol: symbol,
        price: price,
        pctChange: pctChange,
        fullName: fullName
    };
};


export const addMultiplePrices = async (data) => {
    const addedPrices = await Promise.all(
        data.map(async item => {
            const priceData = await getPrice([item.symbol]);  // Fetch the price for only this symbol
            const price = priceData[item.symbol].price;
            const pctChange = priceData[item.symbol].percentChange24h;
            const fullName = priceData[item.symbol].name;
            return {
                ...item,
                price: price,
                pctChange: pctChange,
                fullName: fullName
            };
        })
    );
    return addedPrices; // Make sure to return the result
};

export const addMultiplePrices2 = async (data) => {
  try {
    // Extract all symbols from data
    const symbols = data.map(item => item.symbol);
    // Fetch price data for all symbols in one go
    const priceData = await getPrice2(symbols);

    // Map the price data back to your items
    const addedPrices = data.map(item => {
      const priceInfo = priceData[item.symbol];
      return {
        ...item,
        price: priceInfo?.price || 'Data not available',
        pctChange: priceInfo?.percentChange24h || 'Data not available',
        fullName: priceInfo?.name || 'Data not available'
      };
    });

    return addedPrices;
  } catch (error) {
    console.error("Error processing prices:", error);
    return data; // Return original data in case of error
  }
};



export const addMultiplePricesWithoutTokenColumn = async (data) => {
    const addedPrices = await Promise.all(
      data.map(async (token) => {
        const priceData = await getPrice([token]); // Fetch the price for the token
        const price = priceData[token].price;
        const pctChange = priceData[token].percentChange24h;
        const fullName = priceData[token].name; // Assuming "name" is the full name
        return {
          name: token, // Assign the token name to the "name" property
          price: price,
          pctChange: pctChange,
          fullName: fullName,
        };
      })
    );
    return addedPrices; // Make sure to return the result
  };
  