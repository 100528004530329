import React from "react";
import { Card, CardContent, Typography, CardMedia, Grid } from "@material-ui/core";

export const VideoItem = ({ video }) => {
console.log(video);
    const extractDescription = (description) => {
        return description.split(' ').slice(0, 40).join(' ') + '...';
    };

    return (
        <Card 
            style={{
                marginBottom: '15px',
                borderRadius: '10px',
                boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
                backgroundColor: 'white',
                color: 'black',
                padding: '10px',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CardMedia
                        component="img"
                        image={video.snippet.thumbnails.medium.url}
                        style={{ borderRadius: '5px', width: '100%' }}
                        alt="video thumbnail"
                    />
                </Grid>
                <Grid item xs={8}>
                    <CardContent style={{ padding: '0' }}>
                        <Typography variant="h6" component="p" style={{ fontWeight: '600', fontSize:'1.1em', marginBottom: '8px' }}>
                            {video.snippet.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" style={{ marginBottom: '10px', fontSize:'0.9em' }}>
                            {new Date(video.snippet.publishedAt).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" color="#1a1a1a" component="p" style={{ fontWeight: '400', fontSize:'0.9em' }}>
                            {extractDescription(video.snippet.description)}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

export default VideoItem;
