import React, {useState, useEffect, useContext} from 'react';
import Chip from '@mui/material/Chip';
import {Grid, Button, Typography, TextField, IconButton, InputAdornment } from "@material-ui/core";
import sharedStyles from '../../styles/sharedStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getKeys } from '../../functions/tradingData';
import { useNavigate } from 'react-router-dom';
import newUi from '../../styles/newUi';
import { longColor, shortColor } from '../../data/basics';
import CloseIcon from '@mui/icons-material/Close';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { AuthContext } from '../../AuthContext';

const BotOrder = ({onBotClick, closeBotClick, fullName, direction, latestPrice, symbol, signal, directionTrade, timeframe}) => {
  const { auth, logout } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;
  
    const navigate = useNavigate();
    const newUiClass = newUi();
    const [isLoading, setIsLoading] = useState(false);
    const classes = sharedStyles();
    const [open, setOpen] = useState(false);
    
    const [ready, setReady] = useState(false);
    
    
    const handleClose = () => { setOpen(false); };
    
    const [exchange, setExchange] = useState('');
    const [privateKey, setPrivateKey] = useState();
    const [secretKey, setSecretKey] = useState();
    const [amount, setAmount] = useState(1); 
    const [totalPrice, setTotalPrice] = useState(25); 
    const [takeProfit, setTakeProfit] = useState('');
    const [stopLoss, setStopLoss] = useState('');

    
    useEffect(() => {
      const fetchData = async () => { 
        const keysData = await getKeys(email);
        setExchange(keysData.exchange);
        setPrivateKey(keysData.privateKey);
        setSecretKey(keysData.secretKey);  
        if(keysData.exchange == '') {
           alert("Please configure your exchange before executing a trade. Go to Settings/Exchange Settings")

        }
        setReady(true)     
      };      
      fetchData();
    }, [email]);

    useEffect(() => {
      if (!symbol || !exchange) { return; }  
      fetch(`/api/checkSymbolExchange?symbol=${encodeURIComponent(symbol)}&exchange=${encodeURIComponent(exchange)}`)
        .then(response => {
          if (!response.ok) { throw new Error('Network response was not ok'); }
          return response.json();
        })
        .then(data => { if (!data.available) { alert("This token is not available for trading on your exchange. Your bot won't trigger any successful trades.") }  })
        .catch(error => { console.error('Fetch Error:', error); })
    }, [symbol, exchange]);


    useEffect(() => { if (latestPrice > 0) {  setAmount(totalPrice / latestPrice); } }, [totalPrice, latestPrice]);
    
    const handleTotalPriceChange = (e) => {
      const newTotalPrice = e.target.value === '' ? '' : Number(e.target.value);
      setTotalPrice(newTotalPrice);
    };

    const handleTakeProfitChange = (e) => {
      const newTakeProfit = e.target.value === '' ? '' : parseFloat(e.target.value).toFixed(2);
      setTakeProfit(newTakeProfit);
    };
    
    const handleStopLossChange = (e) => {
      const newStopLoss = e.target.value === '' ? '' : parseFloat(e.target.value).toFixed(2);
      setStopLoss(newStopLoss);
    };

    const openTrade = () => {
      setIsLoading(true);
      const shortSignal = signal.split(':')[0].trim();
        
      if (!hasAccess) {
        alert("Trading features are available in Trading Tier only. Please upgrade.")
        setIsLoading(false);
        return
      }

      if (!privateKey || !secretKey || !exchange) {
        alert("Please configure your exchange before executing a trade. Go to Settings/Exchange Settings")
        setIsLoading(false);
        return
      }     

      const finalTakeProfit = takeProfit / 100;
      const finalStopLoss = stopLoss / 100;

      fetch('/api/addBot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          email: email,
          symbol: symbol,
          direction: directionTrade,
          timeframe: timeframe,
          alert: shortSignal,
          details: [{
            amount: totalPrice,
            direction: directionTrade,
            stopLoss: finalStopLoss,
            takeProfit: finalTakeProfit
          }]
        })
      })
      .then(response => response.json()) 
      .then(data => {
        alert(data.message);
        setIsLoading(false);
      })
      .catch((error) => { 
        console.error('Fetch Error:', error); 
        setIsLoading(false);
      });
    }
    const panelClasses = `${newUiClass.settingsPanelSmall} ${onBotClick ? newUiClass.settingsPanelOpen : ''}`;
  return (
  <div className={panelClasses} style={{padding: '15px', color: '#1a1a1a'}}>
    <div style={{  padding: '10px', paddingTop: '30px',  width: '100%', marginTop: '0%', marginBottom: '3%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={classes.mobileHeader} style={{ textAlign: 'left', paddingLeft:'15px' }}>Trading Bot Setup</div>
          <IconButton className={newUiClass.actionItemFrame} onClick={closeBotClick}>
            <CloseIcon className={newUiClass.actionItem}/>
          </IconButton>
        </div>
        <br/>
      <Grid container spacing={2} style={{ marginBottom: '10px'}}>
        {(!ready) && <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '40px', marginBottom: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <CircularProgress style={{ color: '#fff' }}/>
                        </div>}
        {(ready) && <Grid item xs={12} md={12}>
        <div style={{marginTop: '5px', marginBottom: '10px', paddingLeft: '10px'}}>
              <Chip label={`${fullName} (${symbol})`} className={newUiClass.standardChipExtended} style={{backgroundColor: '#333'}}/>
              <Chip label={`${latestPrice} USD`} className={newUiClass.standardChipExtended} style={{backgroundColor: '#333'}}/>
              {direction && (
                <Chip label={direction} className={newUiClass.standardChipExtended} 
                  style={{ backgroundColor: direction === 'Long' ? longColor : direction === 'Short' ? shortColor : '',}}
                />
              )}
              <Chip label={exchange} className={newUiClass.standardChipExtended} style={{backgroundColor: '#333'}}/>
            </div>
            {exchange == 'BYBIT' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px',}}><i>Your are about to set up a <b>bot that will execute a Derivative market order for {fullName} every time a {signal} is identified</b>. Opening an order will use the leverage you have defined on your trading platform.</i> </Typography>
            )}
            {exchange == 'MEXC' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to set up a <b> bot that will execute Spot market limit orders for {fullName} every time a {signal} is identified</b>. </i> </Typography>
            )}
            {exchange == 'BINANCE' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to set up a <b> bot that will execute Spot market market orders for {fullName} every time a {signal} is identified</b>. </i> </Typography>
            )}
            {exchange == 'BLOFIN' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to set up a <b> bot that will execute future market market orders for {fullName} every time a {signal} is identified</b>. </i> </Typography>
            )}
            
           
      <div>
        <TextField fullWidth  type="number" value={totalPrice} onChange={handleTotalPriceChange} variant="outlined" margin="normal" className={newUiClass.inputField}
            InputProps={{ inputProps: { step: "any",  },
            className: newUiClass.inputBase,
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon className={newUiClass.inputIcon}  /> <b>Trade Size</b>
                </InputAdornment>
              ),
            }}
        />
        
      </div>
      {exchange == 'BINANCE' && (
      <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Grid item xs={6}>
          <TextField fullWidth type="number" value={takeProfit} onChange={handleTakeProfitChange} variant="outlined" margin="normal"
            className={newUiClass.inputField}
            InputProps={{
              inputProps: { step: "0.01", min: "0" }, 
              className: newUiClass.inputBase,
              startAdornment: (
                <InputAdornment position="start">
                  <b>TP %</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth type="number" value={stopLoss} onChange={handleStopLossChange} variant="outlined" margin="normal"
            className={newUiClass.inputField}
            InputProps={{
              inputProps: { step: "0.01", min: "0" }, 
              className: newUiClass.inputBase,
              startAdornment: (
                <InputAdornment position="start">
                  <b>SL %</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      )}
      <Typography style={{marginTop: '10px', fontSize: '0.9em', paddingLeft:'5px'}} onClick={() => { navigate('/bots');}}><u>Click here to manage your active bots.</u></Typography>
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Button onClick={openTrade} fullWidth className={newUiClass.loginButton} style={{height: '50px'}}>
        {isLoading ? <CircularProgress size={24} /> : "Activate Your Bot"}
            </Button>
      </div>
    </Grid>}
    
    </Grid> 
    
    </div>
    </div>
  );
};

export default BotOrder;