import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IconButton, Typography, Tooltip } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const LikeButton = ({ userEmail, contentId, type, content }) => {
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);


  useEffect(() => {
    axios.get(`https://app.crypto-os.pro/api/contentLikes/${contentId}/${type}`)
      .then(response => { setLikes(response.data.length); });

    if (userEmail) {
      axios.get(`https://app.crypto-os.pro/api/userLikes/${userEmail}/${contentId}/${type}`)
        .then(response => { setLiked(response.data.liked); });
    }
  }, [userEmail, contentId, type]);

  const handleLike = (event) => {
    if (!userEmail) return; 

    event.stopPropagation(); 

    const like = !liked;
    let details = {};

    if (type === 'trade_signal') {
      details = {
        symbol: content.symbol,
        signal: content.trade_signal,
        timeframe: content.timeframe,
        direction: content.direction
      };
    } else if (type === 'content_post') {
      details = {
        description: content
      };
    }

    axios.post('https://app.crypto-os.pro/api/setUserLikes', { userEmail, contentId, type, like, details })
      .then(() => {
        setLikes(like ? likes + 1 : likes - 1);
        setLiked(like);
      })
      .catch(error => { console.error('Error updating like status', error); });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '10px', left: '10px' }}>
      <Tooltip title={userEmail ? (liked ? "Unlike" : "Like") : "Please log in to like content!"}>
        <span>
          <IconButton onClick={handleLike} disabled={!userEmail}>
            {liked ? <FavoriteIcon style={{ color: '#ff1744' }} /> : <FavoriteBorderIcon style={{ color: '#ff1744' }} />}
          </IconButton>
        </span>
      </Tooltip>
      {likes > 0 && <Typography>{likes}</Typography>}
    </div>
  );
};

export default LikeButton;
