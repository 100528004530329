import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      backgroundColor: '#fff',
      border: '1px solid #dddddd !important',
      borderRadius: '5px',
      
      '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'transparent',  },
        '&:hover fieldset': { borderColor: 'transparent',  },
        '&.Mui-focused fieldset': { borderColor: 'transparent',  },
        
      },
    },
    formControl1: {
        minWidth: 120,
        backgroundColor: '#fff',
        borderRadius: '5px',
        marginBottom: '5px',
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: 'transparent',  },
          '&:hover fieldset': { borderColor: 'transparent',  },
          '&.Mui-focused fieldset': { borderColor: 'transparent',  },
          
        },
      },
    select: {
      color: '#1a1a1a !important',
      '&:before': { borderColor: 'transparent', },
      '&:after': { borderColor: 'transparent', },
      '&:not(.Mui-disabled):hover::before': {
        borderColor: '#ddd',
        border: '1px solid #ddd'
      },
    },
    icon: { color: '#333' },
    label: {
      color: '#1a1a1a !important',
      '&.Mui-focused': {
        color: '#1a1a1a !important',
      },
    },
    labelInsideSelect: {
        color: '#1a1a1a !important', 
        paddingLeft: '14px', 
      },
    menuItem: {
        color: '#1a1a1a !important',
        '&:hover': { backgroundColor: 'rgba(17,27,37, 0.06)', },
        '&:selected': { backgroundColor: 'rgba(17,27,37, 0.06)', },
      },
      menuPaper: {
        backgroundColor: 'rgba(255,255,255,255)',
        '& ul': { padding: 0, },
        borderRadius: '5px',
        border: '1px solid #efefef'
      },
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #ddd',
        backgroundColor: 'white',
        width: '100%',
        padding: theme.spacing(1, 2), // Ensure consistent padding with the dropdown items
        '& .MuiTypography-root': {
          flex: 1, // Makes each item take equal space
          textAlign: 'center', // Centers the text
          color: theme.palette.text.primary // Ensure text color consistency
        }
      },
      header: {
        fontWeight: 'bold',
        backgroundColor: theme.palette.background.paper, // Background color for the header
        marginBottom: theme.spacing(0.5), // Space below the header
        '& .MuiTypography-root': {
          fontSize: '0.875rem' // Smaller font size for headers
        }
      }
  }));

  export const SelectControl = ({ label, value, onChange, options }) => {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <Select id={`${label}-select`} label={label} value={value} onChange={onChange}
                displayEmpty 
                className={classes.select}
                renderValue={selected => {
                    const selectedOption = options.find(option => option.value === selected);
                    return (
                        <div className={classes.labelInsideSelect}>
                            <b>{label}</b>: {selectedOption ? selectedOption.label : 'Select...'}
                        </div>
                    );
                }}
                MenuProps={{
                    classes: { paper: classes.menuPaper },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const SelectControlBottomMargin = ({ label, value, onChange, options }) => {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" fullWidth className={classes.formControl1}>
            <Select id={`${label}-select`} label={label} value={value} onChange={onChange}
                displayEmpty 
                className={classes.select}
                renderValue={selected => (
                    <div className={classes.labelInsideSelect}>
                        <b>{label}</b>: {selected ? options.find(option => option.value === selected).label : 'Select...'}
                    </div>
                )}
                MenuProps={{
                    classes: { paper: classes.menuPaper },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};


export const SelectControlPlusLogos = ({ label, value, onChange, options, renderOption }) => {
  const classes = useStyles();

  return (
      <FormControl variant="outlined" fullWidth className={classes.formControl} style={{boxShadow: '0,0,0,0'}}>
          <Select id={`${label}-select`} label={label} value={value} onChange={onChange}
              
              displayEmpty 
              className={classes.select}
              renderValue={selected => (
                  <div className={classes.labelInsideSelect}>
                      <b>{label}</b>: {selected ? options.find(option => option.value === selected).label : 'Select...'}
                  </div>
              )}
              MenuProps={{
                  classes: { paper: classes.menuPaper },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                  },
                  transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                  },
              }}
          >
              {options.map((option) => (
                  <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                      {renderOption ? renderOption(option) : option.label}
                  </MenuItem>
              ))}
          </Select>
      </FormControl>
  );

};

export const SelectControlPlusLogosMulti = ({ label, value, onChange, options, renderOption, multiple }) => {
    const classes = useStyles();
  
    // Adjusted renderValue for handling multiple selections
    const renderValue = multiple
      ? (selected) => selected.map((val) => options.find((option) => option.value === val)?.label || val).join(', ')
      : (selected) => {
          const option = options.find((option) => option.value === selected);
          return option ? renderOption(option) : 'Select...';
        };
  
    return (
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Select
          id={`${label}-select`}
          multiple={multiple}
          value={value}
          onChange={onChange}
          displayEmpty
          className={classes.select}
          renderValue={renderValue} // Use the adjusted renderValue function here
          MenuProps={{
            classes: { paper: classes.menuPaper },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: {
              style: {
                  maxHeight: '70vh', // 70% of the viewport height
              },
          },
          }}
          
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
              {renderOption ? renderOption(option) : option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  

  export function SelectControlPlusLogosFormatted({ label, value, onChange, options }) {
    const renderOption = (option) => (
        <MenuItem value={option.value}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body1">{option.label}</Typography>
                <Typography variant="body2" style={{ color: 'gray' }}>
                    {`${option.successRate}%`}
                </Typography>
                <Typography variant="body2">
                    {`${option.signals} signals`}
                </Typography>
            </div>
        </MenuItem>
    );

    // Improved renderValue function to handle undefined values
    const renderValue = (selected) => {
        const selectedItem = options.find(item => item.value === selected) || {};
        if (!selectedItem || !selectedItem.label) return "Select an item";  // Fallback text or could be empty
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body2">{selectedItem.label}</Typography>
                <Typography variant="body2" style={{ color: 'gray' }}>
                    {`${selectedItem.successRate || ''}%`}
                </Typography>
                <Typography variant="body2">
                    {`${selectedItem.signals || ''} signals`}
                </Typography>
            </div>
        );
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                renderValue={renderValue}
            >
                {options.map((option, index) => renderOption(option))}
            </Select>
        </FormControl>
    );
}


export default SelectControlPlusLogos;
