
import React, {useState, useEffect, useContext} from 'react';
import Chip from '@mui/material/Chip';
import {Grid, Button, Snackbar, Typography, TextField, IconButton, InputAdornment } from "@material-ui/core";
import sharedStyles from '../../styles/sharedStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getKeys } from '../../functions/tradingData';
import newUi from '../../styles/newUi';
import { longColor, shortColor } from '../../data/basics';
import CloseIcon from '@mui/icons-material/Close';
import TollIcon from '@mui/icons-material/Toll';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { AuthContext } from '../../AuthContext';



const ManualOrder = ({onAccountBalanceClick, fullName, direction, latestPrice, symbol}) => {
    const { auth, logout } = useContext(AuthContext);
    let email = auth?.user?.email || '';
    const hasAccess = auth?.isAuthenticated;  
    const newUiClass = newUi();
    const [isLoading, setIsLoading] = useState(false);
    const classes = sharedStyles();
    const [open, setOpen] = useState(false);
    const [exchange, setExchange] = useState('');
    const [privateKey, setPrivateKey] = useState();
    const [secretKey, setSecretKey] = useState();
    const [amount, setAmount] = useState(1); 
    const [totalPrice, setTotalPrice] = useState(latestPrice);
    const [takeProfit, setTakeProfit] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [message, setMessage] = useState('');
 
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') { return; }
      setOpen(false);
    };


    useEffect(() => {
        const fetchData = async () => { 
            const keysData = await getKeys(email);
            setExchange(keysData.exchange);
            setPrivateKey(keysData.privateKey);
            setSecretKey(keysData.secretKey);       
         };
        fetchData();
    }, [email]);

    useEffect(() => { if (!symbol || !exchange) { return; }
      fetch(`/api/checkSymbolExchange?symbol=${encodeURIComponent(symbol)}&exchange=${encodeURIComponent(exchange)}`)
        .then(response => {
          if (!response.ok) { throw new Error('Network response was not ok'); }
            return response.json();
          })
        .then(data => { if (!data.available) { alert("This token is not available for trading on your exchange") } })
        .catch(error => { console.error('Fetch Error:', error); })
    }, [symbol, exchange]);


    useEffect(() => { setTotalPrice(amount * latestPrice); }, [amount, latestPrice]);
    useEffect(() => { if (latestPrice > 0) {  setAmount(totalPrice / latestPrice); } }, [totalPrice, latestPrice]);
    
    const handleAmountChange = (e) => {
      const newAmount = e.target.value === '' ? '' : Number(e.target.value);
      setAmount(newAmount);
    };
    
    const handleTotalPriceChange = (e) => {
      const newTotalPrice = e.target.value === '' ? '' : Number(e.target.value);
      setTotalPrice(newTotalPrice);
    };

    const handleTakeProfitChange = (e) => {
      const newTakeProfit = e.target.value === '' ? '' : parseFloat(e.target.value).toFixed(2);
      setTakeProfit(newTakeProfit);
    };
    
    const handleStopLossChange = (e) => {
      const newStopLoss = e.target.value === '' ? '' : parseFloat(e.target.value).toFixed(2);
      setStopLoss(newStopLoss);
    };

    const openTrade = () => {
      setIsLoading(true);
      let side = '';
      if (direction == 'Long') { side = 'Buy'; } 
      else if (direction == 'Short') { side = 'Sell'; }

      if (!hasAccess) {
        setMessage("Trading features are available in Trading Tier only. Please upgrade.")
        setOpen(true);
        setIsLoading(false);
        return
      }

      if (!privateKey || !secretKey || !exchange) {
        setMessage("Please configure your exchange before executing a trade. Go to Settings/Exchange Settings")
        setOpen(true);
        setIsLoading(false);
        return
      }     
            
      const finalTakeProfit = takeProfit / 100;
      const finalStopLoss = stopLoss / 100;

      fetch('/api/openManualTrade', {
      //  fetch('http://localhost:3000/api/openManualTrade', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          user: email,
          symbol: symbol,
          amount: amount, 
          amount_type: 'amount',    
          side: side,
          takeProfit: finalTakeProfit,
          stopLoss: finalStopLoss
        })
      })
      .then(response => response.json()) // Parse the JSON in the response
      .then(data => {
        setMessage(data.message);
        setOpen(true);
        setIsLoading(false);              
      })
      .catch((error) => { 
        console.error('Fetch Error:', error); 
        setIsLoading(false);
      });
    }
    const panelClasses = `${newUiClass.settingsPanelSmall} ${onAccountBalanceClick ? newUiClass.settingsPanelOpen : ''}`;
    return (
      <div className={panelClasses} style={{padding: '15px', color: '#1a1a1a'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className={classes.mobileHeader} style={{ textAlign: 'left', paddingLeft:'15px' }}>Open A Trade</div>
          <IconButton className={newUiClass.actionItemFrame} onClick={onAccountBalanceClick}>
              <CloseIcon className={newUiClass.actionItem}/>
            </IconButton>
        </div>
        <br/>
      <Grid container spacing={2} style={{ marginBottom: '10px'}}>
        <Grid item xs={12} md={12}>
            <div style={{marginTop: '5px', marginBottom: '10px', paddingLeft: '10px'}}>
              <Chip label={`${fullName} (${symbol})`} className={newUiClass.standardChipExtended} style={{backgroundColor: '#333'}}/>
              <Chip label={`${latestPrice} USD`} className={newUiClass.standardChipExtended} style={{backgroundColor: '#333'}}/>
              {direction && (
                <Chip label={direction} className={newUiClass.standardChipExtended} 
                  style={{ backgroundColor: direction === 'Long' ? longColor : direction === 'Short' ? shortColor : '',}}
                />
              )}
              <Chip label={exchange} className={newUiClass.standardChipExtended} style={{backgroundColor: '#333'}}/>
            </div>
            {exchange == 'BYBIT' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to place a <b>market order for {fullName}</b>. Clicking the "Open Trade" button, will open a <b>Derivative {direction} Position</b>. Please make sure that you have enough funds available. <br/><b>Important: </b>Opening an order will use the leverage you have defined on your trading platform.</i> </Typography>
            )}
            {exchange == 'MEXC' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to place a <b>limit order for {fullName}</b>. Clicking the "Open Trade" button, will open a <b>Spot {direction} Position</b>. Please make sure that you have enough funds available. <br/><b>Important: </b>Opening an order will use the leverage you have defined on your trading platform.</i> </Typography>
            )}
            {exchange == 'BINANCE' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to place a <b>market order for {fullName}</b>. Clicking the "Open Trade" button, will open a <b>Spot {direction} Position</b>. Please make sure that you have enough funds available. <br/><b>Important: </b>Opening an order will use the leverage you have defined on your trading platform.</i> </Typography>
            )}
            {exchange == 'BLOFIN' && (
            <Typography style={{paddingLeft: '10px', fontSize: '0.9em', marginTop: '20px', marginBottom: '10px'}}><i>Your are about to place a <b>market order for {fullName}</b>. Clicking the "Open Trade" button, will open a <b>Future {direction} Position</b>. Please make sure that you have enough funds available. <br/><b>Important: </b>Opening an order will use the leverage you have defined on your trading platform.</i> </Typography>
            )}
            <div>
        <TextField fullWidth type="number" value={amount} onChange={handleAmountChange} variant="outlined" margin="normal" className={newUiClass.inputField}
          InputProps={{ inputProps: { step: "any", },
          lassName: newUiClass.inputBase,
              startAdornment: (
                <InputAdornment position="start">
                  <TollIcon className={newUiClass.inputIcon}  /> <b>Amount</b>
                </InputAdornment>
              ),
            }}
          />
      </div>
      <div>
        <TextField fullWidth  type="number" value={totalPrice} onChange={handleTotalPriceChange} variant="outlined" margin="normal" className={newUiClass.inputField}
            InputProps={{ inputProps: { step: "any",  },
            className: newUiClass.inputBase,
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon className={newUiClass.inputIcon}  /> <b>Total Price</b>
              </InputAdornment>
            ),
          }}
        />
        <Typography style={{fontSize: '0.9em', paddingLeft: '10px', marginTop: '10px'}}><i>Actual price might differ! We do not take any liability for the final order amount!</i></Typography>
      </div>
      {exchange == 'BINANCE' && (
      <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Grid item xs={6}>
          <TextField fullWidth type="number" value={takeProfit} onChange={handleTakeProfitChange} variant="outlined" margin="normal"
            className={newUiClass.inputField}
            InputProps={{
              inputProps: { step: "0.01", min: "0" }, 
              className: newUiClass.inputBase,
              startAdornment: (
                <InputAdornment position="start">
                  <b>TP %</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth type="number" value={stopLoss} onChange={handleStopLossChange} variant="outlined" margin="normal"
            className={newUiClass.inputField}
            InputProps={{
              inputProps: { step: "0.01", min: "0" }, // Allows only numbers with two digits after decimal
              className: newUiClass.inputBase,
              startAdornment: (
                <InputAdornment position="start">
                  <b>SL %</b>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      )}
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Button onClick={openTrade} fullWidth className={newUiClass.loginButton} style={{height: '50px'}}>
        {isLoading ? <CircularProgress size={24} /> : "Open Trade"}
            </Button>
      </div>
    </Grid>
    </Grid> 
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} message={message} />
    </div>
  );
};

export default ManualOrder;