import { SelectControl } from "../elements/SelectControl";
import React, { useState, useEffect, useContext } from "react";
import { Snackbar, Paper, Grid, Typography, Checkbox, TextField } from "@material-ui/core";
import { limitResultsForTier, getHighestSubscription } from "../functions/subscriptionCheck";
import Fade from '@mui/material/Fade';
import { SignalDashboard } from "./dashboards/signalDashboard";
import Fab from '@mui/material/Fab';
import Alert from '@mui/material/Alert';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import newUi from "../styles/newUi";
import SignalEmailAlert from "../functions/signalEmailAlert";
import { SignalsTable } from "../elements/signalsTable";
import { signalToBacktestTranslator } from "../data/backtestTranslator";
import LinearProgress from '@mui/material/LinearProgress';
import { AuthContext } from '../AuthContext';
import { Autocomplete } from "@mui/material";


function Signals() {
  const newUiClass = newUi();
  const { auth, loading: authLoading } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const [hasAccess, setHasAccess] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [selectedSignals, setSelectedSignals] = useState([
    "RSI", "MACD Crossover", "Advanced ADX+PSAR", "EMA Crossover", 
    "Bollinger Bands", "EMA Strategy", "Ichimoku", "Stochastic Bollinger", 
    "Big Extender", "Hyper Scalper", "Parabolic Trader", "Power Scalper", 
    "Tri Trendmaster", "Trend Signal Fusion"
  ]); 
  const [field3, setField3] = useState("1D");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");  
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [open1, setOpen1] = useState(false);
  const toggleDrawer = () => { setOpen1(!open1); };
  const [showData, setShowData] = useState([]);
  const [filter, setFilter] = useState(""); 

  useEffect(() => { window.scrollTo(0, 0); }, []);  

  const handleField3Change = (event) => { setField3(event.target.value);  };
  const handleFilterChange = (event) => { setFilter(event.target.value); }; 
  const handleSort = (event) => { setSortBy(event.target.value); };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpen(false);
  };

  const preprocessBacktestData = (backtestData) => {
    const backtestMap = {};
    backtestData.forEach(item => {
      try {
        if (!item.extended_backtest_results) { throw new Error('No extended_backtest_results attribute'); }
        const parsedData = JSON.parse(item.extended_backtest_results);
        if (!parsedData || typeof parsedData !== 'object') { throw new Error('Parsed data is not an object'); }
        backtestMap[item.symbol] = backtestMap[item.symbol] || {};
        backtestMap[item.symbol][item.timeframe] = backtestMap[item.symbol][item.timeframe] || {};
        Object.keys(parsedData).forEach(signal => {
          const signalData2 = parsedData[signal]["2.0%"];
          const signalData5 = parsedData[signal]["5.0%"];
          const signalData10 = parsedData[signal]["10.0%"];
          if (signalData2) {
            backtestMap[item.symbol][item.timeframe][signal] = {
              long: {
                bestProbability2: signalData2["long_win_rate"],
                detectedSignals2: signalData2["number_long"],
                bestProbability5: signalData5 ? signalData5["long_win_rate"] : null,
                detectedSignals5: signalData5 ? signalData5["number_long"] : null,
                bestProbability10: signalData10 ? signalData10["long_win_rate"] : null,
                detectedSignals10: signalData10 ? signalData10["number_long"] : null
              },
              short: {
                bestProbability2: signalData2["short_win_rate"],
                detectedSignals2: signalData2["number_short"],
                bestProbability5: signalData5 ? signalData5["short_win_rate"] : null,
                detectedSignals5: signalData5 ? signalData5["number_short"] : null,
                bestProbability10: signalData10 ? signalData10["short_win_rate"] : null,
                detectedSignals10: signalData10 ? signalData10["number_short"] : null
              }
            };
          }
        });
      } catch (error) {}
    });
    return backtestMap;
  };

  const fetchAndCombineData = async () => {
    try {
      const tradeSignalsRes = await fetch('/api/get-trade-signals');
      const tradeSignalsData = await tradeSignalsRes.json();
      const backtestRes = await fetch('/api/backtest');
      const backtestData = await backtestRes.json();
      const backtestMap = preprocessBacktestData(backtestData);
      const finalCombinedData = tradeSignalsData.map(signal => {
        const { symbol, timeframe, trade_signal, direction } = signal;
        const translatedSignal = signalToBacktestTranslator[`${trade_signal} ${direction}`];
        const backtestInfo = backtestMap[symbol]?.[timeframe]?.[translatedSignal]?.[direction.toLowerCase()] || {};
        return { ...signal, ...backtestInfo };
      });
      setFinalData(finalCombinedData);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage('Failed to fetch or process data. Please try again later.');
      setOpen(true);
    }
  };

  useEffect(() => {
    const applyFiltersAndSort = () => {
      let filteredData = finalData;
      if (selectedSignals.length > 0) { filteredData = filteredData.filter(signal => selectedSignals.includes(signal.trade_signal)); }
      if (field3) { filteredData = filteredData.filter(signal => signal.timeframe === field3); }
      if (filter) {
        const [probability, gain] = filter.split('-').map(item => item.trim());
        filteredData = filteredData.filter(item => {
          const probField = `bestProbability${gain}`;
          const numericProbability = parseFloat(item[probField]);
          return numericProbability >= parseInt(probability);
        });
      }
      if (sortBy) {
        filteredData = filteredData.sort((a, b) => {
          if (sortBy === 'timestamp') {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return sortOrder === 'asc' ? dateB - dateA : dateA - dateB;
          } else if (sortBy === 'profit_potential') {
            return sortOrder === 'asc' ? b.profit_potential - a.profit_potential : a.profit_potential - b.profit_potential;
          } else if (sortBy === 'symbol') {
            return sortOrder === 'asc' ? a.symbol.localeCompare(b.symbol) : b.symbol.localeCompare(a.symbol);
          }
          return 0;
        });
      }
      const uniqueData = removeDuplicates(filteredData);
      const limitedData = limitResultsForTier(uniqueData, hasAccess);
      setShowData(limitedData);
    };
    applyFiltersAndSort();
  }, [isLoading, selectedSignals, field3, sortBy, sortOrder, filter]);

  useEffect(() => {
    if (!authLoading && auth?.isAuthenticated !== undefined) { setHasAccess(auth.isAuthenticated); }
  }, [authLoading, auth]);

  useEffect(() => {
    if (!authLoading) { fetchAndCombineData(); }
  }, [authLoading, hasAccess]);

  const removeDuplicates = (data) => {
    const seen = new Set();
    return data.filter(item => {
      const identifier = `${item.symbol}-${item.trade_signal}-${item.timestamp}`;
      if (seen.has(identifier)) { return false; } else { seen.add(identifier); return true; }
    });
  };

  return (
    <>
      <div >
        <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
          <Typography className={newUiClass.componentTitle}>Trading Signals</Typography>
          <Typography className={newUiClass.componentSubtitle}><i>Get the latest and most accurate signals.</i></Typography>
        </div>
        <Grid container spacing={2} className={newUiClass.headerGrid} style={{marginBottom: '20px', marginTop: '10px'}}>
          <Grid md={12} xs={12}>
            {(!hasAccess) && <Alert className={newUiClass.infoBar} severity="warning">Results are limited. Please login or sign up to use this feature.</Alert>}
          </Grid>
          <SignalDashboard />
        </Grid>
        <div className={newUiClass.filterBox}>
          <Grid container spacing={2} className={newUiClass.filterGrid}>
            <Grid item xs={12} md={12}>
              <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
              <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                multiple
                options={[
                  "Advanced ADX+PSAR",
                  "Big Extender",
                  "Bollinger Bands",
                  "EMA Crossover",
                  "EMA Strategy",
                  "Ichimoku",
                  "Hyper Scalper",
                  "MACD Crossover",  
                  "Parabolic Trader",
                  "Power Scalper",
                  "RSI", 
                     "Stochastic Bollinger",
                      "Trend Signal Fusion",
                  "Tri Trendmaster"
                ]}
                value={selectedSignals}
                onChange={(event, newValue) => setSelectedSignals(newValue)}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      checked={selected}
                      style={{ marginRight: 8 }}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="" placeholder="Select signals" />
                )}
                sx={{
                  '& .MuiAutocomplete-tag': {
                    margin: '2px',
                    fontSize: '0.9em',
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    padding: '5px 10px',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    right: '5px',
                  },
                  '& .MuiAutocomplete-paper': {
                    fontSize: '0.9em',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectControl label="Timeframe" value={field3}
                onChange={handleField3Change}
                options={[{ value: "1W", label: "1W" }, { value: "1D", label: "1D" }, { value: "4H", label: "4H" }, { value: "1H", label: "1H" }, { value: "15M", label: "15M" }]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectControl label="Sorting" value={sortBy}
                onChange={handleSort}
                options={[{ value: 'timestamp', label: 'Latest Signal' },
                { value: 'profit_potential', label: 'Highest Potential' },
                { value: 'symbol', label: 'Alphabetical' }]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectControl label="Filter" value={filter}
                onChange={handleFilterChange}
                options={[
                  { value: '90-10', label: 'Min Prob. 90% - 10% Gains' },
                  { value: '90-5', label: 'Min Prob. 90% - 5% Gains' },
                  { value: '90-2', label: 'Min Prob. 90% - 2% Gains' },
                  { value: '80-10', label: 'Min Prob. 80% - 10% Gains' },
                  { value: '80-5', label: 'Min Prob. 80% - 5% Gains' },
                  { value: '80-2', label: 'Min Prob. 80% - 2% Gains' },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        {isLoading && 
          <div style={{maxWidth: '70%', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}>
            <LinearProgress />
          </div>
        }
        {(!isLoading && showData.length === 0) && <div style={{fontSize: '14px', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginTop: '50px', color:'#1a1a1a', textAlign: 'center'}}><i>There are no entries for your selection.</i></div> }
        {!isLoading && <SignalsTable signals={showData} email={email} />}
        <Grid>
          <Fab color="primary" aria-label="add" onClick={toggleDrawer}
            style={{ position: 'fixed', bottom: '70px', right: '20px', background: '#3558FF', color: 'rgba(255,255,255,0.9)', zIndex: 10000 }}
          >
            <NotificationsActiveIcon />
          </Fab>
          <Fade in={open1} timeout={800}>
            <div>
              <Paper style={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', width: '100%', zIndex: 9999, color: 'rgba(255,255,255,0.5)' }}>
                <SignalEmailAlert />
              </Paper>
            </div>
          </Fade>
        </Grid>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} message={errorMessage} />
      </div>
    </>
  );
}

export default Signals;
