import React, { useState, useEffect, useContext } from "react";
import { Grid, makeStyles, Fab, Fade, Paper,  CircularProgress, Typography } from "@material-ui/core";
import { SelectControl } from "../elements/SelectControl";
import sharedStyles from '../styles/sharedStyles';
import { addMultiplePrices } from "../functions/addPrices";
import { limitResultsForTier } from "../functions/subscriptionCheck";
import newUi from "../styles/newUi";
import Alert from '@mui/material/Alert';
import { BacktestDashboard } from "./dashboards/backtestDashboard";
import BacktestTable from "../elements/backtestTable";
import BacktestEmailAlert from "../functions/backtestEmailAlert";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { AuthContext } from '../AuthContext';




const useStyles = makeStyles((theme) => ({
  tabRoot: { backgroundColor: '#212C4D !important', margin: theme.spacing(1), maxHeight: '14px !important',
    paddingLeft: '15px !important', paddingRight: '15px !important', fontSize: '0.9em', color: 'white !important',
    borderRadius: '50px !important',
    '&.Mui-selected': {  backgroundColor: '#3558FF !important', },
  },
}));

function BacktestScreener() {
  const { auth, logout } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;
  const newUiClass = newUi();
  const classes = sharedStyles();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filters, setFilters] = useState({ date: "today", timeframe: "1H", });
  const [totalElements, setTotalElements] = useState(0);
  const [helper2, setHelper2] = useState(false);
  const [sortOrder, setSortOrder] = useState('Latest'); // Default sort order
  const [open1, setOpen1] = useState(false);
  const toggleDrawer = () => { setOpen1(!open1); };

  useEffect(() => { window.scrollTo(0, 0);}, []);


  useEffect(() => {
    setIsLoading(true);
    fetch('/api/backtestScreener')
      .then((res) => res.json())
      .then(async (fetchedData) => {
        setTotalElements(fetchedData.length); 
        const formattedData = fetchedData.map(item => ({
          ...item,
          detectedDate: new Date(item.detected_date)
        }));
        setData(formattedData);
        applyFilters(formattedData);
              })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage("Failed to load data.");
        setOpen(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const sortData = (data) => {
    switch (sortOrder) {
      case 'Latest':
        return data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      case 'Highest Success Rate':
        return data.sort((a, b) => b.backtest_value - a.backtest_value);
      case 'Most signals':
        return data.sort((a, b) => b.backtest_amount - a.backtest_amount);
      case 'Alphabetical':
        return data.sort((a, b) => a.symbol.localeCompare(b.symbol));
      default:
        return data;
    }
  };
  
  useEffect(() => {
    applyFilters(data);
  }, [filters, data, sortOrder]);

  const applyFilters = async (dataToFilter) => {
    setIsLoading(true); 
    setHelper2(false);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  
    let filtered = dataToFilter
      .filter(item => {
        const itemDate = new Date(item.detectedDate);
        const dateMatch = filters.date === 'today' ? itemDate >= today : itemDate >= yesterday && itemDate < today;
        const timeframeMatch = filters.timeframe === 'all' || item.timeframe === filters.timeframe;
        return dateMatch && timeframeMatch;
      });
  
    filtered = sortData(filtered); // Apply sorting
  
    const limitedData = limitResultsForTier(filtered, hasAccess);
    const dataWithPrices = await addMultiplePrices(limitedData);
    setFilteredData(dataWithPrices);
    setIsLoading(false);
  };
  

  const handleFilterChange = (filterName, filterValue) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: filterValue,
    }));
  };

  useEffect(() => { applyFilters(data); }, [filters, data]);

  return (
    <div>
      <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
        <Typography className={newUiClass.componentTitle}>Backtest Signals</Typography>
        <Typography className={newUiClass.componentSubtitle}><i>Discover signals with the highest backtest quality.</i></Typography>
      </div>      
      <Grid container spacing={2} className={newUiClass.headerGrid} style={{marginBottom: '20px', marginTop: '10px'}}>
        {(!hasAccess) && <Alert className={newUiClass.infoBar} severity="warning">Results are limited. Please login or sign up to use this feature.</Alert>}
        <BacktestDashboard />
        </Grid>
        <div className={newUiClass.filterBox}>
        <Grid container spacing={2} className={newUiClass.filterGrid}>
          <Grid item xs={12} md={12}>
            <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
            <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
          </Grid>
            <Grid item xs={12} md={4} style={{width: '100%', }}>
                <SelectControl label="Date"  value={filters.date}   onChange={(e) => handleFilterChange('date', e.target.value)}
                    options={[{ value: "today", label: "Today" }, {value: "yesterday", label: "Yesterday"}, {value: "days-2", label: "Today -2"}]} 
                />
            </Grid>
            <Grid item xs={12} md={4} style={{width: '100%',}}>
                <SelectControl label="Timeframe"  value={filters.timeframe}   onChange={(e) => handleFilterChange('timeframe', e.target.value)}
                    options={[{ value: "1D", label: "1D" }, {value: "1H", label: "1H"}, {value: "15M", label: "15M"}]} 
                />
            </Grid>
            <Grid item xs={12} md={4} style={{width: '100%',}}>
              <SelectControl label="Sort By" value={sortOrder}
                onChange={(e) => { 
                  setSortOrder(e.target.value);
                  applyFilters(data);   
                }}
                options={[
                  { value: "Latest", label: "Latest" },
                  { value: "Highest Success Rate", label: "Highest Success Rate" },
                  { value: "Most signals", label: "Most signals" },
                  { value: "Alphabetical", label: "Alphabetical" }
                ]}
              />
            </Grid>
          </Grid>
          </div>
          <BacktestTable signals={filteredData} backtest={data} />
          <Grid>
            <Fab color="primary"  aria-label="add"  className={classes.botButton} onClick={toggleDrawer}
              style={{ position: 'fixed', bottom: '70px', right: '20px', background: '#3558FF', color: 'rgba(255,255,255,0.9)', zIndex: 10000 }} 
            >
              <NotificationsActiveIcon />
            </Fab>
            <Fade in={open1} timeout={800}> 
              <div>
                <Paper className={newUiClass.backtestAlertPopup}>
                  <BacktestEmailAlert />
                </Paper>  
              </div>
            </Fade>
          </Grid>
        
      </div>
  );
}

export default BacktestScreener;
