export const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    navButtonsAlwaysVisible: false,
    autoPlay: true,
    interval: 5000,
    animation: 'slide',
    appendDots: dots => (
      <div
        style={{
          position: 'relative',
          bottom: '10px',
          left: '10px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ul style={{ margin: 0, padding: 0, display: 'flex', listStyleType: 'none' }}>{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: 'gray',
          margin: '0 5px'
        }}
      />
    )
  };