import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RsiBubbleChart, Ema200BubbleChart, LineChart } from '../elements/analysisCharts';
import newUi from '../styles/newUi';
import { Typography, Grid, Box, Alert, LinearProgress } from '@mui/material';
import { SelectControl } from '../elements/SelectControl';

const AnalysisChart = () => {
  const newUiClass = newUi();
  const [data, setData] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState('');

  useEffect(() => {  window.scrollTo(0, 0); }, []);  

  const handleChartChange = (event) => {
    setChartType(event.target.value);
    setData([]); // Clear existing data when changing chart type
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!chartType) return;

      setLoading(true);
      try {
        let response;
        const today = new Date();
        today.setDate(today.getDate()); // Subtract one day
        const yesterday = today.toISOString().split('T')[0];

        if (chartType === 'ema_200' || chartType === 'rsi') {
          response = await axios.get(`https://crypto-os.xyz/api/technical-indicators?indicators=${chartType}`);
          const filteredData = response.data.filter(item => item.date.split('T')[0] === yesterday);
          setData(filteredData);
  
        } else if (chartType === 'line') {
          response = await axios.get(`https://crypto-os.xyz/api/aggregated-indicators?indicators=rsi`);
          setData(response.data);
        }

        const priceResponse = await axios.get('https://crypto-os.xyz/api/cache');
        setCurrentPrices(priceResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [chartType]);


  return (
    <>
      <div>
        <div style={{ paddingLeft: '30px', paddingTop: '15px', marginBottom: '25px' }}>
          <Typography className={newUiClass.componentTitle}>Analysis Charts</Typography>
          <Typography className={newUiClass.componentSubtitle}><i>Discover the easiest way to crypto analysis.</i></Typography>
        </div>
        <div className={newUiClass.filterBox}>
          <Grid container spacing={2} className={newUiClass.filterGrid}>
            <Grid item xs={12} md={12}>
              <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
              <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectControl
                label="Chart Type"
                value={chartType}
                onChange={handleChartChange}
                options={[
                  { value: "rsi", label: "RSI Token Overview" },
                  { value: "line", label: "Overall RSI Development" },
                  { value: "ema_200", label: "Long-Term Trend" },
                  
                ]}
              />
            </Grid>
            </Grid>
            <Grid container spacing={2} className={newUiClass.filterGrid}>
            <Grid md={12} xs={12} >
            <Box className={newUiClass.featureHeaderMasterContainerLeft} style={{minHeight: '130px', display: 'block', padding: '30px'}}>
              {chartType === 'line' && data.length > 0 && 
                <>
                  <Alert className={newUiClass.infoBar} severity="info">The chart shows the average RSI of 200 crypto tokens. The RSI is calculated based on the 1D chart and shown for the 1st January 2023 up to today.</Alert>
                  <LineChart data={data} />
                </>
              }
              {chartType === 'ema_200' && data.length > 0 && 
                <>
                  <Alert className={newUiClass.infoBar} severity="info">The chart shows the long-term trend of 200 crypto tokens. Tokens in the green zone are in a long-term uptrend based on the Exponential Moving Average of the last 200 days (EMA200). The distance to the 0-line indicates the percentage difference between the current price and the EMA200.</Alert>
                  <Ema200BubbleChart data={data} currentPrices={currentPrices} />
                </>
              }
               {chartType === 'rsi' && data.length > 0 && !loading &&
                <>
                  <Alert className={newUiClass.infoBar} severity="info">The chart shows the current RSI of Crypto OS support tokens. The RSI is calculated based on the 1D chart and updated daily.</Alert>
                  <RsiBubbleChart data={data} />
                </>
              }
              {chartType === '' && 
                  <Alert className={newUiClass.infoBar} severity="info">Please select a chart type...</Alert>
              }
              {loading && 
                <div style={{maxWidth: '70%', marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}>
                  <LinearProgress />
                </div>
      }

        </Box></Grid>
          </Grid>
        </div>
        
      </div>
    </>
  );
};

export default AnalysisChart;
