import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import newUi from '../../styles/newUi';

const Community = () => {
  const [tokens, setTokens] = useState([]);
  const newUiClass = newUi();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    try {
      const response = await axios.get('/api/blog-tokens');
      setTokens(response.data);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  return (
    <Container>
      <Typography style={{ fontWeight: '800', marginBottom: '0px', marginTop: '20px' }} className={newUiClass.componentTitle}>
        Curated Trade Strategies
      </Typography>
      <Typography className={newUiClass.componentSubtitle}>
        <i>Discover detailed trade setups based on Crypto OS signals.</i>
        <br />
      </Typography>
      <Grid container spacing={3} style={{marginTop: '10px'}}>
        {tokens.map((token) => (
          <Grid item xs={12} sm={6} md={4} key={token.symbol}>
            <Link to={`/blog/${token.symbol}`} className={newUiClass.tokenLink}>
              <Box className={newUiClass.tokenCard}>
                <img src={token.logo_url} alt={token.symbol} className={newUiClass.tokenLogo} />
                <Typography variant="h6" className={newUiClass.tokenName}>{token.symbol}</Typography>
                <Typography variant="body2" className={newUiClass.tokenInfo}>
                  <b>Posts:</b> {token.entry_count}<br />
                  <b>Latest:</b> {new Date(token.latest_entry).toLocaleString()}
                </Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Community;
