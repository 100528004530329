import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { Grid, Button, Typography, Chip } from '@material-ui/core';
import Snackbar from '@mui/joy/Snackbar';
import Stack from '@mui/material/Stack';
import sharedStyles from '../styles/sharedStyles';
import { AntSwitch } from '../elements/antswitch';
import { AuthContext } from '../AuthContext';

const SignalEmailAlert = () => {
  const [selected, setSelected] = useState({});
  const { auth } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;
  const [displayData, setDisplayData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = sharedStyles();
  const [savedTokensData, setSavedTokensData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState();

  const handleChipClick = (token) => {
    setSelected((prevState) => ({
      ...prevState,
      [token.symbol]: !prevState[token.symbol],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/api/cryptologos');
        const data = await response.json();
        setDisplayData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const prepareDataToSend = (email) => {
    let timeframe = '';
    if (isChecked) {
      timeframe = '1D';
    } else {
      timeframe = '1H';
    }
    const selectedTokens = Object.keys(selected).filter((key) => selected[key]);
    return {
      email,
      selectedTokens: JSON.stringify(selectedTokens),
      timeframe,
    };
  };

  const handleSaveAlerts = async (email) => {
    if (!email) {
      setErrorMessage("You need to be logged in to save alerts!");
      setOpen(true);
      setType('warning');
      return;
    }

    const dataToSend = prepareDataToSend(email);
    try {
      const response = await fetch('/api/save-signal-email-alert', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });

      if (response.status === 200) {
        const data = await response.json();
        setErrorMessage("Email alerts saved successfully");
        setOpen(true);
        setType('success');
        fetchAllSavedTokens();
      } else {
        setErrorMessage("Couldn't save your alerts. Please try again later or contact support.");
        setOpen(true);
        setType('warning');
      }
    } catch (error) {
      setErrorMessage("Couldn't save your alerts. Please try again later or contact support.");
      setOpen(true);
      setType('warning');
    }
  };

  const fetchAllSavedTokens = async () => {
    try {
      const response = await fetch('/api/get-signal-email-alert', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.data && Array.isArray(data.data)) {
          const parsedAlerts = data.data.map((alert) => ({
            timeframe: alert.timeframe,
            tokens: JSON.parse(alert.tokens),
          }));
          setSavedTokensData(parsedAlerts);
        }
      } else {
        console.error("Couldn't load saved tokens.");
      }
    } catch (error) {
      console.error("There was a problem fetching the saved tokens", error);
    }
  };

  useEffect(() => {
    if (email === '') {
      return;
    }
    fetchAllSavedTokens();
  }, [email]);

  useEffect(() => {
    updateSelectedTokens("1H");
  }, [savedTokensData]);

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
    const newTimeframe = event.target.checked ? "1D" : "1H";
    updateSelectedTokens(newTimeframe);
  };

  const updateSelectedTokens = (timeframeToCheck) => {
    const matchingTimeframeData = savedTokensData.find((entry) => entry.timeframe === timeframeToCheck);
    let savedTokens = {};

    if (matchingTimeframeData) {
      matchingTimeframeData.tokens.forEach((tokenSymbol) => {
        savedTokens[tokenSymbol] = true;
      });
    }
    setSelected(savedTokens);
  };

  const handleCheckAll = () => {
    const allTokens = displayData.reduce((acc, token) => {
      acc[token.symbol] = true;
      return acc;
    }, {});
    setSelected(allTokens);
  };

  const handleUncheckAll = () => {
    setSelected({});
  };

  return (
    <div style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', width: '100%' }}>
      <Grid container spacing={2} style={{ marginTop: '30px' }}>
        <Grid item xs={12} md={12}>
          <div className={classes.mobileHeader} style={{ color: '#1a1a1a' }}>Automated Email Alerts</div>
          <div className={classes.mobileSubHeader} style={{ marginBottom: '10px', color: '#1a1a1a' }}>
            Select tokens to receive an email notification whenever a trading signal gets detected.
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
      
        </Grid>
      </Grid>
      {!isLoading && (
        <>
          <Grid container spacing={3} style={{ marginBottom: '10px' }}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography style={{ color: '#1a1a1a' }}>Hourly Alerts</Typography>
                  <AntSwitch
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    defaultChecked
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                  <Typography style={{ color: '#1a1a1a' }}>Daily Alerts</Typography>
                </Stack>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                  <Button onClick={handleCheckAll} style={{ color: '#1a1a1a', background: '#eee', marginRight: '10px' }}>Check All</Button>
                  <Button onClick={handleUncheckAll} style={{ color: '#1a1a1a', background: '#eee' }}>Uncheck All</Button>
              </div>
            </Grid>
          </Grid>
          <div style={{ maxWidth: '100%', maxHeight: '300px', marginTop: '20px', overflowX: 'scroll', marginLeft: '-5%', maxWidth: '110%', marginRight: '-5%' }}>
            <div style={{ maxWidth: '100%' }}>
              <Box display="flex" flexWrap="wrap" p={1} justifyContent="center" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                {displayData.map((token, index) => {
                  const isTokenHighlighted = selected[token.symbol];
                  return (
                    <Box key={index} p={1}>
                      <Chip
                        label={token.symbol}
                        onClick={() => handleChipClick(token)}
                        style={{ backgroundColor: isTokenHighlighted ? '#3558FF' : '#333', color: 'white', borderRadius: '5px', border: 'none' }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </div>
          </div>
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Button fullWidth className={classes.standardButton} style={{ height: '50px', backgroundColor: '#3558FF' }} onClick={() => handleSaveAlerts(email)}>
              Save
            </Button>
          </div>
          <Snackbar
            variant="soft"
            color={type}
            open={open}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            endDecorator={
              <Button onClick={() => setOpen(false)} size="sm" style={{ color: '#1a1a1a' }}>
                Dismiss
              </Button>
            }
          >
            {errorMessage}
          </Snackbar>
        </>
      )}
    </div>
  );
};
export default SignalEmailAlert;
