import React, { useState, useEffect } from 'react';
import { Link, Grid, Paper, Chip, Box, Typography, IconButton, LinearProgress, Tooltip } from '@mui/material';
import newUi from '../styles/newUi';
import InfoIcon from '@mui/icons-material/Info';
import mainStyles from '../styles/mainstyles';
import { customFormat, formatTimestampUTC } from '../functions/customFormat';
import { useTheme } from '@material-ui/core/styles';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { SelectControl } from './SelectControl';


function ForecastTable(props) {
  const { rows, indicator } = props;
  const [expandedRow, setExpandedRow] = useState({});
  const [sortedRows, setSortedRows] = useState(rows);
  const [alphabeticalSort, setAlphabeticalSort] = useState('alphabeticalAsc');
  const [sortType7D, setSortType7D] = useState('');
  const [sortType30D, setSortType30D] = useState('');
  const newUiClass = newUi();
  const classes2 = mainStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  

  const sortFunctions = {
    highestAccuracy7D: (a, b) => a.mape_7 - b.mape_7,
    highestAccuracy30D: (a, b) => a.mape_30 - b.mape_30,
    highestIncrease7D: (a, b) => b.sevenDayPercentageDiff - a.sevenDayPercentageDiff,
    highestIncrease30D: (a, b) => b.thirtyDayPercetageDiff - a.thirtyDayPercetageDiff,
    lowestIncrease7D: (a, b) => a.sevenDayPercentageDiff - b.sevenDayPercentageDiff,
    lowestIncrease30D: (a, b) => a.thirtyDayPercetageDiff - b.thirtyDayPercetageDiff,
    alphabeticalAsc: (a, b) => a.symbol.localeCompare(b.symbol),
    alphabeticalDesc: (a, b) => b.symbol.localeCompare(a.symbol)
  };
  const resetSorts = (rows) => {
    // Do not reset states here
    return [...rows];  // Simply return a copy of rows without sorting them by default
};

const handleSortChangeAlphabetical = (event) => {
    const newSortValue = event.target.value;
    // Reset other sorts first
    setSortType7D('');
    setSortType30D('');
    setAlphabeticalSort(newSortValue);  // Then set the new sort state
    const newSortedRows = resetSorts(rows).sort(sortFunctions[newSortValue]);
    setSortedRows(newSortedRows);
};

const handleSortChange7D = (event) => {
    const newSortValue = event.target.value;
    // Reset other sorts first
    setSortType30D('');
    setAlphabeticalSort('');
    setSortType7D(newSortValue);  // Then set the new sort state
    const newSortedRows = resetSorts(rows).sort(sortFunctions[newSortValue]);
    setSortedRows(newSortedRows);
};

const handleSortChange30D = (event) => {
    const newSortValue = event.target.value;
    // Reset other sorts first
    setSortType7D('');
    setAlphabeticalSort('');
    setSortType30D(newSortValue);  // Then set the new sort state
    const newSortedRows = resetSorts(rows).sort(sortFunctions[newSortValue]);
    setSortedRows(newSortedRows);
};


  function getProgress(mape) {
    if (mape == null) { return 0; }
    if (mape < 2) { return 95; } 
    else if (mape < 4) { return 80; } 
    else if (mape < 6) { return 60; }
    else if (mape < 8) { return 40; } 
    else if (mape < 100) { return 20; } 
    else { return 0; }
    }

    function getTooltipValue(mape) {
      if (mape == null) { return "No data available"; }
      if (mape < 2) { return "Excellent Accuracy"; } 
      else if (mape < 4) { return "Good Accuracy"; } 
      else if (mape < 6) { return "Average Accuracy"; }
      else if (mape < 8) { return "Poor Accuracy"; } 
      else if (mape < 100) { return "Very Poor Accuracy"; } 
      else { return "n.a."; }
      }

  function interpolateColor(value, max, min, color1, color2) {
      const ratio = Math.max(Math.min((value - min) / (max - min), 1), 0);
      const hex = function(x) {
          x = x.toString(16);
          return (x.length === 1) ? '0' + x : x;
      };
  
      let r = Math.ceil(parseInt(color2.substring(1, 3), 16) * ratio + parseInt(color1.substring(1, 3), 16) * (1 - ratio));
      let g = Math.ceil(parseInt(color2.substring(3, 5), 16) * ratio + parseInt(color1.substring(3, 5), 16) * (1 - ratio));
      let b = Math.ceil(parseInt(color2.substring(5, 7), 16) * ratio + parseInt(color1.substring(5, 7), 16) * (1 - ratio));
  
      return `#${hex(r)}${hex(g)}${hex(b)}`;
  }
  

  useEffect(() => {
    const sorted = [...rows].sort(sortFunctions['alphabeticalAsc']);
    setSortedRows(sorted);
  }, [rows]);

  function MapeProgressBar({ mape }) {
    const progress = getProgress(mape);
    const color = getMapeColor(mape);
    const tooltipValue = getTooltipValue(mape);

    return (
        <Tooltip title={tooltipValue}>
        <LinearProgress
            style={{ width: '100%' }}
            variant="determinate"
            value={progress}
            sx={{ '& .MuiLinearProgress-barColorPrimary': { backgroundColor: color } }}
        />
        </Tooltip>
    );
}

    function getMapeColor(mape) {
      const minMape = 2;   // Best performance boundary
      const maxMape = 100; // Worst performance boundary
      const colorGood = '#006400'; // Dark green
      const colorPoor = '#8B0000'; // Dark red
  
      return interpolateColor(mape, maxMape, minMape, colorGood, colorPoor);
  }

    function highAccuracyChip(mape, days) {
      if (mape < 4) {
        if (days == 7) {
          return(
            <Chip style={{backgroundColor: 'darkgreen'}} className={newUiClass.standardChip1} label="HIGH ACCURACY FCT 7D" />
          )
        }
        else if (days == 30) {
          return(
          <Chip style={{backgroundColor: 'darkgreen'}} className={newUiClass.standardChip1} label="HIGH ACCURACY FCT 30D" />
          )
        }
        }
      }

    function getArrow(value) {
      if (value > 0) { return ( <TrendingUpIcon style={{color: 'darkgreen'}} /> ) }
      else if (value < 0) { <TrendingDownIcon style={{color: 'darkred'}} /> }
    }
    
  return (
    <>
    <div style={{paddingLeft: '15px', marginBottom: '25px', marginRight: '10px'}}>
        <Grid container spacing={2} className={newUiClass.filterGrid} style={{paddingLeft: '5px'}}>
          <Grid item xs={12} md={12}>
            <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
            <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectControl label="7D" value={sortType7D}
              onChange={handleSortChange7D}
              options={[{ value: "", label: "None" }, {value: "highestAccuracy7D", label: "Highest Accuracy (7D)"}, {value: "highestIncrease7D", label: "Highest Increase (7D)"}, {value: "lowestIncrease7D", label: "Lowest Increase (7D)"}]} newUiClass={newUiClass}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectControl  label="30D"  value={sortType30D}  
          onChange={handleSortChange30D}
          options={[{ value: "", label: "None" }, {value: "highestAccuracy30D", label: "Highest Accuracy (30D)"}, {value: "highestIncrease30D", label: "Highest Increase (30D)"}, {value: "lowestIncrease30D", label: "Lowest Increase (30D)"}]} newUiClass={newUiClass}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectControl  label="Alphabetical"  value={alphabeticalSort}  
              onChange={handleSortChangeAlphabetical}
              options={[{ value: "", label: "None" }, {value: "alphabeticalAsc", label: "Alphabetical (A-Z)"}, {value: "alphabeticalDesc", label: "Alphabetical (Z-A)"}]} newUiClass={newUiClass}
            />
          </Grid>
        </Grid>
      </div>
    <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
      {(indicator == 'ai') &&
      sortedRows.map((row, index) => {
        const isFirstInRow = index % 3 === 0;
        const isThirdInRow = index % 3 === 2;
        return (
          <Grid item xs={12} md={4} key={row.symbol} style={{
            paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px', 
            paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
            alignItems: 'stretch'  
          }}>
            <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
              setExpandedRow(prevState => ({
              ...prevState,
              [row.symbol]: !prevState[row.symbol]
              }));
            }}>
              <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexShrink: 0 }}>
                      <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                    </div>
                    <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                      <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                        <b>{row.symbol}</b><br />
                        <span style={{ color:'#1a1a1a', fontSize:'0.8em' }}>
                          {row.fullName}
                        </span>
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right", width: '100%' }}>
                      <Typography style={{ color: '#333', fontSize: '2em', fontWeight: '800' }}>
                      {getArrow(row.sevenDayPercentageDiff)}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                     {highAccuracyChip(row.mape_7, 7)}
                     {highAccuracyChip(row.mape_30, 30)}

                    </Box>
                  </div>
                  {expandedRow[row.symbol] && (
                    <>
                    <div style={{marginTop: '25px'}}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Current Price</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                        <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.latest_price)}</span>
                      </Typography>
                      </div>
                      <div style={{ marginTop: '5px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>24H Forecast</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
                      <span style={{ paddingRight: '5px', fontWeight: '600' }}>{row['24_hour_forecast']}</span>
                      </Typography>
                      </div>
                      <div style={{ marginTop: '5px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>7 Days Forecast</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(row.sevenDayPercentageDiff) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
                        <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.seven_day_forecast)}</span>({customFormat(row.sevenDayPercentageDiff)}%)
                      </Typography>
                      <Typography style={{fontSize: '0.7em', color: '#333', marginBottom: '10px'}}><i>7 Days Forecast Accuracy</i></Typography>
                      <Tooltip titel={row.mape_7}>
                        <MapeProgressBar mape={row.mape_7} />
                      </Tooltip>
                      </div>
                      <div style={{ marginTop: '25px', marginBottom: '35px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>30 Days Forecast</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(row.thirtyDayPercentageDiff) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
                        <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.thirty_day_forecast)}</span>({customFormat(row.thirtyDayPercentageDiff)}%)
                      </Typography>
                      <Typography style={{fontSize: '0.7em', color: '#333', marginBottom: '10px'}}><i>30 Days Forecast Accuracy</i></Typography>
                      <Tooltip titel={row.mape_30}>
                        <MapeProgressBar mape={row.mape_30} />
                      </Tooltip>
                      </div>
                      <MinimizedTokenInfo token={row.symbol} />
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                          Last Updated: {formatTimestampUTC(row.timestamp)} UTC
                        </Typography>
                        <div>
                          <Link to={`/tokens/${row.symbol}`} style={{ textDecoration: 'none' }}>
                            <IconButton style={{ boxShadow: '#ebebeb 1px 1px 1px 1px' }} aria-label="account-balance">
                              <InfoIcon style={{ color: '#333' }} />
                            </IconButton>
                          </Link>
                         </div>
                      </div>
                    </>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        </>
      
    );
    
}  

export default ForecastTable;