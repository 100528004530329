import React, { useState, useEffect, useContext } from 'react';
import {Grid, Snackbar, Typography } from "@material-ui/core";
import { limitResultsForTier } from "../functions/subscriptionCheck";
import PatternForecastTable from '../elements/patternForecastTable';
import Alert from '@mui/material/Alert';
import newUi from '../styles/newUi';
import { AuthContext } from '../AuthContext';



function PatternForecast ()  {
  const newUiClass = newUi();
  const { auth, logout } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [extraMessage, setExtraMessage] = useState();

  useEffect(() => { window.scrollTo(0, 0); }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpen(false);
  };
  
  useEffect(() => {
    setIsLoading(true);  
    fetch('/api/forecast-extended')
      .then(response => response.json())
      .then(data => {
        const filteredData = data.filter(item => 
          item.seven_day_forecast >= 0 && 
          item.thirty_day_forecast >= 0 && 
          item.latest_price !== null && 
          item.latest_price !== undefined && 
          item.latest_price !== ''
        );
        
        const limitedData = limitResultsForTier(filteredData, hasAccess);
        if(!hasAccess) { 
            setErrorMessage("Upgrade to discover forecasts of more than 200 tokens! It's free!");
            setOpen(true);}

        const enrichedData = limitedData.map(item => {
          const sevenDayDiff = ((item.seven_day_forecast - item.latest_price) / item.latest_price) * 100;
          const thirtyDayDiff = ((item.thirty_day_forecast - item.latest_price) / item.latest_price) * 100;
          return {
            ...item,
            sevenDayPercentageDiff: sevenDayDiff,
            thirtyDayPercentageDiff: thirtyDayDiff
          };
        });        
        setData(enrichedData);
        setDisplayData(enrichedData);
        setDataReady(true);  
        if (!hasAccess) {
          setExtraMessage('Upgrade to Pro and discover more than 200 cryptocurrencies.');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
      setDataReady(true);  
  }, []);

  useEffect(() => {
    if (dataReady) { setTimeout(() => { setIsLoading(false); }, 5000); }
  }, [dataReady]);
  
 if (data.length == 1) {return};
  
  return (
    <>
      <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
        <Typography className={newUiClass.componentTitle}>Deep AI Forecast</Typography>
        <Typography className={newUiClass.componentSubtitle}><i>Discover the latest deep learning AI forecasts.</i></Typography>
      </div><Grid container spacing={2} className={newUiClass.headerGrid} style={{marginBottom: '20px', marginTop: '10px'}}>
        <Grid md={12} xs={12}>
          {(!hasAccess) && <Alert className={newUiClass.infoBar} severity="warning">Results are limited. Please login or sign up to use this feature.</Alert>}
        </Grid>
        
      </Grid>
      <PatternForecastTable rows={data} indicator="ai"/>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}  message={errorMessage}/>
    </>
  );
};

export default PatternForecast;
