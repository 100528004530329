import React from "react";
import { Card, CardContent, Grid, Avatar, Typography, CardMedia } from "@material-ui/core";

export const BlogMessage = ({ message, handleOpenImageDialog }) => {
    return (
        <Card style={{ marginBottom: '15px', borderRadius: '10px', boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)', backgroundColor: 'white', color: 'black', fontSize: '0.8em' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Avatar>{message.username.charAt(0).toUpperCase()}</Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body2" color="textSecondary" component="p">
                            @{message.username} · {new Date(message.created_at).toLocaleString()}
                        </Typography>
                        <Typography style={{ fontSize: '1em' }} variant="body1" component="div" dangerouslySetInnerHTML={{ __html: message.content }} />
                        {message.attachment_file_path && (
                            <CardMedia
                                component="img"
                                alt="Attachment"
                                image={message.attachment_file_path}
                                style={{ marginTop: '10px', height: 'auto', borderRadius: '5px', cursor: 'pointer' }}
                                onClick={() => handleOpenImageDialog(message.attachment_file_path)}
                            />
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BlogMessage;
