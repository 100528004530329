import React from 'react';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement, 
    Title, 
    Tooltip, 
    Legend, 
    Filler,
    TimeScale // Import TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

// Register the chart components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TimeScale // Register TimeScale
);

const LineChart = ({ data }) => {
  // Sort data by timestamp
  const sortedData = data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          parser: 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'', // Explicitly specify the parser format
          tooltipFormat: 'MM-dd'
        },
        grid: {
          display: false  // This will hide the grid lines for the x-axis
        },
        title: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5 // Adjust this value as needed to reduce the number of ticks
        }
      },
      y: {
        beginAtZero: false, 
        grid: {
          display: false  // This will hide the grid lines for the y-axis
        },
        title: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5 // Adjust this value as needed to reduce the number of ticks
        }
      }
    },
    elements: {
      point: {
        radius: 0 // Hides the points on the line
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false
      },
      datalabels: {
        display: false // This will hide the data labels
      }
    },
    maintainAspectRatio: false
  };

  // Update the dataset with new color
  const chartData = {
    datasets: [
      {
        label: 'Closing Price',
        data: sortedData.map(entry => ({
          x: entry.timestamp,
          y: parseFloat(entry.close)
        })),
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          return getGradient(ctx, chartArea);
        },
        borderColor: 'rgb(0, 123, 255)',
        borderWidth: 2,
        tension: 0.1
      }
    ]
  };

  const chartRef = React.useRef(null);

  const getGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'rgba(0, 123, 255, 0.2)'); // same color as the fill, but more solid
    gradient.addColorStop(1, 'rgba(0, 123, 255, 0)'); // transparent color at the top

    return gradient;
  };

  return (
    <Line
      data={chartData}
      options={options}
      ref={chartRef}
    />
  );
};

export default LineChart;

