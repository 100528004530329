import React, { useState } from 'react';
import { Grid, Box, Chip, Typography, Link, Paper, IconButton } from '@mui/material';
import newUi from '../styles/newUi';
import mainStyles from '../styles/mainstyles';
import { customFormat } from '../functions/customFormat';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


function ScreenerTable({data}) {
  const classes2 = mainStyles();
  const newUiClass = newUi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({});



  return (
    <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
      
    {data.map((row, index) => {
      
      const isFirstInRow = index % 3 === 0;
      const isThirdInRow = index % 3 === 2;
      return (
        <>
        <Grid item xs={12} md={4} key={`${row.alert_time}-${row.symbol}`} style={{
          paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px', 
          paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
          alignItems: 'stretch'  
        }}>
          <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
            setExpandedRows(prevState => ({
            ...prevState,
            [row.symbol]: !prevState[row.symbol]
            }));
          }}>
            <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ flexShrink: 0 }}>
                    <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                  </div>
                  <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                    <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                      <b>{row.symbol}</b><br />
                      <span style={{ color:'#1a1a1a', fontSize:'0.8em' }}>
                        {row.fullName}
                      </span>
                    </Typography>
                  </div>
                </div>
            
                {expandedRows[row.symbol] && (
                  <>
                  <div style={{marginTop: '25px'}}>
                    <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Current Price (24h Change)</i></Typography>
                    <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(row.pctChange) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
                      <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.price)}</span>({customFormat(row.pctChange)}%)
                    </Typography>
                    </div>
                   
                    <div style={{ marginTop: '25px' }}>
                      <MinimizedTokenInfo token={row.symbol} />
                     
                    </div>
                  </>

                )}
              </Paper>
            </Grid>
          </>
          );   
        })}
      </Grid>
      

  );
}

export default ScreenerTable;
