import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box, Tooltip, LinearProgress, Chip, IconButton, Link } from '@mui/material';
import newUi from '../styles/newUi';
import { formatTimestampUTCOnlyHoursAndMinutes, formatTimestamp } from '../functions/customFormat';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import mainStyles from '../styles/mainstyles';
import { customFormat } from '../functions/customFormat';
import ManualOrder from "../forms/bots/manualOrder";
import BotOrder from "../forms/bots/botOrder";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShareIcon from '@mui/icons-material/Share';
import { copyToClipboard } from '../functions/shareLinks';
import { shareOnX } from '../functions/shareLinks';
import XIcon from '@mui/icons-material/X';
import LikeButton from '../functions/likeButton';

export function SignalsTable({ signals, email }) {
  const classes2 = mainStyles();
  const newUiClass = newUi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({}); 
  const [tradeDialogOpen, setTradeDialogOpen] = useState(false);
  const [botDialogOpen, setBotDialogOpen] = useState(false);
  const [tradeSymbol, setTradeSymbol] = useState('');
  const [tradeFullName, setTradeFullName] = useState('');
  const [tradePrice, setTradePrice] = useState('');
  const [tradeDirection, setTradeDirection] = useState('');
  const [botSignal, setBotSignal] = useState('');
  const [botTimeframe, setBotTimeframe] = useState('');
  const [selectedRow, setSelectedRow] = useState(null); // To hold the selected row data
  const [selectedBotRow, setSelectedBotRow] = useState(null); // To hold the selected row data for bot
  const [items, setItems] = useState([]);  // To hold the items to be displayed
  const [hasMore, setHasMore] = useState(true);  // To control loading state
  const itemsPerPage = 20;  // Number of items to load per page
 
  useEffect(() => { setItems(signals.slice(0, itemsPerPage)); }, [signals]);
  const fetchMoreData = () => {
    if (items.length >= signals.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => { setItems(prevItems => prevItems.concat(signals.slice(prevItems.length, prevItems.length + itemsPerPage))); }, 1500);
  };

  const handleTradeDialogToggle = (row, event) => {
    if (event) {
      event.stopPropagation();
    }
    if (selectedRow && selectedRow.symbol === row.symbol && tradeDialogOpen) {
      setTradeDialogOpen(false);
      setSelectedRow(null);
    } else {
      setTradeDirection(row.direction);
      setTradeSymbol(row.symbol);
      setTradeFullName(row.fullName);
      setTradePrice(row.price);
      setSelectedRow(row);
      setTradeDialogOpen(true);
    }
  };

  const handleBotDialogToggle = (row, event) => {
    if (event) {
      event.stopPropagation();
    }
    if (selectedBotRow && selectedBotRow.symbol === row.symbol && botDialogOpen) {
      setBotDialogOpen(false);
      setSelectedBotRow(null);
    } else {
      setTradeDirection(row.direction);
      const complete = `${row.trade_signal} ${row.direction}`;
      setBotSignal(complete);
      setTradeSymbol(row.symbol);
      setTradeFullName(row.fullName);
      setTradePrice(row.price);
      setBotTimeframe(row.timeframe);
      setSelectedBotRow(row);
      setBotDialogOpen(true);
    }
  };

  const closeBotDialog = () => { setBotDialogOpen(false); };

  function probabilityChip(probability) {
    const numericProbability = parseFloat(probability);
    if (numericProbability >= 90) {
        return { label: "HIGHEST PROBABILITY", color: "darkgreen" };
    } else if (numericProbability >= 80) {
        return { label: "HIGH PROBABILITY", color: "green" };
    } else if (numericProbability >= 70) {
        return { label: "MODERATE PROBABILITY", color: "gold" };
    } else if (numericProbability >= 50) {
        return { label: "LOW PROBABILITY", color: "orange" };
    } else if (numericProbability < 50) {
        return { label: "POOR PROBABILITY", color: "red" };
    }
    else {
      return { label: 'NO DATA', color: 'grey'}
    }
  }

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      endMessage={<p style={{ textAlign: 'center' }}><b>Yay! You have seen it all</b></p>}
    >
      <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
        {items.map((row, index) => {
          const isFirstInRow = index % 3 === 0;
          const isThirdInRow = index % 3 === 2;
          const probability = row.timeframe === '1D' ? row.bestProbability5 : row.bestProbability2;
          const { label, color } = probabilityChip(probability);
          let percentageFloat = 0;  
          if (row.bestProbability2) { 
           const percentageStr = row.bestProbability2.replace('%', '');
          percentageFloat = parseFloat(percentageStr);
          }
          let percentageAi = 0
          let percetageAiText = ''
          if(row.ai_backtest_probability) {
            percentageAi = row.ai_backtest_probability * 100;
            percetageAiText = customFormat(percentageAi) + "%"
          }
          const rowId = `${row.symbol}-${index}`;
          return (
            <React.Fragment key={rowId}>
              <Grid item xs={12} md={4} style={{
                paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px', 
                paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
                alignItems: 'stretch'  
              }}>
                <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
                  setExpandedRows(prevState => ({
                    ...prevState,
                    [rowId]: !prevState[rowId]
                  }));
                }}>
                  <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexShrink: 0 }}>
                      <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                    </div>
                    <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                      <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                        <b>{row.symbol}</b><br />
                        <span style={{ color:'#AAAAAA', fontSize:'0.8em' }}>
                          {row.fullName}
                        </span>
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right", width: '100%' }}>
                      <Typography style={{ color: '#333', fontSize: '2em', fontWeight: '800' }}>
                        {row.profit_potential}%
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                      <Chip className={newUiClass.standardChip} style={{background: '#F5F7FE'}} label={row.trade_signal} />
                      <Chip className={newUiClass.standardChip} style={{ background: '#F5F7FE' }} label={row.timeframe} />
                      <Chip className={newUiClass.standardChipColorless} style={{ background: row.direction === "Short" ? "darkred" : "darkgreen" }} label={row.direction}/>
                      <Chip className={newUiClass.standardChipColorless} style={{ background: color }} label={label} />
                   



                    </Box>
                  </div>
                  {expandedRows[rowId] && (
                    <>
                    <div style={{marginTop: '25px'}}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Click To Trade The Signal On Your Preferred Exchange</i></Typography>
                        <Link href="https://accounts.binance.com/register?ref=WU0WXFA0" target="_blank" style={{ textDecoration: 'none' }}>
                          <Chip label="Binance"  className={newUiClass.standardChipColorless1} style={{background: '#3558FF', marginLeft: '0px !important'}}/>  
                        </Link>
                        <Link href="https://partner.blofin.com/d/CryptoOS" target="_blank" style={{ textDecoration: 'none' }}>
                          <Chip label="BloFin"  className={newUiClass.standardChipColorless} style={{background: '#3558FF'}} />  
                        </Link>
                        <Link href="https://www.mexc.com/register?inviteCode=mexc-cryptoknow" target="_blank" style={{ textDecoration: 'none' }}>
                          <Chip label="MEXC"   className={newUiClass.standardChipColorless} style={{background: '#3558FF'}}/>
                        </Link>
                        
                      </div>
                      <div style={{marginTop: '25px'}}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Detected On</i></Typography>
                        <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', color: '#1a1a1a' }}>
                          {formatTimestamp(row.timestamp)} UTC
                        </Typography>
                      </div>
                      <div style={{marginTop: '15px'}}>
                        <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Current Price (24h Change)</i></Typography>
                        <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(row['24h_change']) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
                          <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.latest_price)}</span>({customFormat(row['24h_change'])}%)
                        </Typography>
                      </div>
                    




                      <div style={{ marginTop: '15px' }}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Historical Win Rate (2% Gains)</i></Typography>
                        <Tooltip title={row.bestProbability2}>
                          <LinearProgress style={{ width: '100%' }}  variant="determinate" value={percentageFloat} />
                        </Tooltip>
                      </div>
                      <div style={{ marginTop: '25px' }}>
                        <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Backtest Details</i></Typography>
                        <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', color: '#1A1A1A' }}>
                          The signal has a historical probability of <b>{row.bestProbability2} to achieve 2% gains, {row.bestProbability5} to achieve 5% gains, and {row.bestProbability10} to achieve 10% gains during the following 30 candles.</b> The backtest results are based on {row.detectedSignals2} historical signals on the {row.timeframe} chart. 
                        </Typography>
                        <MinimizedTokenInfo token={row.symbol} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                          <LikeButton userEmail={email} contentId={row.id} type="trade_signal" content={row}/>
                          </Typography>
                          <div>
                    <Tooltip title="Copy Shareable Link">
                  <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={() => copyToClipboard(row.unique_link)}>
                      <ShareIcon style={{ color: '#333' }} />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Share On X">
                  <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={() => shareOnX("Check out the latest Crypto OS Trade Signal", (row.unique_link) )}>
                      <XIcon style={{ color: '#333' }} />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Bot Setup">
                    <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={(event) => handleBotDialogToggle(row, event)}>
                      <AutoModeIcon style={{ color: '#333' }} />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Trade Now">
                    <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={(event) => handleTradeDialogToggle(row, event)}>
                      <CompareArrowsIcon style={{ color: '#333' }} />
                    </IconButton>
                    </Tooltip>

                  </div>
                        </div>
                      </div>
                    </>
                  )}
                </Paper>
              </Grid>
              {tradeDialogOpen && selectedRow?.symbol === row.symbol && (
                <Grid item xs={12} md={4} key={`trade-dialog-${row.symbol}-${index}`} style={{ paddingLeft: '0px', paddingRight: '0px', alignItems: 'stretch' }}>
                  <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto', padding: '20px', borderRadius: '8px', backgroundColor: 'white' }}>
                    <ManualOrder
                      onAccountBalanceClick={handleTradeDialogToggle}
                      fullName={tradeFullName}
                      direction={tradeDirection}
                      latestPrice={tradePrice}
                      symbol={tradeSymbol}
                    />
                  </Paper>
                </Grid>
              )}
              {botDialogOpen && selectedBotRow?.symbol === row.symbol && (
                <Grid item xs={12} md={4} key={`bot-dialog-${row.symbol}-${index}`} style={{ paddingLeft: '0px', paddingRight: '0px', alignItems: 'stretch' }}>
                  <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto', padding: '20px', borderRadius: '8px', backgroundColor: 'white' }}>
                    <BotOrder
                      onBotClick={handleBotDialogToggle}
                      closeBotClick={closeBotDialog}
                      fullName={tradeFullName}
                      direction={tradeDirection}
                      latestPrice={tradePrice}
                      symbol={tradeSymbol}
                      signal={botSignal}
                      directionTrade={tradeDirection}
                      timeframe={botTimeframe}
                    />
                  </Paper>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </InfiniteScroll>
  );
}

export default SignalsTable;



/*



   {row.ai_backtest_probability > 0.8 && (
                  <Chip className={newUiClass.standardChipColorless} style={{ background: "darkgreen" }} label="AI RECOMMENDED" />
                )}



  {row.ai_backtest_probability &&
                      <div style={{ marginTop: '15px', marginBottom: '30px' }}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>AI Probability</i></Typography>
                        <Tooltip title={percetageAiText}>
                          <LinearProgress style={{ width: '100%' }}  variant="determinate" value={percentageAi} />
                        </Tooltip>
                      </div>
                      }

                */