import React from 'react';
import { Grid, Paper, Chip } from '@mui/material';
import newUi from '../styles/newUi';
import { longColor, shortColor } from '../data/basics';
import { customFormat, formatTimestamp } from '../functions/customFormat';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';


export function CmeTable({ signals }) {
    const classes2 = newUi();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const paperStyles = {
      backgroundColor: '#101935', 
      boxShadow: 'none',
      margin: 'auto',
      width: '100%',
      borderRadius: '5px',
      overflow: 'hidden', 
    };

  
    return (
      <Paper elevation={3} style={paperStyles} >
        <Grid container >

          <Grid item container style={{ borderBottom: '1px solid #ddd' }}>
          <Grid item xs={12} className={classes2.tableHeaderStyle}>
              <Typography className={classes2.headerTitle}>
                Past Performance
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes2.tableHeaderStyle}>Entry</Grid>
            <Grid item xs={3} className={classes2.tableHeaderStyle}>Direction</Grid>
            <Grid item xs={3} className={classes2.tableHeaderStyle}>P/L</Grid>
            <Grid item xs={3} className={classes2.tableHeaderStyle}>Result</Grid>   
          </Grid>
          {signals.filter(signal => signal.status !== 'open').map((signal, index) => (
            <Grid item container key={index}>
              <Grid item xs={3} className={classes2.tableCellStyles}>
                <div>{formatTimestamp(signal.open)}</div>
              </Grid>
              <Grid item xs={3} className={classes2.tableCellStyles}>
                <Chip 
                  label={signal.direction.toUpperCase()} 
                  style={{ 
                    backgroundColor: signal.direction.toLowerCase() === 'long' ? longColor : shortColor,
                    color: 'white', height: '25px', borderRadius: '5px'
                  }} 
                />
              </Grid>
              <Grid item xs={3} className={classes2.tableCellStyles}>
                <div>{customFormat(((signal.exit_price - signal.entry_price) / signal.entry_price) * 100, true)}%</div>
              </Grid>
              <Grid item xs={3} className={classes2.tableCellStyles}>
                <Chip
                  label={signal.status.includes('won') ? 'WON' : 'LOST'}
                  style={{
                    backgroundColor: signal.status.includes('won') ? longColor : shortColor,
                    color: 'white', height: '25px', borderRadius: '5px'
                  }}
                />
              </Grid>
              <Grid item sx={12} style={{borderRadius: '5px, 5px, 0, 0', height: '25px'}}/>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
export default CmeTable;
