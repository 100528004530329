/************************************+
 * Used in Screener for selecting values
 * 
 */


export const rsiValues = [
    { value: 'All', label: 'All' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Overbought', label: 'Overbought' },
    { value: 'Oversold', label: 'Oversold' }
  ];
  
  export const macdValues = [
    { value: 'All', label: 'All' },
    { value: 'Bullish', label: 'Bullish' },
    { value: 'Bearish', label: 'Bearish' }
  ];

  export const longTrendValues = [
    { value: 'All', label: 'All' },
    { value: 'Uptrend', label: 'Uptrend' },
    { value: 'Downtrend', label: 'Downtrend' }
  ];

  export const mediumTrendValues = [
    { value: 'All', label: 'All' },
    { value: 'Uptrend', label: 'Uptrend' },
    { value: 'Downtrend', label: 'Downtrend' }
  ];

  export const shortTrendValues = [
    { value: 'All', label: 'All' },
    { value: 'Uptrend', label: 'Uptrend' },
    { value: 'Downtrend', label: 'Downtrend' }
  ];

  export const adxValues = [
    { value: 'All', label: 'All' },
    { value: 'Strong Trend', label: 'Strong Trend' },
    { value: 'Light Trend', label: 'Light Trend' },
    { value: 'Weak', label: 'Weak' }
  ];

  export const psarValues = [
    { value: 'All', label: 'All' },
    { value: 'Uptrend', label: 'Uptrend' },
    { value: 'Downtrend', label: 'Downtrend' },
  ];

  export const stochoscValues = [
    { value: 'All', label: 'All' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Overbought', label: 'Overbought' },
    { value: 'Oversold', label: 'Oversold' }
  ];

  export const willrValues = [
    { value: 'All', label: 'All' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Overbought', label: 'Overbought' },
    { value: 'Oversold', label: 'Oversold' }
  ];

  export const stochrsiValues = [
    { value: 'All', label: 'All' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Overbought', label: 'Overbought' },
    { value: 'Oversold', label: 'Oversold' }
  ];

  export const timeframeValues = [
    { value: '15M', label: '15M' },
    { value: '1H', label: '1H' },
    { value: '1D', label: '1D' }
  ];
  
  // ...other constants
  