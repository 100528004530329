export const chartSignalDirections = {

    'Bullish Pullback': 'Long',
    'RSI Oversold Uptrend': 'Long',
    'RSI Overbought Downtrend': 'Short',
    'Hanging Man': 'Short',
    'Shooting Star': 'Short',
    'Bullish Engulfing': 'Long',
    'Bearish Engulfing': 'Short',
    'Evening Star': 'Short',
    'Morning Star': 'Long',
    'Hammer': 'Long'}
