import React, { useState } from 'react';
import { Grid, Paper, Link, Typography, Box, Button, Tooltip, LinearProgress, Chip, IconButton } from '@mui/material';
import newUi from '../styles/newUi';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import mainStyles from '../styles/mainstyles';
import SmartToyIcon from '@mui/icons-material/SmartToy';


export function BotTable({ data, timeframe, onSelect }) {

  const classes2 = mainStyles();
  const newUiClass = newUi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({});


  const direction_short = {
    'MACD Bullish Crossover': 'Long',
    'MACD Bearish Crossover': 'Short',
    'RSI Overbought': 'Short',
    'RSI Oversold': 'Long',
    'EMA Bullish Crossover': 'Long',
    'EMA Bearish Crossover': 'Short',
    'Bollinger Bands Buy': 'Long',
    'Bollinger Bands Sell': 'Short',
    'EMA Strategy Buy': 'Long',
    'EMA Strategy Sell': 'Short',
    'Parabolic Trader Buy': 'Long',
    'Parabolic Trader Sell': 'Short',
    'Hyper Scalper Buy': 'Long',
    'Hyper Scalper Sell': 'Short',
    'Big Extender Buy': 'Long',
    'Big Extender Sell': 'Short',
    'Bollinger Stochastic Buy': 'Long',
    'Bollinger Stochastic Sell': 'Short',
    'Power Scalper Buy': 'Long',
    'Power Scalper Sell': 'Short',
    'Tri Trendmaster Buy': 'Long',
    'Tri_Trendmaster_Sell': 'Short',
  }
 
 const direction = {
  'MACD Bullish Crossover': 'Long Signal',
  'MACD Bearish Crossover': 'Short Signal',
  'RSI Overbought': 'Short Signal',
  'RSI Oversold': 'Long Signal',
  'EMA Bullish Crossover': 'Long Signal',
  'EMA Bearish Crossover': 'Short Signal',
  'Bollinger Bands Buy': 'Long Signal',
  'Bollinger Bands Sell': 'Short Signal',
  'EMA Strategy Buy': 'Long Signal',
  'EMA Strategy Sell': 'Short Signal',
  'Parabolic Trader Buy': 'Long Signal',
  'Parabolic Trader Sell': 'Short Signal',
  'Hyper Scalper Buy': 'Long Signal',
  'Hyper Scalper Sell': 'Short Signal',
  'Big Extender Buy': 'Long Signal',
  'Big Extender Sell': 'Short Signal',
  'Bollinger Stochastic Buy': 'Long Signal',
  'Bollinger Stochastic Sell': 'Short Signal',
  'Power Scalper Buy': 'Long Signal',
  'Power Scalper Sell': 'Short Signal',
  'Tri Trendmaster Buy': 'Long Signal',
  'Tri_Trendmaster_Sell': 'Short Signal',
}
  
  const details = {
    'MACD Bullish Crossover': 'This bot will open a long trade when the MACD forms a bullish crossover.',
    'MACD Bearish Crossover': 'This bot will open a short trade when the MACD forms a bearish crossover.',
    'RSI Overbought': 'This bot will open a short trade when the RSI is above 70.',
    'RSI Oversold': 'This bot will open a long trade when the RSI is below 30.',
    'EMA Bullish Crossover': 'This bot will open a long trade when the EMA12 crosses the EMA50 from bottom to top.',
    'EMA Bearish Crossover': 'This bot will open a short trade when the EMA12 crosses the EMA50 from top to bottom.',
    'Bollinger Bands Buy': 'This bot will open a long trade when the closing is below the lower Bollinger Bands.',
    'Bollinger Bands Sell': 'This bot will open a short trade when the closing is below the upper Bollinger Bands',
    'EMA Strategy Buy': 'This bot will open a long trade when all long conditions of the EMA Strategy are met. Find more details about the strategy in our documentation.',
    'EMA Strategy Sell': 'This bot will open a short trade when all short conditions of the EMA Strategy are met. Find more details about the strategy in our documentation.',
    'Parabolic Trader Buy': 'This bot will open a long trade when all long conditions of the Parabolic Trader Strategy are met. Find more details about the strategy in our documentation.',
    'Parabolic Trader Sell': 'This bot will open a short trade when all short conditions of the Parabolic Trader Strategy are met. Find more details about the strategy in our documentation.',
    'Hyper Scalper Buy': 'This bot will open a long trade when all long conditions of the Parabolic Trader Strategy are met. Find more details about the strategy in our documentation.',
    'Hyper Scalper Sell': 'This bot will open a short trade when all short conditions of the Hyper Scalper Strategy are met. Find more details about the strategy in our documentation.',
    'Big Extender Buy': 'This bot will open a long trade when all long conditions of the Big Extender Strategy are met. Find more details about the strategy in our documentation.',
    'Big Extender Sell': 'This bot will open a short trade when all short conditions of the Big Extender Strategy are met. Find more details about the strategy in our documentation.',
    'Bollinger Stochastic Buy': 'This bot will open a long trade when all long conditions of the Bollinger Stochastic Strategy are met. Find more details about the strategy in our documentation.',
    'Bollinger Stochastic Sell': 'This bot will short a long trade when all short conditions of the Bollinger Stochastic Strategy are met. Find more details about the strategy in our documentation.',
    'Power Scalper Buy': 'This bot will open a long trade when all long conditions of the Power Scalper Strategy are met. Find more details about the strategy in our documentation.',
    'Power Scalper Sell': 'This bot will short a short trade when all long conditions of the Power Scalper Strategy are met. Find more details about the strategy in our documentation.',
    'Tri Trendmaster Buy': 'This bot will open a long trade when all long conditions of the Tri Trendmaster Strategy are met. Find more details about the strategy in our documentation.',
    'Tri_Trendmaster_Sell': 'This bot will open a short trade when all short conditions of the Tri Trendmaster Strategy are met. Find more details about the strategy in our documentation.',
}

  if (data == null) {return}

  return (
    <>
    <Grid container alignItems="flex-start" fullWidth spacing={2} className={newUiClass.headerGrid2}>
          {data.map((row, index) => (
          <Grid item xs={12} md={3} >
            <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }}>
              <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexShrink: 0 }}>
                      <SmartToyIcon />
                
                      <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                        <b>{row.label}</b>
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right", width: '100%' }}>
                      <Typography style={{ color: '#333', fontSize: '2em', fontWeight: '800' }}>
                        
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                      <Chip className={newUiClass.standardChip1}  label={direction[row.label] } style={{background: direction[row.label] == "Long Signal" ? "darkgreen" : "darkred"}}  />
                      {(row.successRate > 75 ) && <Chip className={newUiClass.standardChip1}  label="High Win Rate" style={{background:  "darkgreen" }}  />}
                      {(row.signals > 30 && timeframe == "1D" ) && <Chip className={newUiClass.standardChip1}  label="High Signal Count" style={{background:  "darkgreen" }}  />}
                      {(row.signals > 250 && timeframe == "1H" ) && <Chip className={newUiClass.standardChip1}  label="High Signal Count" style={{background:  "darkgreen" }}  />}
                      {(row.signals > 1000 && timeframe == "15M" ) && <Chip className={newUiClass.standardChip1}  label="High Signal Count" style={{background:  "darkgreen" }}  />}
                    </Box>
                  </div>
                  
                    <>
                      <div style={{ marginTop: '15px' }}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Historical Win Rate</i></Typography>
                        <Tooltip >
                        {row.successRate}%
                        </Tooltip>
                       </div>
                       <div style={{ marginTop: '15px' }}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Amount of Signals</i></Typography>
                        <Tooltip >
                        {row.signals}
                        </Tooltip>
                       </div>
                      <div style={{ marginTop: '25px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Backtest Details</i></Typography>
                  <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', color: '#1A1A1A' }}>
                  {details[row.label]}
                  </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button style={{backgroundColor: '#3558FF', color: 'white', paddingLeft: '15px', paddingRight: '15px'}} onClick={() => {onSelect(row.value, direction_short[row.label])}}>Activate</Button>      
                          <div>
                          </div>
                        </div>
                      </div>
                    </>
                </Paper>
              </Grid>
              ))}
        </Grid>
        </>          
      
    );
    
}


export default BotTable;
