import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper, Typography, Box, Tooltip, LinearProgress, Chip, IconButton } from '@mui/material';
import newUi from '../styles/newUi';
import {  formatTimestamp } from '../functions/customFormat';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import mainStyles from '../styles/mainstyles';
import { customFormat } from '../functions/customFormat';
import ManualOrder from "../forms/bots/manualOrder";
import BotOrder from "../forms/bots/botOrder";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import ShareIcon from '@mui/icons-material/Share';
import { copyToClipboard } from '../functions/shareLinks';
import { shareOnX } from '../functions/shareLinks';
import XIcon from '@mui/icons-material/X';
import LikeButton from '../functions/likeButton';
import { AuthContext } from '../AuthContext';
import axios from "axios";
import VideoItem from "./videoItem";
import BlogMessage from "./blogMessages";

export function SingleSignal({ signal }) {
    
  const classes2 = mainStyles();
  const newUiClass = newUi();
  const theme = useTheme();
  const { auth, logout } = useContext(AuthContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({});
  const [tradeDialogOpen, setTradeDialogOpen] = useState(false);
  const [botDialogOpen, setBotDialogOpen] = useState(false);
  const [tradeSymbol, setTradeSymbol] = useState('');
  const [tradeFullName, setTradeFullName] = useState('');
  const [tradePrice, setTradePrice] = useState('');
  const [tradeDirection, setTradeDirection] = useState('');
  const [botSignal, setBotSignal] = useState('');
  const [botTimeframe, setBotTimeframe] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedBotRow, setSelectedBotRow] = useState(null);
  const [email, setEmail] = useState();
  const [blogMessages, setBlogMessages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => { window.scrollTo(0, 0); }, []);

  const handleCloseImageDialog = () => {
      setImageDialogOpen(false);
      setCurrentImage(null);
  };

  const handleOpenImageDialog = (image) => {
      setCurrentImage(image);
      setImageDialogOpen(true);
  };
  

  useEffect(() => { 
    const email = auth?.user?.email || '';
    setEmail(email);
  }, [auth] )

  const handleTradeDialogToggle = (row, event) => {
    if (event) { event.stopPropagation(); }
    if (selectedRow && selectedRow.symbol === row.symbol && tradeDialogOpen) {
      setTradeDialogOpen(false);
      setSelectedRow(null);
    } else {
      setTradeDirection(row.direction);
      setTradeSymbol(row.symbol);
      setTradeFullName(row.fullName);
      setTradePrice(row.price);
      setSelectedRow(row);
      setTradeDialogOpen(true);
    }
  };

  useEffect(() => {
    if (signal) {
        fetchBlogMessages(signal.symbol);
        fetchVideos(signal.symbol);
        };
    }
  , [signal]);

  const handleBotDialogToggle = (row, event) => {
    if (event) {
      event.stopPropagation();
    }
    if (selectedBotRow && selectedBotRow.symbol === row.symbol && botDialogOpen) {
      setBotDialogOpen(false);
      setSelectedBotRow(null);
    } else {
      setTradeDirection(row.direction);
      const complete = `${row.trade_signal} ${row.direction}`;
      setBotSignal(complete);
      setTradeSymbol(row.symbol);
      setTradeFullName(row.fullName);
      setTradePrice(row.price);
      setBotTimeframe(row.timeframe);
      setSelectedBotRow(row);
      setBotDialogOpen(true);
    }
  };

  const closeBotDialog = () => { setBotDialogOpen(false); };

  function probabilityChip(probability) {
    const numericProbability = parseFloat(probability);
    if (numericProbability >= 90) {
      return { label: "HIGHEST PROBABILITY", color: "darkgreen" };
    } else if (numericProbability >= 80) {
      return { label: "HIGH PROBABILITY", color: "green" };
    } else if (numericProbability >= 70) {
      return { label: "MODERATE PROBABILITY", color: "gold" };
    } else if (numericProbability >= 50) {
      return { label: "LOW PROBABILITY", color: "orange" };
    } else if (numericProbability < 50) {
      return { label: "POOR PROBABILITY", color: "red" };
    } else {
      return { label: 'NO DATA', color: 'grey' }
    }
  }

  const probability = signal.timeframe === '1D' ? signal.bestProbability5 : signal.bestProbability2;
  const { label, color } = probabilityChip(probability);
  const rowId = `${signal.symbol}`;

  let percentageFloat = 0;  
  if (signal.bestProbability2) { 
    const percentageStr = signal.bestProbability2.replace('%', '');
    percentageFloat = parseFloat(percentageStr);
  }

   // if(auth.isAuthenticated == '') {return;}


  /** Function for Blog messages */
  const fetchBlogMessages = async (symbol) => {
    try {
        const response = await axios.get(`/api/blog-messages?symbol=${symbol}`);
        if (response.error) { console.error(response.error); } else { setBlogMessages(response.data); 
            console.log(response.data)
        }
    } catch (error) { console.error('Error fetching latest signals:', error); }
  };


  /** Function to fetch Youtube videos based on Symbol and Tag */
    const fetchVideos = async (symbol) => {
    try {
        const response = await axios.get(`/api/videos/${symbol}`);
        if (response.error) { console.error(response.error); } else { setVideos(response.data); }
    } catch (error) { console.error('Error fetching videos:', error); }
  };


  return (
    <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid} style={{background: 'white', maxHeight: '1100px', }}>
      <Grid item xs={12} md={8} style={{ paddingLeft: (!isMobile) ? '0px' : '12px', paddingRight: (!isMobile) ? '0px' : '12px', alignItems: 'stretch',height: '100%', maxHeight: '1050px', overflow: 'auto' }}>
      <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} >
          <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div style={{ flexShrink: 0 }}>
              <img src={signal.logo_url} alt={signal.symbol} style={{ width: "35px" }} />
            </div>
            <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
              <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                <b>{signal.symbol}</b><br />
                <span style={{ color: '#AAAAAA', fontSize: '0.8em' }}>
                  {signal.fullName}
                </span>
              </Typography>
            </div>
          </div>
          <div>
            <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
              <Chip className={newUiClass.standardChip} style={{ background: '#F5F7FE' }} label={signal.trade_signal} />
              <Chip className={newUiClass.standardChip} style={{ background: '#F5F7FE' }} label={signal.timeframe} />
              <Chip className={newUiClass.standardChipColorless} style={{ background: signal.direction === "Short" ? "darkred" : "darkgreen" }} label={signal.direction} />
              <Chip className={newUiClass.standardChipColorless} style={{ background: color }} label={label} />
            </Box>
          </div>
          <div style={{ marginTop: '25px' }}>
            <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Detected On</i></Typography>
            <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', color: '#1a1a1a' }}>
              {formatTimestamp(signal.timestamp)} UTC
            </Typography>
          </div>
          <div style={{ marginTop: '5px' }}>
            <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Profit Potential</i></Typography>
            <Typography style={{ color: '#333', fontSize: '2em', fontWeight: '800' }}>
              {signal.profit_potential}%
            </Typography>
          </div>
          <div style={{ marginTop: '15px' }}>
            <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Current Price (24h Change)</i></Typography>
            <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(signal['24h_change']) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
              <span style={{ paddingRight: '5px', fontWeight: '600' }}>${customFormat(signal.latest_price)}</span>({customFormat(signal['24h_change'])}%)
            </Typography>
          </div>
          <div style={{ marginTop: '15px' }}>
            <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Historical Win Rate</i></Typography>
            <Tooltip title={signal.backtest_value}>
              <LinearProgress style={{ width: '100%' }} classes={{ colorPrimary: newUiClass.colorPrimary, barColorPrimary: newUiClass.barColorPrimary }} variant="determinate" value={percentageFloat} />
            </Tooltip>
          </div>
          <div style={{ marginTop: '25px' }}>
            <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Backtest Details</i></Typography>
            <Typography className={classes2.myBoxTextThree} style={{ fontSize: '0.9em', color: '#1A1A1A' }}>
              The signal has a historical probability of <b>{signal.bestProbability2} to achieve 2% gains, {signal.bestProbability5} to achieve 5% gains, and {signal.bestProbability10} to achieve 10% gains during the following 30 candles.</b> The backtest results are based on {signal.detectedSignals2} historical signals on the {signal.timeframe} chart.
            </Typography>
            <MinimizedTokenInfo token={signal.symbol} />
          
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                <LikeButton userEmail={email} contentId={signal.id} type="trade_signal" content={signal}/>
              </Typography>        
            <div>
              <Tooltip title="Copy Shareable Link">
                <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={() => copyToClipboard(signal.unique_link)}>
                  <ShareIcon style={{ color: '#333' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share On X">
                <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={() => shareOnX("Check out the latest Crypto OS Trade Signal", (signal.unique_link) )}>
                  <XIcon style={{ color: '#333' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bot Setup">
                <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={(event) => handleBotDialogToggle(signal, event)}>
                  <AutoModeIcon style={{ color: '#333' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Trade Now">
                <IconButton aria-label="account-balance" style={{ boxShadow: '#ebebeb 1px 1px 1px 1px', marginRight: '5px' }} onClick={(event) => handleTradeDialogToggle(signal, event)}>
                  <CompareArrowsIcon style={{ color: '#333' }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
    <Grid item xs={12} md={4} style={{ paddingLeft: (!isMobile) ? '0px' : '12px', paddingRight: (!isMobile) ? '0px' : '12px', alignItems: 'stretch',  height: '100%', maxHeight: '1050px', overflow: 'auto' }}>
      <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} >
        <Typography className={classes2.myBoxText} style={{ fontWeight: '400', marginBottom: '10px' }}>Curated Analysis</Typography>
          {blogMessages.length > 0 ? (
            blogMessages.map((message) => ( <BlogMessage key={message.id} message={message} handleOpenImageDialog={handleOpenImageDialog} /> ))
          ) : ( <Typography style={{color: '#1a1a1a', fontSize: '0.8em'}}><i>No analysis available yet — check back soon!</i></Typography> )}
      </Paper>
    </Grid>
  


      {tradeDialogOpen && selectedRow?.symbol === signal.symbol && (
        <Grid item xs={12} md={4} key={`trade-dialog-${signal.symbol}`} style={{ paddingLeft: '0px', paddingRight: '0px', alignItems: 'stretch' }}>
          <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto', padding: '20px', borderRadius: '8px', backgroundColor: 'white' }}>
            <ManualOrder
              onAccountBalanceClick={handleTradeDialogToggle}
              fullName={tradeFullName}
              direction={tradeDirection}
              latestPrice={tradePrice}
              symbol={tradeSymbol}
            />
          </Paper>
        </Grid>
      )}


      {botDialogOpen && selectedBotRow?.symbol === signal.symbol && (
        <Grid item xs={12} md={4} key={`bot-dialog-${signal.symbol}`} style={{ paddingLeft: '0px', paddingRight: '0px', alignItems: 'stretch' }}>
          <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto', padding: '20px', borderRadius: '8px', backgroundColor: 'white' }}>
            <BotOrder
              onBotClick={handleBotDialogToggle}
              closeBotClick={closeBotDialog}
              fullName={tradeFullName}
              direction={tradeDirection}
              latestPrice={tradePrice}
              symbol={tradeSymbol}
              signal={botSignal}
              directionTrade={tradeDirection}
              timeframe={botTimeframe}
            />
          </Paper>
        </Grid>
      )}
    
    </Grid>
  );
}

export default SingleSignal;
