import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState, useEffect, useContext } from "react";
import { Snackbar, Typography, Grid, Button } from '@material-ui/core';
import sharedStyles from '../styles/sharedStyles';
import useAddPrices from '../functions/addPrices2';
import { rsiValues, macdValues, shortTrendValues, mediumTrendValues, longTrendValues, stochoscValues, stochrsiValues, willrValues, psarValues, adxValues, timeframeValues } from "../data/menuItems.js";
import ScreenerTable from '../elements/screenerTable';
import newUi from "../styles/newUi";
import { SelectControlBottomMargin } from '../elements/SelectControl';
import { addMultiplePrices2 } from '../functions/addPrices.js';
import { AuthContext } from '../AuthContext';

function Screener() {
  const newUiClass = newUi();
  const { auth, logout } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;
  const [macd, setMacd] = useState("All");
  const [rsi, setRsi] = useState("All");
  const [shortTrend, setShortTrend] = useState("All");
  const [mediumTrend, setMediumTrend] = useState("All");
  const [longTrend, setLongTrend] = useState("All");
  const [stochosc, setStochosc] = useState("All");
  const [stochrsi, setStochrsi] = useState("All");
  const [willr, setWillr] = useState("All");
  const [psar, setPsar] = useState("All");
  const [adx, setAdx] = useState("All");
  const [timeframe, setTimeframe] = useState("1D");
  const [displayData, setDisplayData] = useState([]);

  const handleMacdChange = (event) => { setMacd(event.target.value);  };
  const handleRsiChange = (event) => { setRsi(event.target.value);  };
  const handleShortTrendChange = (event) => { setShortTrend(event.target.value);  };
  const handleMediumTrendChange = (event) => { setMediumTrend(event.target.value);  };
  const handleLongTrendChange = (event) => { setLongTrend(event.target.value);  };
  const handleStochoscChange = (event) => { setStochosc(event.target.value);  };
  const handleStochrsiChange = (event) => { setStochrsi(event.target.value);  };
  const handleWillrChange = (event) => { setWillr(event.target.value);  };
  const handlePsarChange = (event) => { setPsar(event.target.value);  };
  const handleAdxChange = (event) => { setAdx(event.target.value);  };
  const handleTimeframeChange = (event) => { setTimeframe(event.target.value);  };

  
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = sharedStyles();
  const { selectedData, addPrices } = useAddPrices();
  const [allData, setAllData] = useState([]); 
  const [dataReady, setDataReady] = useState(false);


  useEffect(() => { window.scrollTo(0, 0); }, []);   

  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
  
      try {
        const response = await fetch('/api/new-screener');
        const data = await response.json();
  
        const groups = data.reduce((acc, item) => {
          acc[item.symbol] = acc[item.symbol] || [];
          acc[item.symbol].push(item);
          return acc;
        }, {});
  
        const filteredData = Object.values(groups).filter(group => group.length === 3).flat();
        setAllData(filteredData); 
        
        const updateData = async () => {
          try {
              const updatedData = await addMultiplePrices2(filteredData);
              setAllData(updatedData);
              
          } catch (error) { console.error('Error updating data:', error); }
        };
      
        updateData();


      } catch (error) { console.error(error);
      } finally {
        setDataReady(true);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => { if (dataReady) { setTimeout(() => { setIsLoading(false); }, 5000); }}, [dataReady]);


  useEffect(() => {
    const filteredData = selectedData.filter(item => 
      item.last_pirce !== null && 
      item.last_pirce !== undefined && 
      item.last_price !== ''
    );    
  }, [selectedData]);


  const applyFilters = () => {
    const filteredData = allData.filter(item => {
      return (macd === "All" || item.macd === macd) &&
        (rsi === "All" || item.rsi === rsi) &&
        (shortTrend === "All" || item.short_ema === shortTrend) &&
        (mediumTrend === "All" || item.medium_ema === mediumTrend) &&
        (longTrend === "All" || item.long_ema === longTrend) &&
        (stochosc === "All" || item.stochastic_oscillator === stochosc) &&
        (stochrsi === "All" || item.stoch_rsi === stochrsi) &&
        (willr === "All" || item.willr === willr) &&
        (psar === "All" || item.psar === psar) &&
        (adx === "All" || item.adx === adx) &&
        (  item.timeframe === timeframe);
    });
    setDisplayData(filteredData);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpen(false);
  };

  

  return (
    <div >
      <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
        <Typography className={newUiClass.componentTitle}>Token Screener</Typography>
        <Typography className={newUiClass.componentSubtitle}><i>Filter more than 200 tokens.</i></Typography>
      </div>
      <form>
      <Grid container spacing={2} className={newUiClass.headerGrid} style={{paddingRight: '0px'}}>
            {(isLoading) && 
              <Grid item xs={12}>
                <div className={classes.mobileHeader} style={{marginTop: '10px', marginBottom: '10px'}}>
                  <CircularProgress style={{color: '#fff'}}/>
                </div>
            </Grid>   
            }
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='MACD' value={macd} onChange={handleMacdChange} options={macdValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='RSI' value={rsi} onChange={handleRsiChange} options={rsiValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='ADX' value={adx} onChange={handleAdxChange} options={adxValues} />              
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectControlBottomMargin label='PSAR' value={psar} onChange={handlePsarChange} options={psarValues} />  
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='Short Trend' value={shortTrend} onChange={handleShortTrendChange} options={shortTrendValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='Medium Trend' value={mediumTrend} onChange={handleMediumTrendChange} options={mediumTrendValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='Long Trend' value={longTrend} onChange={handleLongTrendChange} options={longTrendValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='WilliamsR' value={willr} onChange={handleWillrChange} options={willrValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='Stoch. Oscillator' value={stochosc} onChange={handleStochoscChange} options={stochoscValues} />
            </Grid>
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='Stoch RSI' value={stochrsi} onChange={handleStochrsiChange} options={stochrsiValues} />
            </Grid> 
            <Grid item xs={12} md={6} >
              <SelectControlBottomMargin label='Timeframe' value={timeframe} onChange={handleTimeframeChange} options={timeframeValues} />
            </Grid>
            <Grid item xs={12} md={12} >
            <Button className={newUiClass.loginButton} variant="contained" color="primary" onClick={applyFilters}>
                Apply Filters
              </Button>
            </Grid>
            </Grid>
        </form>
            <Grid container alignItems="stretch" spacing={2} >
              <Grid item xs={12}>
                <ScreenerTable data={displayData} />
              </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}  message={errorMessage} />
        </div>
);
}
export default Screener;