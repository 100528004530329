import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, CircularProgress, Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import Snackbar from '@mui/joy/Snackbar';
import { AuthContext } from '../AuthContext';
import newUi from '../styles/newUi';

const BacktestEmailAlert = () => {
  const [timeframe, setTimeframe] = useState([]);
  const { auth } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState();

  const newUiClass = newUi();

  useEffect(() => {
    fetchInitialAlerts();
  }, []);

  const fetchInitialAlerts = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/get-backtest-email-alerts?email=${email}`);
      const data = await response.json();
      const fetchedTimeframes = data.map(alert => alert.timeframe);
      setTimeframe(fetchedTimeframes);
    } catch (error) {
      console.error('Failed to fetch alerts:', error);
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = (event) => {
    const tf = event.target.name;
    setTimeframe(prev => prev.includes(tf) ? prev.filter(t => t !== tf) : [...prev, tf]);
  };

  const saveAlerts = async () => {
    if (!email) {
      setErrorMessage("You need to be logged in to save alerts!");
      setAlertOpen(true);
      setType('warning');
      return;
    }

    setIsLoading(true);

    try {
      const fetchResponse = await fetch(`/api/get-backtest-email-alerts?email=${email}`);
      const existingAlerts = await fetchResponse.json();
      const existingTimeframes = Array.isArray(existingAlerts) ? existingAlerts.map(alert => alert.timeframe) : [];
      const timeframesToDelete = existingTimeframes.filter(tf => !timeframe.includes(tf));
      const timeframesToSave = timeframe.filter(tf => !existingTimeframes.includes(tf));

      await Promise.all(timeframesToDelete.map(tf =>
        fetch('/api/delete-backtest-email-alert', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, timeframe: tf })
        })
      ));

      if (timeframesToSave.length > 0) {
        await Promise.all(timeframesToSave.map(tf =>
          fetch('/api/save-backtest-email-alert', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, timeframe: tf })
          })
        ));
        setErrorMessage("Email alerts saved successfully");
        setAlertOpen(true);
        setType('success');
      } else if (timeframesToDelete.length > 0) {
        setAlertOpen(true);
        setType('success');
        setErrorMessage("Unselected email alerts deleted successfully!");
      } else {
        setAlertOpen(true);
        setErrorMessage("No changes made to email alerts.");
        setType('warning');
      }
    } catch (error) {
      console.error(error);
      setAlertOpen(true);
      setType('warning');
      setErrorMessage("Failed to update alerts. Please try again.");
    }

    setIsLoading(false);
    
  };

  return (
    <>
      <Snackbar
        open={open}
        
        sx={{ minWidth: 360 }}
      >
        <Stack spacing={0.5} sx={{ padding: '10px' }}>
          <h3>Receive Automated Email Alerts</h3>
          <Typography level="title-md">Simply select your desired chart timeframes and we'll make sure <br/> to send out the top trade signals right into your inbox.</Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={timeframe.includes('1H')} onChange={handleCheckboxChange} name="1H" />}
              label="1H Chart Timeframe"
            />
            <FormControlLabel
              control={<Checkbox checked={timeframe.includes('1D')} onChange={handleCheckboxChange} name="1D" />}
              label="1D Chart Timeframe"
            />
          </FormGroup>
          <Button
            variant="contained"
            style={{ backgroundColor: '#3558FF' }}
            onClick={saveAlerts}
            startIcon={isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : null}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Stack>
      </Snackbar>

      <Snackbar
        variant="soft"
        color={type}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        endDecorator={
          <Button onClick={() => setAlertOpen(false)} size="sm" style={{ color: '#1a1a1a' }}>
            Dismiss
          </Button>
        }
      >
        {errorMessage}
      </Snackbar>
    </>
  );
};

export default BacktestEmailAlert;
