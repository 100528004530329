import React, { useState, useEffect, useContext } from "react";
import { Snackbar, Grid, Button, Typography } from "@material-ui/core";
import { limitResultsForSupportAndResistance } from "../functions/subscriptionCheck";
import CircularProgress from '@mui/material/CircularProgress';
import { addMultiplePrices2 } from "../functions/addPrices";
import { SelectControl } from "../elements/SelectControl";
import newUi from "../styles/newUi";
import Alert from '@mui/material/Alert';
import { SupportResistanceDashboard } from "./dashboards/supportResistanceDashboard";
import { ResultsTable } from "../elements/resultsTable";
import { AuthContext } from '../AuthContext';


function SupportAndResistance() {
    const [alerts, setAlerts] = useState([]);
    const newUiClass = newUi();
    const { auth, logout } = useContext(AuthContext);
    let email = auth?.user?.email || '';
    const hasAccess = auth?.isAuthenticated;
    const [field1, setField1] = useState("today");
    const [field3, setField3] = useState("15M");
    const handleField1Change = (event) => { setField1(event.target.value);  };
    const handleField3Change = (event) => { setField3(event.target.value);  };
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");  


    useEffect(() => { window.scrollTo(0, 0); }, []);

    useEffect(() => {
        let isComponentMounted = true;
        setIsLoading(true);
    
        const fetchData = async () => {
            try {
                const res = await fetch('/api/supportAndResistance');
                const data = await res.json();
                if (data.error) { console.error(data.error);
                } else {

                    const limitedData = limitResultsForSupportAndResistance(data, hasAccess);
                    const updatedData = await addMultiplePrices2(limitedData);
                    
                    if (isComponentMounted) {
                        
                        setTableData(updatedData);
                        setFinalData(updatedData);
                        if (!hasAccess) {
                            setErrorMessage("Results are limited to five daily signals. Sign-up or login to see all results!");
                            setOpen(true);
                        }
                    }
                }
            } catch (error) { console.error('Error fetching or updating data:', error);
            } finally { if (isComponentMounted) { setIsLoading(false); }
            }
        };
    
        fetchData();
        return () => { isComponentMounted = false; };
    }, []);

    useEffect(() => {
        if (tableData.length > 0 && finalData.length > 0) { handleSubmit(field1, field3); }
      }, [finalData]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpen(false);
      };

    const formatDate = (date) => {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
    };
 
    const getDateForFilter = (filter) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    switch (filter) {
        case 'today':
        return today;
        case 'yesterday':
        return new Date(today.setDate(today.getDate() - 1));
        case 'days-2':
        const newToday = new Date();
        newToday.setHours(0, 0, 0, 0);
        return new Date(newToday.setDate(newToday.getDate() - 2));
        default:
        return today;
    }
    };
      

    const handleSubmit = (selectedDateFilter, selectedTimeframe) => {
        setIsLoading(true);
        const selectedDate = getDateForFilter(selectedDateFilter);
        const formattedSelectedDate = formatDate(selectedDate); // Use the custom formatting function
        const filteredAlerts = finalData.filter((finalData) => {
            const alertDate = new Date(finalData.alert_time);
            const alertDateFormatted = alertDate.toISOString().split('T')[0]; 
            const matchesDate = alertDateFormatted === formattedSelectedDate;
            const matchesTimeframe = finalData.timeframe === selectedTimeframe;
            return matchesDate && matchesTimeframe;
        });
        setTableData(filteredAlerts);
        setIsLoading(false);
    };

    return (
        <div>
            <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
                <Typography className={newUiClass.componentTitle}>Support & Resistance Signals</Typography>
                <Typography className={newUiClass.componentSubtitle}><i>Never miss a token breaking essential price levels.</i></Typography>
            </div>
            <Grid container spacing={2} className={newUiClass.headerGrid}>
                {(!hasAccess) && <Alert className={newUiClass.infoBar} severity="warning">Results are limited. Please login or upgrade to use this feature.</Alert>}
                <SupportResistanceDashboard />
            </Grid>
            <div className={newUiClass.filterBox}>
                <Grid container spacing={2} className={newUiClass.filterGrid}>
                    <Grid item xs={12} md={12}>
                        <Typography className={newUiClass.filterBoxTitle}>Filter</Typography>
                        <Typography className={newUiClass.filterBoxSubtitle}><i>Here you can filter the results based on your preferences.</i></Typography>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <SelectControl label="Date"  value={field1}  onChange={handleField1Change} 
                        options={[{ value: "today", label: "Today" }, {value: "yesterday", label: "Yesterday"}, {value: "days-2", label: "Today -2"}]} newUiClass={newUiClass}
                    />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectControl  label="Timeframe"  value={field3}  onChange={handleField3Change} 
                        options={[{ value: "1D", label: "1D" }, {value: "1H", label: "1H"}, {value: "15M", label: "15M"}]} 
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button type="submit" variant="contained" fullWidth color="primary" className={newUiClass.loginButton} onClick={() => handleSubmit(field1, field3)} style={{marginTop: '5px', width: '100%'}}>
                            {isLoading ? <CircularProgress size={24} /> : "Filter"}
                        </Button>
                    </Grid>
                </Grid>  
            </div>   
            <ResultsTable signals={tableData} />
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} message={errorMessage} />
        </div>
    );
}
export default SupportAndResistance;