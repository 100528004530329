export function limitResultsForTier(data, isAllowed) {
  if (!isAllowed) { return data.slice(0, 3); }
  return data; 
}

export function limitResultsForSupportAndResistance(data, isAllowed) {
  const sortedData = data.sort((a, b) => new Date(b.alert_date) - new Date(a.alert_date));
  if (!isAllowed) { return sortedData.slice(0, 5); }
  return sortedData; 
}


export function simpleLimitResults(data, tier) {
  if (["Scalper", "Scalper Tier", "Expert", "Expert Tier", "Bot Tier", "Trader", "Analyst", "Crypto OS Premium Access", "Crypto OS Trading Plan"].indexOf(tier) === -1) {
    return data.slice(0, 9);
  }
  return data; 
}

export function simpleLimitResultsPattern(data, tier) {
  if (["Scalper", "Scalper Tier", "Expert", "Expert Tier", "Bot Tier", "Trader", "Analyst", "Crypto OS Premium Access", "Crypto OS Trading Plan"].indexOf(tier) === -1) {
    return data.slice(0, 3);
  }
  return data; 
}
  
  
export function getHighestSubscription(userData) {
  
  if (!userData || !userData.data || !userData.data.currentMember || !userData.data.currentMember.subscriptions) return "";
  const subscriptionTiers = ["Crypto OS Trading Plan", "Crypto OS Premium Access", "Crypto OS Free Plan"];
  for (const tier of subscriptionTiers) {    
    if (userData.data.currentMember.subscriptions.some(sub => {
      const matches = sub.plan.name === tier && sub.active;
            return matches;
    })) {
      return tier;
    }
  }

  return "";
}
