// This one is used for the selectbox entries


export const signalToBacktestTranslator = {
  'MACD Crossover Long': 'macd',
  'MACD Crossover Short': 'macd',
  'RSI Short': 'rsi',
  'RSI Long': 'rsi',
  'EMA Crossover Long': 'ema_crossover',
  'EMA Crossover Short': 'ema_crossover',
  'EMA Strategy Long': 'ema_strategy',
  'EMA Strategy Short': 'ema_strategy',
  'Big Extender Long': 'big_extender',
  'Big Extender Short': 'big_extender',
  'Bollinger Bands Long': 'bollinger_bands',
  'Bollinger Bands Short': 'bollinger_bands',
  'Stochastic Bollinger Long': 'bollinger_stochastic',
  'Stochastic Bollinger Short': 'bollinger_stochastic',
  'Hyper Scalper Long': 'hyper_scalper',
  'Hyper Scalper Short': 'hyper_scalper',
  'Parabolic Trader Long': 'parabolic_trader',
  'Parabolic Trader Short': 'parabolic_trader',
  'Power Scalper Long': 'power_scalper',
  'Power Scalper Short': 'power_scalper',
  'Tri Trendmaster Long': 'tri_signal_trendmaster',
  'Tri Trendmaster Short': 'tri_signal_trendmaster',
  'Trend Signal Fusion Long': 'trend_signal_fusion',
  'Trend Signal Fusion Short': 'trend_signal_fusion',
  'Ichimoku Long': 'ichimoku',
  'Ichimoku Short': 'ichimoku'
};



export const backtestStrategyTranslator = [
    { value: "macd", label: "MACD" },
    { value: "rsi", label: "RSI" },
    { value: "ema", label: "EMA Crossover" },
    { value: "bb", label: "Bollinger Band" },
    { value: "emaStrategy", label: "EMA Strategy" },
    { value: "parabolicTrader", label: "Parabolic Trader" },
    { value: "hyperScalper", label: "Hyper Scalper" },
    { value: "bigExtender", label: "Big Extender" },
    { value: "bollingerStochastic", label: "Bollinger Stochastic" },
    { value: "powerScalper", label: "Power Scalper" },
    { value: "triTrendmaster", label: "Tri Trendmaster" },
    { value: "precisionPuls", label: "Precision Pulse" },
]


export const botStrategies = {
  "MACD_Bearish_Crossover": "MACD", 
  "MACD_Bullish_Crossover": "MACD", 
  "RSI_Above_70": "RSI", 
  "RSI_Below_30": "RSI", 
  "EMA_Bullish_Crossover": "EMA",
  "EMA_Bearish_Crossover": "EMA", 
  "BB_Buy": "Bollinger", 
  "BB_Sell": "Bollinger ", 
  "EMA_Strategy_Bullish": "EMA Strategy", 
  "EMA_Strategy_Bearish": "EMA Strategy",
  "Parabolic_Trader_Buy": "Parabolic Trader", 
  "Parabolic_Trader_Sell": "Parabolic Trader",
  "Hyper_Scalper_Bullish": "Hyper Scalper",
   "Hyper_Scalper_Bearish": "Hyper Scalper",
  "Big_Extender_Bullish": "Big Extender", 
  "Big_Extender_Bearish": "Big Extender",
  "Bollinger_Stochastic_Bullish": "Bollinger + Stochastic", 
  "Bollinger_Stochastic_Bearish": "Bollinger + Stochastic",
  "Power_Scalper_Bullish": "Power Scalper", 
  "Power_Scalper_Bearish": "Power Scalper",
  "Tri_Trendmaster_Buy": "Tri Trendmaster", 
  "Tri_Trendmaster_Sell": "Tri Trendmster",
  "Precision_Pulse_Buy": "Precsion Pulse", 
  "Precision_Pulse_Sell": "Precision Pulse"
}




  // This one is used for the visualization, so we display reasonable names!
  export const backtestStrategyTranslatorThree = {
    "MACD_Bearish_Crossover": "MACD Bearish Crossover", 
    "MACD_Bullish_Crossover": "MACD Bullish Crossover", 
    "Filtered_MACD_Bullish_Crossover": "MACD Bullish Crossover + ADX",
    "Filtered_MACD_Bearish_Crossover": "MACD Bearish Crossover + ADX",
    "RSI_Above_70": "RSI Overbought", 
    "RSI_Below_30": "RSI Oversold", 
    "Filtered_RSI_Above_70": "RSI Overbought + ADX", 
    "Filtered_RSI_Below_30": "RSI Oversold + ADX",
    "EMA_Bullish_Crossover": "EMA Bullish Crossover",
    "EMA_Bearish_Crossover": "EMA Bearish Crossover", 
    "Filtered_EMA_Bullish_Crossover": "EMA Bullish Crossover + ADX", 
    "Filtered_EMA_Bearish_Crossover": "EMA Bearish Crossover + ADX",
    "BB_Buy": "Bollinger Bands Buy", 
    "BB_Sell": "Bollinger Bands Sell", 
    "Filtered_BB_Buy": "Bollinger Bands Buy + ADX", 
    "Filtered_BB_Sell": "Bollinger Bands Sell + ADX",
    "EMA_Strategy_Bullish": "EMA Strategy Buy", 
    "EMA_Strategy_Bearish": "EMA Strategy Sell",
    "Parabolic_Trader_Buy": "Parabolic Trader Buy", 
    "Parabolic_Trader_Sell": "Parabolic Trader Sell",
    "Hyper_Scalper_Bullish": "Hyper Scalper Buy",
     "Hyper_Scalper_Bearish": "Hyper Scalper Sell",
    "Big_Extender_Bullish": "Big Extender Buy", 
    "Big_Extender_Bearish": "Big Extender Sell",
    "Bollinger_Stochastic_Bullish": "Bollinger Stochastic Buy", 
    "Bollinger_Stochastic_Bearish": "Bollinger Stochastic Sell",
    "Power_Scalper_Bullish": "Power Scalper Buy", 
    "Power_Scalper_Bearish": "Power Scalper Sell",
    "Tri_Trendmaster_Buy": "Tri Trendmaster Buy", 
    "Tri_Trendmaster_Sell": "Tri Trendmster Sell",
    "Precision_Pulse_Buy": "Precsion Pulse Buy", 
    "Precision_Pulse_Sell": "Precision Pulse Sell"
  };

  const variableMapping = {
    'macdCrossover_long': 'MACD_Bullish_Crossover',
    'macdCrossover_short':  'MACD_Bearish_Crossover',
    'macdCrossoverAdx_long': 'Filtered_MACD_Bullish_Crossover',
    'macdCrossoverAdx_short': 'Filtered_MACD_Bearish_Crossover',
    'rsi_short': 'RSI_Above_70',
    'rsi_long':  'RSI_Below_30',
    'rsiAdx_short': 'Filtered_RSI_Above_70',
    'rsiAdx_long': 'Filtered_RSI_Below_30',
    'emaCrossover_long': 'EMA_Bullish_Crossover',
    'emaCrossover_short': 'EMA_Bearish_Crossover',
    'emaCrossoverAdx_long': 'Filtered_EMA_Bullish_Crossover',
    'emaCrossoverAdx_short': 'Filtered_EMA_Bearish_Crossover',
    'bollingerBands_long': 'BB_Buy',
    'bollingerBands_short': 'BB_Sell',
    'bollingerBandsAdx_long': 'Filtered_BB_Buy',
    'bollingerBandsAdx_short': 'Filtered_BB_Sell',
    'emaStrategy_long': 'EMA_Strategy_Bullish',
    'emaStrategy_short': 'EMA_Strategy_Bearish',
    'parabolicTrader_long': 'Parabolic_Trader_Buy',
    'parabolicTrader_short': 'Parabolic_Trader_Sell',
    'hyperScalper_long': 'Hyper_Scalper_Bullish',
    'hyperScalper_short': 'Hyper_Scalper_Bearish',
    'bigExtender_long': 'Big_Extender_Bullish',
    'bigExtender_short': 'Big_Extender_Bearish',
    'bollingerStochastic_long': 'Bollinger_Stochastic_Bullish',
    'bollingerStochastic_short': 'Bollinger_Stochastic_Bearish',
    'powerScalper_long': 'Power_Scalper_Bullish',
    'powerScalper_short': 'Power_Scalper_Bearish',
    'triTrendmaster_long': 'Tri_Trendmaster_Buy',
    'tri_trendmaster_short': 'Tri_Trendmaster_Sell',
    'precisionPulse_long': 'Precision_Pulse_Buy',
    'precisionPulse_short': 'Precision_Pulse_Sell',
  };
  
 


  export const backtestStrategyTranslatorFinal = [
    { value: "macdCrossover", label: "MACD Crossover" },
    { value: "macdCrossoverAdx", label: "MACD Crossover + ADX" },
    { value: "rsi", label: "RSI" },
    { value: "rsiAdx", label: "RSI + ADX" },
    { value: "bollingerBands", label: "Bollinger Bands" },
    { value: "bollingerBandsAdx", label: "Bollinger Bands + ADX" },
    { value: "emaCrossover", label: "EMA Crossover" },
    { value: "emaCrossoverAdx", label: "EMA Crossover + ADX" },
    { value: "emaStrategy", label: "EMA Strategy" },
    { value: "parabolicTrader", label: "Parabolic Trader" },
    { value: "hyperScalper", label: "Hyper Scalper" },
    { value: "bigExtender", label: "Big Extender" },
    { value: "bollingerStochastic", label: "Bollinger Stochastic" },
    { value: "powerScalper", label: "Power Scalper" },
    { value: "triTrendmaster", label: "Tri Trendmaster" },
    { value: "precisionPulse", label: "Precision Pulse" }
  ];