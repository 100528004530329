import React, { useState, useEffect, useContext } from "react";
import { Snackbar, Grid, Typography, Dialog, DialogContent } from "@material-ui/core";
import { customFormat } from '../functions/customFormat';
import mainStyles from '../styles/mainstyles';
import { getPrice } from '../functions/cryptoPrices';
import { useParams } from 'react-router-dom';
import newUi from '../styles/newUi';
import { longColor, shortColor } from '../data/basics';
import { MinimizedTokenInfoWoChart } from "./others/minimizedTokenInfoWoChart";
import SingleTokenForecast from "../elements/singleTokenForecast";
import { AuthContext } from '../AuthContext';
import  RenderSignals  from "../elements/signalOverview";
import axios from "axios";
import LineChart from "../functions/lineChart";
import { getChartData } from "../functions/getChartData";
import BlogMessage from "../elements/blogMessages";
import VideoItem from "../elements/videoItem";
import { chartSignalDirections } from "../data/chartSignalDirections";

function Tokens() {
    const classes2 = mainStyles();
    const { auth } = useContext(AuthContext);
    const email = auth?.user?.email || '';
    const hasAccess = auth?.isAuthenticated;
    const [tokens, setTokens] = useState([]);
    const [forecastData, setForecastData] = useState([]);
    const { tokenSymbol } = useParams();
    const newUiClass = newUi();
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");  
    const [token, setToken] = useState('');
    const [tokenTV, setTokenTV] = useState();
    const [fullData, setFullData] = useState([]);
    const [allowed, setAllowed] = useState(false);
    const [latestSignals, setLatestSignals] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTokens, setFilteredTokens] = useState([]);
    const [blogMessages, setBlogMessages] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [videos, setVideos] = useState([]);
    const [allLoaded, setAllLoaded] = useState(false);  
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    useEffect(() => { window.scrollTo(0, 0); }, []);

    const handleCloseImageDialog = () => {
        setImageDialogOpen(false);
        setCurrentImage(null);
    };

    const handleOpenImageDialog = (image) => {
        setCurrentImage(image);
        setImageDialogOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpen(false);
    };

    function getLogo(data, symbol) {
        const tokenData = data.find(row => row.symbol === symbol);
        const logo = tokenData?.logo_url;
        return logo || '';
    }

    const updateDisplayPermission = () => {
        if (hasAccess || ["BTC", "ETH", "XRP", "LTC", "BNB"].includes(token)) { setAllowed(true); } 
        else {
            setAllowed(false);
            setErrorMessage("Sign-up or login to unlock all tokens — it's free!");
            setOpen(true);
        }
    };
    useEffect(() => { updateDisplayPermission();  }  , [token, hasAccess]);

    useEffect(() => {
        if (tokenSymbol) {
            const tokenTV1 = `BINANCE:${tokenSymbol}USDT`;
            setTokenTV(tokenTV1);
            addPrices(tokenSymbol);
            setToken(tokenSymbol);
            fetchAllSignals(tokenSymbol);
            fetchChartData(tokenSymbol);
            fetchBlogMessages(tokenSymbol).then(() => {
                setAllLoaded(true);  
            });
        }
    }, [tokenSymbol]);

    const handleTokenChange = (tokenValue) => {
        const tokenTV1 = `BINANCE:${tokenValue}USDT`;
        setTokenTV(tokenTV1);
        addPrices(tokenValue);
        setToken(tokenValue);
        fetchAllSignals(tokenValue);
        fetchBlogMessages(tokenValue);
        fetchChartData(tokenValue).then(() => {
            setAllLoaded(true);  
        });
        setSearchTerm('');  
    };

    useEffect(() => {
        if (searchTerm.length > 0) {
            const results = tokens.filter(token => token.symbol.toLowerCase().includes(searchTerm.toLowerCase()) );
            setFilteredTokens(results); } 
        else { setFilteredTokens([]); }
    }, [searchTerm, tokens]);
    
    useEffect(() => {
        fetch('/api/cryptologos')
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {  console.error(data.error); } 
                else { setTokens(data.map(item => ({ symbol: item.symbol, logo_url: item.logo_url }))); }
            });
    }, []);

    useEffect(() => {
        fetch('/api/forecast')
            .then((res) => res.json())
            .then((data) => {
                if (data.error) { console.error(data.error); } 
                else { setForecastData(data); }
            });
    }, []);

    const addPrices = async (symbol) => {
        try {
            const priceData = await getPrice([symbol]);
    
            if (priceData && priceData[symbol]) {
                const price = priceData[symbol].price;
                const pctChange = priceData[symbol].percentChange24h;
                const fullName = priceData[symbol].name;
                const addedPrice = { symbol: symbol, price: price, pctChange: pctChange, fullName: fullName };
                setFullData(addedPrice);
            } else {
                console.error(`Price data for ${symbol} is not available.`);
            }
        } catch (error) {
            console.error(`Error fetching price data for ${symbol}:`, error);
        }
    };
    

    const fetchBlogMessages = async (symbol) => {
        console.log(symbol)
        try {
            const response = await axios.get(`/api/blog-messages?symbol=${symbol}`);
            if (response.error) { console.error(response.error); } else { setBlogMessages(response.data); 
                console.log(response.data)
            }
        } catch (error) { console.error('Error fetching latest signals:', error); }
    };

    useEffect(() => { if (allLoaded) { fetchVideos(token); } }, [allLoaded]);

    const fetchChartData = async (symbol) => {
        const timeframe = '1D';
        getChartData(symbol, timeframe)
          .then(data => {  setChartData(data); })
          .catch(error => {  console.error(error); });
      };

    const fetchVideos = async (symbol) => {
        try {
            const response = await axios.get(`/api/videos/${symbol}`);
            if (response.error) { console.error(response.error); } else {
                setVideos(response.data); 
                setAllLoaded(false);
            }
        } catch (error) { console.error('Error fetching videos:', error); }
    };

    const fetchAllSignals = async (symbol) => {
        try {
            const [latestSignalsData, screenerSignalsData, supresSignalsData] = await Promise.all([
                fetch(`/api/get-latest-signals/${symbol}`).then(res => res.json()),
                fetch(`http://localhost:3000/api/get-chart-signals?symbol=${symbol}`).then(res => res.json()),
                fetch(`http://localhost:3000/api/get-supres-signals?symbol=${symbol}`).then(res => res.json())
            ]);
    
            const mergedSignals = [
                ...latestSignalsData.map(signal => ({
                    symbol: signal.symbol,
                    timeframe: signal.timeframe,
                    direction: signal.direction,
                    profit_potential: signal.profit_potential,
                    trade_signal: signal.trade_signal,
                    timestamp: new Date(new Date(signal.timestamp).getTime() - 2 * 60 * 60 * 1000), // Subtract 2 hours for UTC correction
                    type: 'ta', // Type attribute for latest signals
                    unique_link: signal.unique_link
                })),
                ...screenerSignalsData.map(signal => ({
                    symbol: signal.symbol,
                    timeframe: signal.timeframe,
                    trade_signal: signal.pattern_direction,
                    direction: chartSignalDirections[signal.pattern_direction] || 'N/A', // Map the direction using chartSignalDirections
                    timestamp: new Date(signal.timestamp), // Ensure timestamp is a Date object
                    type: 'chart', // Type attribute for screener signals
                })),
                ...supresSignalsData.map(signal => {
                    let tradeSignal, direction;
                    
                    if (signal.support) {
                        tradeSignal = 'Support Broken';
                        direction = 'Short';
                    } else if (signal.resistance) {
                        tradeSignal = 'Resistance Broken';
                        direction = 'Long';
                    }
    
                    return {
                        symbol: signal.symbol,
                        timeframe: signal.timeframe,
                        trade_signal: tradeSignal,
                        direction: direction,
                        timestamp: new Date(signal.alert_time), // Ensure timestamp is a Date object
                        type: 'supres', // Type attribute for support/resistance signals
                    };
                })
            ];
    
            // Sort by timestamp in descending order before filtering
            mergedSignals.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    
            // Filter out duplicate signals (occurring within 6 hours or with the exact same timestamp)
            const filteredSignals = mergedSignals.filter((signal, index, self) => {
                const previousSignal = self.find((s, idx) => 
                    s.trade_signal === signal.trade_signal && 
                    s.timeframe === signal.timeframe &&
                    (idx < index) &&
                    (Math.abs(signal.timestamp - s.timestamp) <= 6 * 60 * 60 * 1000)
                );
                return !previousSignal;
            });
    
            setLatestSignals(filteredSignals);
        } catch (error) {
            console.error('Error fetching signals:', error);
        }
    };
    
    
    const renderCombinedSignals = () => {
        const combinedSignals = [...latestSignals];
        combinedSignals.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by timestamp descending
    
        return <RenderSignals timeframe="Combined" signals={combinedSignals} />;
    };
    

    return (
        <div style={{ paddingLeft: '15px' }}>
            <Grid container spacing={2} className={newUiClass.headerGrid3} style={{ paddingRight: '0px',  }}>
                <Grid item xs={12} md={12} className={newUiClass.tokenSightSelect}>
                    <Typography className={newUiClass.componentTitle}>TokenSight</Typography>
                </Grid>
            </Grid>
            {(allowed) &&
            <div className={newUiClass.tokenSightOuter} style={{ marginRight: '15px' }}>
            <Grid container alignItems="stretch" spacing={5} style={{ paddingTop: '20px' }}>
                <Grid item xs={12} sm={12} md={4} alignItems="stretch" style={{overflow: 'auto', borderRadius: '5px', padding: '25px', maxHeight: '1000px'}}>
                    <Typography className={classes2.myBoxText} style={{ fontWeight: '400', paddingBottom: '10px', color: '#1a1a1a' }}>Please sign up or login to view all tokens — it's free!</Typography>
                </Grid>
            </Grid>
            </div>
            }
            {!allowed &&
                <div className={newUiClass.tokenSightOuter} style={{ marginRight: '15px' }}>
                    <Grid container alignItems="stretch" spacing={5} style={{ paddingTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={4} alignItems="stretch" style={{overflow: 'auto', background: 'white', borderRadius: '5px', padding: '25px', maxHeight: '1000px'}}>
                            <Typography className={classes2.myBoxText} style={{ fontWeight: '400', paddingBottom: '10px' }}>Current Data</Typography>
                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', flex: '1' }}>
                                    {(tokens.length > 0) && <img src={getLogo(tokens, token)} alt={token} style={{ width: '35px', marginRight: '10px', borderRadius: '50px' }} />}
                                    <Typography className={classes2.myBoxText} style={{ paddingLeft: '5px', fontSize: '1em' }}><b>{fullData.fullName} ({fullData.symbol})</b></Typography>
                                    <Typography className={classes2.myBoxTextThree} style={{ color: '#1a1a1a', paddingLeft: '25px', paddingTop: '15px', fontSize: '1em' }}><b>${customFormat(fullData.price)}</b>
                                        <span style={{ paddingLeft: '5px', color: fullData.pctChange < 0 ? shortColor : longColor, fontSize: '1em', fontWeight: '500' }}>({customFormat(fullData.pctChange)}%)</span>
                                    </Typography>
                                </div>
                                <MinimizedTokenInfoWoChart token={token} />
                                <div style={{height: '300px', paddingBottom: '30px', paddingTop: '30px'}}>
                                    <Typography className={classes2.myBoxText} style={{ fontWeight: '400', marginBottom: '10px' }}>Price Chart</Typography>     
                                    {(chartData != null) &&  <LineChart data={chartData}  /> }
                                </div>
                                <SingleTokenForecast rows={forecastData} symbol={token} indicator="ai" /> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} alignItems="stretch" style={{overflow: 'auto', background: 'white', borderLeft: '1px solid #EEEEEE', borderRadius: '5px', padding: '25px', marginTop: '5px', marginBottom: '25px', maxHeight: '1000px' }}>
                            <Typography className={classes2.myBoxText} style={{ fontWeight: '400' }}>Latest Signals</Typography>
                                {renderCombinedSignals()}  
                                <Typography className={classes2.myBoxText} style={{ fontWeight: '400', marginTop: '25px' }}>Latest Videos</Typography>
                                <Grid container>
                                {(videos.length > 0) &&
                            videos.slice(0, 4).map((video) => (
                                <Grid item xs={12} sm={12} md={12} alignItems="stretch" style={{background: 'white', maxHeight: '400px' }}>
                                    <VideoItem key={video.id} video={video} />
                                </Grid>
                            ))
                        }
                        </Grid>      
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} alignItems="stretch" style={{overflow: 'auto', background: 'white', borderLeft: '1px solid #EEEEEE', borderRadius: '5px', padding: '25px', maxHeight: '1000px' }}>
                            <Typography className={classes2.myBoxText} style={{ fontWeight: '400', marginBottom: '10px' }}>Curated Analysis</Typography>
                                    {blogMessages.length > 0 ? (
                                        blogMessages.map((message) => ( <BlogMessage key={message.id} message={message} handleOpenImageDialog={handleOpenImageDialog} /> ))
                                    ) : ( <Typography style={{color: '#1a1a1a', fontSize: '0.8em'}}><i>No analysis available yet — check back soon!</i></Typography> )}
                        </Grid>
                    </Grid>
                </div>
            }
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} message={errorMessage} />
            <Dialog open={imageDialogOpen} onClose={handleCloseImageDialog} maxWidth="md" fullWidth style={{backgroundColor: 'white'}}>
                
                <DialogContent style={{background: 'white'}}>
                    <img src={currentImage} alt="Attachment" style={{ width: '100%', height: 'auto' }} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Tokens;
