export const copyToClipboard = (text) => {

    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  

  export const shareOnX = (text, url) => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };
  