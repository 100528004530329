export const symbols = ['AAVE', 'ADA', 'ADX', 'AION', 'ALGO', 'ANC', 'ANT', 'ARDR', 'AST', 'ATM', 'ATOM', 'AVAX', 'BAND', 'BAT', 'BCC', 'BCH', 'BLZ', 'BNB', 'BNT', 'BTC', 'BTG', 'BTS', 'BTT', 'BUSD', 'CAKE', 'COMP', 'CRO', 'CTXC', 'CVC', 'DAI', 'DAR', 'DASH', 'DATA', 'DCR', 'DENT', 'DGB', 'DNT', 'DOGE', 'DOT', 'EGLD', 'ELF', 'ENJ', 'EOS', 'ETC', 'ETH', 'FIL', 'FTT', 'FUN', 'GAS', 'GNO', 'GRT', 'GTC', 'GTO', 'GXS', 'HBAR', 'ICX', 'IOST', 'IOTA', 'IOTX', 'KLAY', 'KMD', 'LEND', 'LINK', 'LOOM', 'LRC', 'LSK', 'LTC', 'LUNA', 'MANA', 'MATIC', 'MCO', 'NANO', 'NBT', 'NEBL', 'NEO', 'NMR', 'NPXS', 'NULS', 'OAX', 'OMG', 'ONT', 'POLY', 'POWR', 'QTUM', 'QUICK',  'REP', 'REQ', 'RLC', 'RUNE', 'SC', 'SHIB', 'SNT', 'SOL', 'SNX', 'STEEM', 'STORJ', 'STORM', 'STRAT', 'STX', 'SYS', 'THETA', 'TRX', 'TUSD', 'UNI', 'UST', 'UTK', 'VEN', 'VET', 'VIB', 'WAVES', 'WBTC', 'WTC', 'XEM', 'XLM', 'XMR', 'XRP', 'XTZ', 'XVG', 'XZC',  'ZEC', 'ZEN', 'ZIL'];
export const timeframes = ['1D', '1H', '15M'];
export const reduced_timeframes = ['1D', '1H', '15M'];
export const allowedTiersCourse = ["Course"];
export const allowedTiers3 = ["Crypto OS Premium Access"];
export const allowedTiers2 = ["Trader", "Pro", "Crypto OS Premium Access", "Crypto OS Trading Plan"];
export const allowedTiersTrading = ["Crypto OS Trading Plan"]
export const allowedTimeline = ["Crypto OS Free Plan", "Crypto OS Premium Access", "Crypto Trading Bot Plan"]
export const longColor = 'darkgreen'; 
export const shortColor = 'darkred'; 
export const standardOrgange = '#FFA500'
export const standardLightGreen = '#90EE90'
export const standardYellow = '#FFD700'
export const standardHighlight = '#24B9D5';