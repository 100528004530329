import React, { useState, memo } from 'react';
import { Grid, Avatar, Paper, Chip, Link, IconButton, Typography, Box } from '@mui/material';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import InfoIcon from '@mui/icons-material/Info';
import newUi from '../styles/newUi';
import { longColor, shortColor } from '../data/basics';
import { formatTimestampUTCOnlyHoursAndMinutes } from '../functions/customFormat';
import { customFormat, formatTimestamp } from '../functions/customFormat';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import mainStyles from '../styles/mainstyles';

export const ResultsTable = memo(({ signals }) => {


  const newUiClass = newUi();
  const classes2 = mainStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({});
 
  function determineDirection(signal) {
    let direction, chipColor;
    if(signal.support && (signal.resistance_alert_time == null || signal.support_alert_time > signal.resistance_alert_time)) {
      direction = "Support Broken";
      chipColor = shortColor;
    } else {
      direction = "Resistance Broken";
      chipColor = longColor;
    }
    return { direction, chipColor };
  }

    function alertText(row) {
        let text = '';
        if (row.support_alert_time) {
            if (row.resistance_alert_time == null || row.support_alert_time > row.resistance_alert_time) {
                text = "Support level at $" + customFormat(row.support) + " broken at " + formatTimestamp(row.support_alert_time) + " UTC.";
            }
        }
        else if (row.resistance_alert_time) {
            text = "Resistance level at $" + customFormat(row.resistance) + " broken " + formatTimestamp(row.resistance_alert_time) + " UTC.";        
        }
        return text;
    }


  return (
    <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
      
      {signals.map((row, index) => {
        const { direction, chipColor } = determineDirection(row); 
        const isFirstInRow = index % 3 === 0;
        const isThirdInRow = index % 3 === 2;
        const alertText1 = alertText(row);
        return (
          <>
          
          <Grid item xs={12} md={4} key={`${row.alert_time}-${row.symbol}`} style={{
            paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px', 
            paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
            alignItems: 'stretch'  
          }}>
            <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
              setExpandedRows(prevState => ({
              ...prevState,
              [row.symbol]: !prevState[row.symbol]
              }));
            }}>
              <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexShrink: 0 }}>
                      <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                    </div>
                    <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                      <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                        <b>{row.symbol}</b><br />
                        <span style={{ color:'#1a1a1a', fontSize:'0.8em' }}>
                          {row.fullName}
                        </span>
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                    <Chip label={direction} className={newUiClass.standardChipColorless} style={{backgroundColor: chipColor, color: 'white'}}/>
                    </Box>
                  </div>
                  {expandedRows[row.symbol] && (
                    <>
                    <div style={{marginTop: '25px'}}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Current Price (24h Change)</i></Typography>
                      <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(row.pctChange) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
                        <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(row.price)}</span>({customFormat(row.pctChange)}%)
                      </Typography>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Signal Details</i></Typography>
                        {alertText1}
                       </div>
                      <div style={{ marginTop: '25px' }}>
                        <MinimizedTokenInfo token={row.symbol} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                            Detected: {formatTimestampUTCOnlyHoursAndMinutes(row.created_at)} UTC
                          </Typography>
                          <div>
                            
                            <Link to={`/tokens/${row.symbol}`} style={{ textDecoration: 'none' }}>
                              <IconButton style={{ boxShadow: '#ebebeb 1px 1px 1px 1px' }} aria-label="account-balance">
                                <InfoIcon style={{ color: '#333' }} />
                              </IconButton>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>

                  )}
                </Paper>
              </Grid>
</>
            );
            
          })}
        </Grid>
        
  );
})
