
// Used in the Signals component


export const getCompleteSignal = (rowData) => {
  let attributes;
  try { attributes = JSON.parse(rowData.trade_signal);
  } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
  }

  for (const [key, value] of Object.entries(attributes)) {
      if (value) {
          const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()); 
          const labelValue = `${formattedKey}: ${value}`;
          return labelValue;
      }
  }

  return null;
};


export const basicSignalNames = {
  'tri_trendmaster': 'Tri Trendmaster', 
  'precision_pulse': 'Precision Pulse', 
  'power_scalper': 'Power Scalper',
  'bollinger_stochastic': 'Bollinger Stoch.',  
  'big_extender': 'Big Extender',  
  'hyper_scalper': 'Hyper Scalper',  
  'parabolic': 'Parabolic Trader',  
  'macd_value': 'MACD Crossover',  
  'rsi_value': 'RSI',  
  'ema_trend': 'EMA Crossover',  
  'bollinger': 'Bollinger Bands',  
}

export const getDirectionValue = (tradeSignalJson) => {
  let attributes;
  try {
      attributes = JSON.parse(tradeSignalJson);
  } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
  }

  for (const [key, value] of Object.entries(attributes)) {
      if (value) {
          const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
          const directionValue = directionMapping[formattedKey]?.[value] || value;
          if (directionValue) {
              return directionValue ;
          }
      }
  }

  return null;
};

export const directionMapping =  {
  "MACD Crossover": {
    "Bullish Crossover": "Long",
    "Bearish Crossover": "Short"
   },
  "RSI": {
    "Oversold": "Long",
    "Overbought": "Short"
  },
  "EMA Trend": {
    "Bullish Crossover": "Long",
    "Bearish Crossover": "Short"
  },
  "EMA Strategy": {
    "Bullish": "Long",
    "Bearish": "Short"
  },
  "Big Extender": {
    "Long": "Long",
    "Short": "Short"
  },
  "Hyper Scalper": {
    "Long": "Long",
    "Short": "Short"
  },
  "Parabolic Trader": {
    "Long": "Long",
    "Short": "Short"
  },
  "Power Scalper": {
    "Buy": "Long",
    "Sell": "Long"
  },
  "Bollinger": {
    "Buy": "Long",
    "Sell": "Short"
  },
  "Bollinger Stochastic": {
    "Bullish": "Long",
    "Bearish": "Short"
  },
  "Tri Trendmaster": {
    "Long": "Long",
    "Short": "Short"
  }
}



export const signalDirectionTimeline3 = {
  "MACD Bullish Crossover": "Long",
  "MACD Bearish Crossover": "Short",
  "RSI Overbought": "Short",
  "RSI Oversold": "Long",
  "EMA Bullish Crossover": "Long",
  "EMA Bearish Crossover": "Short",
  "Bollinger Bands Buy": "Long",
  "Bollinger Bands Sell": "Short",
  "EMA Strategy Buy": "Long",
  "EMA Strategy Sell": "Short",
  "Parabolic Trader Buy": "Long",
  "Parabolic Trader Sell": "Short",
  "Hyper Scalper Buy": "Long",
  "Hyper Scalper Sell": "Short",
  "Big Extender Buy": "Long",
  "Big Extender Sell": "Short",
  "Bollinger Stochastic Buy": "Long",
  "Bollinger Stochastic Sell": "Short",
  "Power Scalper Buy": "Long",
  "Power Scalper Sell": "Short",
  "Tri-Signal Trendmaster Buy": "Long",
  "Tri-Signal Trendmaster Sell": "Short"
}

export const signalMapping = {
    "MACD Bullish Crossover": "MACD_Bullish_Crossover",
    "MACD Bearish Crossover": "MACD_Bearish_Crossover",
    "RSI Overbought": "RSI_Above_70",
    "RSI Oversold": "RSI_Below_30",
    "EMA Bullish Crossover": "EMA_Bullish_Crossover",
    "EMA Bearish Crossover": "EMA_Bearish_Crossover",
    "Bollinger Buy": "BB_Buy",
    "Bollinger Sell": "BB_Sell",
    "EMA Strategy Bullish": "EMA_Strategy_Bullish",
    "EMA Strategy Bearish": "EMA_Strategy_Bearish",
    "Parabolic Trader Bullish": "Parabolic_Trader_Buy",
    "Parabolic Trader Bearish": "Parabolic_Trader_Sell",
    "Hyper Scalper Bullish": "Hyper_Scalper_Bullish",
    "Hyper Scalper Bearish": "Hyper_Scalper_Bearish",
    "Big Extender Bullish": "Big_Extender_Bullish",
    "Big Extender Bearish": "Big_Extender_Bearish",
    "Bollinger Stochastic Bullish": "Bollinger_Stochastic_Bullish",
    "Bollinger Stochastic Bearish": "Bollinger_Stochastic_Bearish",
    "Power Scalper Bullish": "Power_Scalper_Bullish",
    "Power Scalper Bearish": "Power_Scalper_Bearish"
  };



  export const signalMapping2 = {
    "MACD_Bullish_Crossover": "MACD Crossover",
    "MACD_Bearish_Crossover": "MACD Crossover",
    "RSI_Below_30": "RSI",
    "RSI_Above_70": "RSI",
    "EMA_Bullish_Crossover": "EMA Crossover",
    "EMA_Bearish_Crossover": "EMA Crossover",
    "BB_Buy": "Bollinger Bands",
    "BB_Sell": "Bollinger Bands",
    "EMA_Strategy_Bullish": "EMA Strategy",
    "EMA_Strategy_Bearish": "EMA Strategy",
    "Parabolic_Trader_Bullish": "Parabolic Trader",
    "Parabolic_Trader_Bearish": "Parabolic Trader",
    "Hyper_Scalper_Bullish": "Hyper Scalper",
    "Hyper_Scalper_Bearish": "Hyper Scalper",
    "Big_Extender_Bullish": "Big Extender",
    "Big_Extender_Bearish": "Big Extender",
    "Bollinger_Stochastic_Bullish": "Bollinger + Stochastic",
    "Bollinger_Stochastic_Bearish": "Bo llinger +Stochastic",
    "Power_Scalper_Bullish": "Power Scalper",
    "Power_Scalper_Bearish": "Power Scalper"
  };

  