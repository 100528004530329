import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Box, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import mainStyles from '../../styles/mainstyles';
import newUi from '../../styles/newUi';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, defs, linearGradient, stop } from 'recharts';


export const SignalCount = () => {
  const [signalCount, setSignalCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSignalCount = async () => {
      try {
        setError('');
        setLoading(true);
        const response = await axios.get('/api/get-today-signal-counts');
        setSignalCount(response.data);
        console.log(response.data)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSignalCount();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

    return (
        <>

            <i>1D Signals: <b>{signalCount['1D']}</b></i> 
            <span style={{paddingLeft: '3px', paddingRight: '3px'}}> //</span>
            <i>4H Signals: <b>{signalCount['4H']}</b></i>
            <span style={{paddingLeft: '3px', paddingRight: '3px'}}> //</span>
            <i>1H Signals: <b>{signalCount['1H']}</b></i>
        </>
    );    
};