import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import newUi from '../styles/newUi';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import { Snackbar, Grid } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import Logo from "../images/logo.png";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { getMessaging, getToken } from "firebase/messaging";
// import { messaging } from "../firebase"; // Import the initialized messaging instance

/*
const requestFirebaseNotificationPermission = async () => {
  if (typeof Notification === 'undefined') {
    console.error('This browser does not support notifications.');
    return;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      try {
        const token = await getToken(messaging, { vapidKey: 'BHEhEo9NkGcJ2ROoeSkuvFsPJZKpZLNP2U8JcPQ7o0F8IBiYsIEEcoT_bV0hfm7P5lIlcy09JakSb35donjEER8' });
        console.log('Notification permission granted.');
        console.log('FCM Token:', token);
        // Send token to server to store it for later use
        // Example: axios.post('/api/save-token', { token });
        return token;
      } catch (error) {
        console.error('An error occurred while getting the token:', error);
      }
    } else {
      console.error('Notification permission not granted.');
    }
  } catch (error) {
    console.error('An error occurred while requesting notification permission:', error);
  }
};
*/

const AuthPage = () => {
  const newUiClass = newUi();
  const { auth, logout, login } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");  
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpen(false);
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    const url = isLogin ? 'https://crypto-os.xyz/api/login' : 'https://crypto-os.xyz/api/register';
    try {
      const response = await axios.post(url, { email, password });
      if (isLogin && response.data.message === 'Login successful') {
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);
        login(accessToken, { email });
        // await requestFirebaseNotificationPermission(); // Request notification permission
        navigate('/newStart'); // Redirect to the dashboard or another component
      }
      if(response.data === "Confirmation email sent.") {
        setErrorMessage("We sent you a confirmation email. Please click the link to activate your user.");
      } else {
        setErrorMessage(response.data.message);
      }
      setOpen(true);
    } catch (error) {
      setErrorMessage('Error: ' + (error.response ? error.response.data.error : 'Unknown error'));
      setOpen(true);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const { credential } = response;
      const { data } = await axios.post('https://crypto-os.xyz/api/google-login', { tokenId: credential });
      const { accessToken, email } = data;
      localStorage.setItem('accessToken', accessToken);
      login(accessToken, { email });
      // await requestFirebaseNotificationPermission(); // Request notification permission
      navigate('/newStart');
    } catch (error) {
      setErrorMessage('Google login failed. Please try again.');
      setOpen(true);
    }
  };

  const handleGoogleFailure = (error) => {
    setErrorMessage('Google login failed. Please try again.');
    setOpen(true);
  };

  // useEffect(() => {
  //   if (Notification.permission === 'default') {
  //     requestFirebaseNotificationPermission();
  //   }
  // }, []);

  return (
    <GoogleOAuthProvider clientId="341841920004-ej6aidfva79po8ph25d0leb8gn03ltkc.apps.googleusercontent.com">
      <Grid container spacing={2} className={newUiClass.headerGrid} style={{ marginBottom: '20px', marginTop: '80px' }}>
        <Grid md={12} xs={12}>
          <Alert className={newUiClass.infoBar} style={{ marginTop: '100px' }} severity="warning">
            Due to recent changes in our user handling system, users who previously signed up through Memberful will need to sign up again. We apologize for any inconvenience this may cause.
          </Alert>
        </Grid>
      </Grid>
      <div style={styles.container}>
        <div style={styles.formContainer}>
          <img src={Logo} style={{ width: '90px' }} alt="Logo" />
          <p style={{ color: '#1a1a1a', fontWeight: '900', fontSize: '1.2em' }}>Log in to Crypto OS</p>
          <form onSubmit={handleAuth}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
            <button type="submit" style={styles.button}>
              {isLogin ? 'Login' : 'Sign Up'}
            </button>
          </form>
          <br />
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            useOneTap
          />
          <br />
          <button onClick={() => setIsLogin(!isLogin)} style={styles.toggleButton}>
            {isLogin ? 'Sign Up With Your Email Instead - It is free!' : 'Switch to Login'}
          </button>
          {!isLogin && (
            <div style={{ color: '#1a1a1a', marginTop: '40px' }}>
              <i>
                The usage of Crypto OS is free of charge. By signing up, you acknowledge that you will receive emails
                containing data and information from our platform. We will not send advertisements or use your email
                address for any other purposes. You can opt out at any time.
              </i>
            </div>
          )}
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} message={errorMessage} />
    </GoogleOAuthProvider>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    backgroundColor: '#f0f0f0',
  },
  formContainer: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    width: '700px',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '0.5rem',
    marginBottom: '1rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    width: '100%',
    padding: '0.5rem',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  toggleButton: {
    marginTop: '1rem',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#007BFF',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default AuthPage;
