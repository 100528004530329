import React from 'react';
import { Box, Typography, Grid, Link, IconButton, autocompleteClasses, Tooltip } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import GroupIcon from '@mui/icons-material/Group'; // Assuming you use this for the community icon
import logo from '../images/cryptoOsLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
/// <Grid container spacing={2} justifyContent="space-between">


const Footer = () => {
  return (
    <>
   
    <Grid container spacing={2} style={{backgroundColor:'#070F26', paddingLeft: '20px', paddingTop: '30px', paddingBottom: '20px'}}>
        <Grid xs={0} md={1} />
        <Grid xs={12} md={2}>
            <Box sx={{ color: 'white', p: 3 }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <img src={logo} style={{width: '50px', height: 'auto'}}/>
                    <div style={{marginBottom: '20px'}}>
                        <Tooltip title="Follow us on X">
                            <IconButton color="inherit" href="https://twitter.com/Crypto__OS">
                                <TwitterIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Follow us on YouTube">
                            <a href="https://www.youtube.com/@crypto__os1" >
                                <IconButton style={{color: '#fff'}} ><FontAwesomeIcon icon={faYoutube} style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }} /></IconButton>            
                            </a>
                        </Tooltip>
                        <Tooltip title="Join us on Reddit">
                            <a href="https://www.reddit.com/r/cryptoos/new/" >
                                <IconButton style={{color: '#fff'}} ><FontAwesomeIcon icon={faReddit} style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }} /></IconButton>            
                            </a>
                        </Tooltip>
                        <Tooltip title="Tune into our Podcast">
                            <a href="https://open.spotify.com/show/26aN9mmTu1t2II1SsakfOo?si=d0fabbcac4e94e9a" >
                                <IconButton style={{color: '#fff'}} ><FontAwesomeIcon icon={faSpotify} style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }} /></IconButton>            
                            </a>
                        </Tooltip>
                        <Tooltip title="Join our Discord">
                            <a href="https://discord.gg/YChmcdyxkJ" >
                                <IconButton style={{color: '#fff'}} ><FontAwesomeIcon icon={faDiscord} style={{ color: '#fff', display: 'flex', alignItems: 'center', textDecoration: 'none' }} /></IconButton>            
                            </a>
                        </Tooltip>
                        <br/>
                    </div>
                </Typography>
                <Typography variant="body2" component="p">
                    © 2024 Crypto OS
                </Typography>
            </Box>            
        </Grid>
        <Grid xs={12} md={2}>
            <Box sx={{ color: 'white', p: 3 }}>
                <Typography variant="button" display="block" style={{marginBottom: '10px', fontWeight: '600', fontSize:'1.1em'}}>Company</Typography>
                <Typography style={{fontSize: '1em'}}><b>Crypto OS is registered in Germany</b></Typography>
                <Typography style={{fontSize: '1em'}}>Schulstrasse 13</Typography>
                <Typography style={{fontSize: '1em'}}>64750 Luetzelbach</Typography>
                <Typography style={{fontSize: '1em'}}>GERMANY</Typography><br/>
            </Box>
        </Grid>
        <Grid xs={12} md={2}>
            <Box sx={{ color: 'white', p: 3 }}>
            <Typography variant="button" display="block" style={{marginBottom: '10px', fontWeight: '600', fontSize:'1.1em'}}>About</Typography>
                <Link href="https://crypto-os.pro/privacy-policy/" underline="none" color="inherit" style={{lineHeight: '2em'}} >Privacy Policy</Link><br/>
                <Link href="https://crypto-os.pro/imprint/" underline="none" color="inherit" style={{lineHeight: '2em'}}>Imprint</Link><br/>
                
            </Box>
        </Grid>
        <Grid xs={12} md={2}>
            <Box sx={{ color: 'white', p: 3 }}>
            <Typography variant="button" display="block" style={{marginBottom: '10px', fontWeight: '600', fontSize:'1.1em'}}>Products</Typography>
                <Link href="http://crypto-os.pro/signals" underline="none" style={{lineHeight: '2em'}} color="inherit">Trading Signals</Link><br/>
                <Link href="http://crypto-os.pro/bots" underline="none" style={{lineHeight: '2em'}} color="inherit">Trading Bots</Link><br/>
                <Link href="http://crypto-os.pro/forecast" underline="none" style={{lineHeight: '2em'}} color="inherit">AI Forecasts</Link><br/>
                <Link href="http://crypto-os.pro/supportResistance" underline="none" style={{lineHeight: '2em'}} color="inherit">Support & Resistance</Link><br/>
                
            </Box>
        </Grid>
        <Grid xs={12} md={2}>
            <Box sx={{ color: 'white', p: 3 }}>
            <Typography variant="button" display="block" style={{marginBottom: '10px', fontWeight: '600', fontSize:'1.1em'}}>Support</Typography>
                <Link href="https://docs.crypto-os.pro/" underline="none" color="inherit" style={{lineHeight: '2em'}}>Documentation</Link><br/>
                <a href="mailto:help@crypto-os.pro" underline="none" color="inherit" style={{lineHeight: '2em'}}>Submit a Request</a>   <br/>

            </Box>
        </Grid>
        <Grid xs={0} md={1} />
        </Grid>
        <Grid container spacing={2} style={{backgroundColor:'#070F26', paddingLeft: '20px', paddingTop: '30px', paddingBottom: '20px'}}>    
        <Grid xs={0} md={1} />
        <Grid xs={12} md={10}>
        <Typography style={{fontSize: '1em', fontWeight: '700', padding: '20px', paddingBottom: '0px'}}>Risk Warning</Typography>
<Typography style={{fontSize: '0.8em', padding: '20px', paddingTop: '0px'}}><i>Trading cryptocurrency is risky and may not be suitable for all users. Users must consider their investment objectives, experience, and risk tolerance. Digital asset trading has various risks, including price fluctuations, transaction risks, policy and regulatory risks, liquidity risks, and security risks. Users should seek legal or professional advice before engaging transactions. Crypto OS is not responsible for any user losses and does not provide investment or financial advice. Moreover, Crypto OS does not take any responsibility of the correctnes or accuracy of the displayed data. By using Crypto OS services, users agree to the terms and conditions in the Crypto OS Terms, Risk Warning, and Privacy.</i></Typography>
        </Grid>
        <Grid xs={0} md={1} />
      </Grid>
      <div style={{padding: '30px', backgroundColor:'#070F26'}}></div>
      </>
  );
};

export default Footer;
