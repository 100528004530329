import jwtDecode from 'jwt-decode';

// Web-based: Using localStorage
  
  export const getEmail = () => {
    try {
      const token = localStorage.getItem("jwtToken");
      if (!token) return null;
      const decoded = jwtDecode(token);
      let email = decoded.email
      return email;
    } catch (err) {
      console.error("Error getting user email from token:", err);
      return null;
    }
  };