// src/elements/analysisCharts.js


import ChartDataLabels from 'chartjs-plugin-datalabels';
import { customFormat } from '../functions/customFormat';
import React, { useMemo, useEffect, useRef } from 'react';
import { Bubble, Line } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, Filler } from 'chart.js';


ChartJS.register(Tooltip, Legend, Filler, ChartDataLabels);

const getGradient = (ctx, chartArea) => {
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradient.addColorStop(0, 'rgba(0, 123, 255, 0.3)'); 
  gradient.addColorStop(1, 'rgba(0, 123, 255, 0)');  
  return gradient;
};

export function LineChart({ data = [] }) {
  const chartRef = useRef(null);
  const chartData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    return {
      labels: data.map(item => new Date(item.date)),
      datasets: [{
        label: 'RSI',
        data: data.map(item => item.rsi),
        fill: true,
        borderColor: 'rgba(0, 123, 255, 1)',
        tension: 0.1
      }]
    };
  }, [data]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `RSI: ${context.raw}`;
          }
        }
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          tooltipFormat: 'MMM yyyy',
          displayFormats: {
            month: 'MMM yyyy',
          },
        },
        title: {
          display: true,
          text: 'Date',
        }
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: 'RSI',
        }
      },
    },
  };

  useEffect(() => {
    // Ensure the component is mounted and the chart has been rendered
    if (chartRef.current && chartRef.current.chartInstance) {
      const chart = chartRef.current; // For Chart.js 3.x, it's attached directly
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;

      if (chartArea) {
        const gradient = getGradient(ctx, chartArea);
        chart.data.datasets[0].backgroundColor = gradient;
        chart.update(); // Update the chart
      }
    }
  }, [chartRef, chartData]);

  return (
    <div>
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  );
}

export function RsiBubbleChart ({ data }) {
    const rsiData = data.map(item => ({
      x: item.date,
      y: item.rsi,
      r: 10,
      symbol: item.symbol
    }));
  
    const chartData = {
      datasets: [{
        label: 'RSI',
        data: rsiData,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      }]
    };
  
    const options = {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value, context) => context.dataset.data[context.dataIndex].symbol,
          align: 'end',
          anchor: 'end',
          offset: 4,
          font: {
            weight: 'bold',
            size: 10,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const dataPoint = context.raw;
              return `${dataPoint.symbol}: RSI ${customFormat(dataPoint.y)}`;
            }
          }
        }
      },
      scales: {
        x: {
          type: 'category',
          labels: data.map(item => item.date),
          display: false,
          title: {
            display: false
          }
        },
        y: {
          beginAtZero: true,
          max: 100,
          title: {
            display: true,
            text: 'RSI'
          }
        },
      },
    };
  
    return (
      <div>
        <Bubble data={chartData} options={options} />
      </div>
    );
  }
  const drawBackgroundPlugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      const yScale = chart.scales['y'];
      const zeroLine = yScale.getPixelForValue(0);
  
      ctx.save();
  
      // Draw upper half background
      ctx.fillStyle = 'rgba(144, 238, 144, 0.1)'; // Light green
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, zeroLine - chartArea.top);
  
      // Draw lower half background
      ctx.fillStyle = 'rgba(255, 182, 193, 0.1)'; // Light red
      ctx.fillRect(chartArea.left, zeroLine, chartArea.right - chartArea.left, chartArea.bottom - zeroLine);
  
      ctx.restore();
    }
  };
  
  export function Ema200BubbleChart({ data, currentPrices }) {
    
  
    const bubbleData = data.map(item => {
      const currentPrice = currentPrices[item.symbol + 'USDT'];
      const ema200 = item.ema_200;

      const percentageDifference = ((currentPrice - ema200) / ema200) * 100;
      
      const color = percentageDifference < 0 ? 'rgba(255, 99, 132, 0.5)' : 'rgba(75, 192, 192, 0.4)';
  
      return {
        x: item.symbol,
        y: percentageDifference,
        r: 10, // Bubble size
        backgroundColor: color,
      };
    });
  
    const chartData = {
      datasets: [{
        label: 'EMA 200',
        data: bubbleData,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: bubbleData.map(bubble => bubble.backgroundColor),
        borderWidth: 1,
      }]
    };
  
    const options = {
      plugins: {
        customCanvasBackgroundColor: {
          color: 'white' // Ensure the plugin is correctly configured
        },
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value, context) => context.dataset.data[context.dataIndex].x,
          align: 'end',
          anchor: 'end',
          offset: 4,
          font: {
            weight: 'bold',
            size: 10,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const dataPoint = context.raw;
              return `${dataPoint.x}: ${dataPoint.y.toFixed(2)}%`;
            }
          }
        }
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: false,
          },
          grid: {
            display: false, // Remove vertical grid lines
          },
          ticks: {
            display: false, // Remove symbols below the x-axis
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Percentage Difference (%)',
          },
        },
      },
    };
  
    return (
      <div>
        <Bubble data={chartData} options={options} plugins={[drawBackgroundPlugin]} />
      </div>
    );
  }