export function customFormat(num) {
  num = parseFloat(num);
  if (isNaN(num)) {
      return ""; // if num can't be converted to a number, return an empty string
  }

  let isNegative = num < 0;
  num = Math.abs(num); // convert num to its absolute value for easier comparison

  let formattedNum;
  if (num === 0) {
      formattedNum = "";
  } else if (num > 999) {
        formattedNum = num.toFixed(0); // No decimals for numbers > 999
  } else if (num >= 1) {
      formattedNum = num.toFixed(2);
  } else if (0.01 <= num && num < 1) {
      formattedNum = num.toFixed(3);
  } else if (0.0001 <= num && num < 0.01) {
      formattedNum = num.toFixed(4);
  } else {
      formattedNum = num.toExponential(2);
  }

  return isNegative ? '-' + formattedNum : formattedNum; // append the negative sign if num was negative
}

export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Extract year, month, day, hours, minutes, and seconds in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // months are 0-based in JS
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Return the formatted date
    return `${day}-${month}-${year} ${hours}:${minutes}`;
}


export function formatTimestampUTC(timestamp) {
    const date = new Date(timestamp);

    // Extract year, month, day, hours, minutes, and seconds in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // months are 0-based in JS
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Return the formatted date in UTC
    return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export function formatTimestampUTCOnlyHoursAndMinutes(timestamp) {
    const date = new Date(timestamp);

    // Extract year, month, day, hours, minutes, and seconds in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // months are 0-based in JS
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Return the formatted date in UTC
    return `${hours}:${minutes}`;
}


export function formatTimestampShort(timestamp) {
    const date = new Date(timestamp);

    // Extract year, month, day, hours, minutes, and seconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-based in JS
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Return the formatted date
    return `${day}/${month} ${hours}:${minutes}`;
}


export function adjustDecimalPlaces(number, decimalPlaces) {
    if (isNaN(number) || !Number.isInteger(decimalPlaces) || decimalPlaces < 0) { throw new Error('Invalid input'); }
    const numberAsString = number.toString();
    const decimalPointIndex = numberAsString.indexOf('.');
    if (decimalPointIndex === -1 || (numberAsString.length - decimalPointIndex - 1) <= decimalPlaces) { return number; }
    return Number(number.toFixed(decimalPlaces));
}