import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, Paper } from '@mui/material';
import newUi from '../../styles/newUi';
import DOMPurify from 'dompurify';
import LikeButton from '../../functions/likeButton';

const TokenBlog = () => {
  const { symbol } = useParams();
  const [messages, setMessages] = useState([]);
  const [expandedMessage, setExpandedMessage] = useState(null);
  const newUiClass = newUi();

  useEffect(() => {
    fetchMessages();
  }, [symbol]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`/api/blog-messages?symbol=${symbol}`);
      
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const handleExpandClick = (messageId) => {
    setExpandedMessage((prev) => (prev === messageId ? null : messageId));
  };

  return (
    <Container className={newUiClass.blogContainer}>
      <Typography variant="h4" className={newUiClass.componentTitle}>
        {symbol} Trade Setups
      </Typography><br />
      {messages.map((message) => {
        const isExpanded = expandedMessage === message.id;
        const firstLine = message.content.split('\n')[0];
        return (
          <Paper key={message.id} className={newUiClass.messageBox} onClick={() => handleExpandClick(message.id)}>
            <Typography variant="body2" className={newUiClass.timestamp}>
              {message.username} — {new Date(message.created_at).toLocaleString()}
            </Typography>
            <Typography
              variant="body1"
              className={newUiClass.content}
              dangerouslySetInnerHTML={createMarkup(isExpanded ? message.content : firstLine)}
            />
            {isExpanded && message.attachment_file_path && (
              message.attachment_file_type === 'jpg' ? (
                <img
                  src={message.attachment_file_path}
                  alt="attachment"
                  className={newUiClass.image}
                  style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}
                />
              ) : (
                <video
                  src={message.attachment_file_path}
                  controls
                  className={newUiClass.video}
                />
              )
            )}
            {isExpanded && (
              <div className={newUiClass.likeButtonContainer}>
                {/* <LikeButton userEmail={currentUserName} contentId={message.id} type="content_post" content={message.content} /> */}
              </div>
            )}
          </Paper>
        );
      })}
    </Container>
  );
};

export default TokenBlog;
