import { makeStyles } from '@material-ui/core/styles';
import { autocompleteClasses } from '@mui/material';

import styled from '@emotion/styled';
import { List as MuiList } from '@mui/material';
import { longColor, shortColor, standardHighlight } from '../data/basics';


const mainStyles = makeStyles((theme) => ({
    
    
    myNewBox: {
        
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        padding: '25px !important',
        borderRadius: '8px',
        marginLeft: '13px',
        marginRight: '13px',
        marginTop: '10px'
    },
    myBox: {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '25px !important',
        borderRadius: '8px'
    },
    newsletterPopup: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1000',
        width: '80%',
        maxWidth: '500px',
        padding: '30px',
        background: 'white',
        borderRadius: '8px',
        background: '#efefef',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
      },
    myBox2: {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95) !important',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '40px !important',
        paddingLeft: '25px !important',
        borderRadius: '8px'
    },
    myBox3: {
        
        transition: 'box-shadow 0.3s ease',
        backgroundColor: '#333 !important',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '15px !important',
        paddingLeft: '25px !important',
        borderRadius: '5px'
    },
    myBoxNew: {
        boxShadow: 'rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 0px 1px 2px !important',
        //transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(255, 255, 255) !important',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px !important',
        borderRadius: '5px'
    },
    myBoxBacktest: {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(255,255,255,0.04) !important',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px !important',
        borderRadius: '5px',
        paddingTop: '20px', 
        paddingBottom: '20px'
    },
    myBoxBacktestMore: {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px !important',
        borderRadius: '5px',
        paddingTop: '20px', 
        paddingBottom: '20px'
    },
    backtestOuterDiv: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        width: '100%'
    },
    backtestInnerDiv: {
        flex: 1, 
        marginLeft: "10px", 
        marginRight: "10px"
    },
    backtestTypography: {
        marginRight: '20px',  
        textAlign: 'left', 
        paddingLeft: '20px', 
        fontSize: '1em',
        marginBottom: '10px !important'
    },
    backtestLowerSpan: {
        fontSize: '1.5em', 
        fontWeight: '800',
        marginBottom: '20px !important', 
        paddingBottom: '20px'
    },
    backtestImage: {
        width: '100%',
         marginLeft: 'auto', 
         marginRight: 'auto', 
         marginTop: '15px'
    },
    adBox: {
        position: 'relative', 
        //background: 'linear-gradient(180deg, #5F72BE 20%, #9921E8 100%)', 
        background: 'rgba(255,255,255,0.04)',
        color: '#fff', 
        paddingTop: '20px', 
        paddingBottom: '20px', 
        paddingLeft: '15px', 
        paddingRight: '15px', // Adjusted for close button space
        marginLeft: 'auto', 
        marginRight: 'auto', 
        marginTop: '25px', 
        marginBottom: '15px', 
        borderRadius: '7px',
        textAlign: 'left' // Center the text if not already
    },
    adBoxButton: {
        position: 'absolute',
        top: '10px',
        right: '0px',
        background: 'transparent',
        border: 'none',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '20px',  
    },
    adBoxTitle: {
        fontSize: '1.4em',
        fontWeight: '800',
        marginBottom: '10px',
        width: '90%'
    },
    adBoxDescription: {
        fontSize: '1.1em',
        fontWeight: '400',
        marginBottom: '20px',
        width: '95%'
    },
    
    myBoxNew1: {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95) !important',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px !important',
        maxWidth: '96%',
        borderRadius: '5px'
    },
    headerBox: {
        backgroundColor: '#1E1F24', 
        padding: '20px !important', 
        paddingTop: '30px !important',
        borderRadius: '10px', 
        marginBottom: '40px !important',
        marginLeft: '10px', 
        marginRight: '10px'
    },
    headerTitle: {
        textAlign: 'left',
        fontWeight: '800',
        fontSize: '1.6em',
        paddingBottom: '5px'
    },
    headerSubtitle: {
        textAlign: 'left !important',
        fontSize: '1.1em',
        fontWeight: '400',
        paddingBottom: '20px'
    },
    detectedText: {
        fontSize: '0.8em',
        color: '#1A1A1A',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    myBoxTitle: {
        marginRight: theme.spacing(1),  
    },
    tokenCardValue: {
        marginLeft: theme.spacing(1),
    },
    assistantSubmitButton: {
        backgroundColor: '#1D6F90',
        paddingLeft: '25px',
        paddingRight: '25px',
        padding: '10px',
        marginTop: '30px',
        marginBottom: '20px',
        color: 'white'
    },
    assistantCenteredContent: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // This pushes children to start and end of container
        height: '100%', 
        '& > *': { // This will center-align all direct children of DialogContent
          margin: theme.spacing(1, 'auto'), // Adjust spacing as needed
        },
      },
    
    cardLogo: {
        width: '50px',
        position: 'absolute',
        top: '-25px',
        left: '10%',
        marginLeft: '20px',
        transform: 'translateX(-50%)',
        zIndex: '2',
    },
    
    menuButton: {
        color: 'white', 
        marginLeft: 'auto', 
        marginRight: 'auto',
        position: 'absolute', 
        top: '25%', 
        border: '1px solid rgb(255,255,255,0.06)',
        transform: 'translateY(-50%)', 
        backgroundColor:'rgba(27,37,47) !important',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70px', 
        width: '60px' 

    },
    bottomNavigation: {
        position: 'fixed', 
        height: '7%', 
        paddingBottom: '15px', 
        bottom: '0', 
        width: '100%', 
        zIndex: '1000', 
        backgroundColor:'rgba(27,37,47,0.95) !important'
    },
    knowledgeBaseButton1: {
        border: '1px solid white',
        marginBottom: '22px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '10px !important',
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
    },
    knowledgeBaseReadMoreButton: {
        marginTop: '20px',
        border: '1px solid #5B798E',
        background: '#5B798E',
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    
    neutralChip: {
        border: '1px dotted #baa !important',  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '24px',
        borderRadius: '5px !important',
        marginBottom: '10px',
        width: '80px'
    },
    neutralBotChip: {
        color: 'white',
        height: '25px !important', 
        marginRight:'5px', 
        marginTop: '10px',
        borderRadius: '5px !important',
        background: '#333'
    },
    neutralChip1: {
        border: '1px dotted #baa !important',  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '24px',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '120px'
    },
    blueChip: {
        backgroundColor: '#5B798E !important',  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '24px',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '120px'
    },
    buyChip: {
        backgroundColor: `${longColor} !important`,  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '25px !important',
                borderRadius: '5px',
        marginBottom: '10px',
        width: '120px'
    },
    longChip: {
        backgroundColor: `${longColor} !important` ,  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '25px !important',
                borderRadius: '5px',
        marginBottom: '10px'
    },
    bullishChip: {
        marginRight: theme.spacing(1),
        height: '25px !important',
                borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: `${longColor} !important`,
        color: 'white'
    },
    switchAssistantBase: {
        color: 'grey', // Color when the switch is off
        '&$checked': {
          color: 'green !important', // Color when the switch is on
        },
        '&$checked + $track': {
          backgroundColor: 'green', // Background color when the switch is on
        },
      },
      checked: {},
      track: {},
    bearishChip: {
        marginRight: theme.spacing(1),
        height: '25px !important',
                borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: `${shortColor} !important`,
        color: 'white'
    },
    sellChip: {
        backgroundColor: `${shortColor} !important`,  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '25px !important',
                borderRadius: '5px',
        marginBottom: '10px',
        width: '120px'
    },
    neutral1Chip: {
        backgroundColor: `${standardHighlight} !important`,  
        color: 'white',
        borderRadius: '5px',
        height: '25px !important',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '120px'
    },
    overallChip: {
        border: '1px dotted #baa !important',  
        color: 'white',
        marginRight: theme.spacing(1),
        height: '48px',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '180px'
    },
    trendDownChip: {
        background: `${shortColor} !important`,
        color: 'white !important',
        marginRight: theme.spacing(1),
        height: '25px !important',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '150px'
    },
    trendDownChip1: {
        background: `${shortColor} !important`,
        color: 'white !important',
        borderRadius: '5px !important',
        height: '25px !important',
        marginRight:'5px', 
        marginTop: '10px'
    },
    trendUpChip: {
        background: `${longColor} !important`,
        color: 'white',
        marginRight: theme.spacing(1),
        height: '25px !important',
        marginRight:'5px', 
    },
    trendUpChip1: {
        background: `${longColor} !important`,
        color: 'white !important',
        borderRadius: '5px !important',
        height: '25px !important',
        marginRight:'5px', 
        marginTop: '10px'
    },
    noShow: {
        display: 'none'
    },
    tokenCardValue: {
        marginLeft: theme.spacing(1),
        textAlign: 'right'
    },
    myBoxTitle: {
        color: '#A1A1A6',
        fontWeight: '800',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    myBoxMainTitle: {
        color: '#ffffff',
        fontWeight: '800',
        fontSize: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    myBoxText: {
        color: '#1A1A1A !important',
        fontWeight: '600 !important'
    },
    myBoxBold: {
        color: '#ffffff',
        fontWeight: '900',
        fontSize: '4rem',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '5%',
        paddingBottom: '5%',
        flexGrow: 1
    },
    myBoxTextTwo: {
        color: '#A1A1A6 !important',
        fontSize: '0.8rem',
        paddingRight: '10%',
        paddingTop: '20px'
    },
    myBoxTextThree: {
        
        fontSize: '0.9rem',
        paddingRight: '10%',
        paddingTop: '5px',
        paddingBottom: '15px'
    },
    myBoxTextThreeAlt: {
        
        fontSize: '0.9rem',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '15px'
    },
    myBoxTextFour: {
        color: '#fff !important',
        fontSize: '0.9rem',
        paddingRight: '10%',
        paddingBottom: '15px'
    },
    positiveChange: {
        color: 'green',
        fontWeight: '600',
        fontSize: '0.8rem',
        paddingLeft: '8px'
    },
    negativeChange: {
        color: 'red',
        fontWeight: '600',
        fontSize: '0.8rem',
        paddingLeft: '8px'
    },
    leftCard: {
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        color: '#EDEDED',
        padding: '3%'
    },
    leftCard2: {
        
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        color: '#EDEDED',
        padding: '3%'
    },
    rightCard: {
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#EDEDED',
        padding: '3%'
    },
    imageInLeftCard: {
        width: '50%',
        height: 'auto'
    },
    textBlock: {
        marginBottom: theme.spacing(1),
        // Any additional styles for each text block
    },
    rightCardWithBlueBackground: {
        backgroundColor: '#5B798E', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2), 
        height: '100%',  
        position: 'relative',
        borderRadius: '8px'
    },
    logoInRightCard: {
        maxWidth: '80%',  // or whatever size you'd prefer
        margin: theme.spacing(1) // some space around the image
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '1250px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        padding: '25px',
        borderRadius: '8px', 
    },   
      timelineContainer2: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '620px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        padding: '25px',
        borderRadius: '8px',
    },  
      timelineItemsContainer: {
        height: '1100px',   // To display 6 items at a time
        overflow: 'hidden',
        position: 'relative',  // To layer items beneath the headline if necessary
    },
        
      timelineRow: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '16px',
        animationName: "$moveUp",
        animationDuration: "60s",   // Adjust based on how fast you want them to move
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        '&::after': {
          content: '""',
          position: 'absolute',
          left: '30px', 
          top: '50%', // ← Position at the vertical center
          transform: 'translateY(95%)', // ← Center vertically
          height: '70px', // ← Adjust this for desired length
          width: '2px',
          background: '#ddd',
          zIndex: 0,
        },
        '&:last-child::after': { // ← This targets the last child's pseudo-element
            display: 'none', // ← This hides the line
          },
      },
      timelineLogo: {
        position: 'relative',
        zIndex: 1
      },
      logo: {
        width: '23px', 
        height: '23px',
        position: 'relative',
        zIndex: 2, 
        marginLeft: '10px'
      },
    
      timelineContent: {
        flex: 1,
        borderRadius: '8px',
        padding: '10px 20px',
        marginLeft: '10px'
      },
      timelineHeading: {
        color: '#A1A1A6',
        fontSize: '1rem',
        fontWeight: '800',
        paddingRight: '10%',
        paddingTop: '20px'
      },
      timelineText: {
        color: '#fff',
        fontSize: '0.8rem',
        fontWeight: '400',
        paddingTop: '5px'
      },
      bread: {
        color: 'white !important',
        paddingBottom: '10px',
        '& a': {
            color: 'white !important'
         }
      },
      screenerContainer: {
        backgroundColor: '#1E1F24',
        borderRadius: '8px',
        marginTop: '10px',
        padding: theme.spacing(2),
        border: '5px solid #161519'
      },
      iconWithText: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '@media (max-width:600px)': {
            // your mobile-specific styles here
            width: '100%',
            paddingBottom: '15px',  // example: reduce padding for mobile
        },
      },
      screenerButton: {
        backgroundColor: '#5B798E', // replace with your preferred color
        borderRadius: '5px', // rounded corners
        marginLeft: theme.spacing(1),
        '&:hover': {
          backgroundColor: 'YOUR_HOVER_COLOR_HERE', // replace with your preferred hover color
        },
    },
   
    "@keyframes moveUp": {
        from: { transform: "translateY(0%)" },
        to: { transform: "translateY(-1500%)" }  // move up by its own height
    },
    botWorkflowButtonText: {
        fontSize: '1em !important',
        fontWeight: '900 !important',
        textAlign: 'left !important',
        marginRight: '10px',
        marginLeft: '10px',
        display: 'block'
    },
    botWorkflowButtonTextSub: {
        fontSize: '0.8em !important',
        fontWeight: '300 !important',
        textAlign: 'left !important',
        marginRight: '10px',
        marginLeft: '10px',
        textTransform: 'none',
        display: 'block'
    },
    botDiv: {
        width: '100%',
        marginTop: '50px', 
        
        alignItems: 'center',
        
    },
    botDivReduced: {
        width: '100%', 
        marginTop: '50px'
    },
    botLeftRight5: {
        paddingLeft: '5%', 
        paddingRight: '5%'
    },
    botWorkflowMainButton: {
        border: '1px solid #5B798E !important', 
        color: 'white !important',   
        width: '35%', 
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        height: 90, 
        display: 'flex', 
        justifyContent: 'space-between !important',  // Ensures contents are spread out
        alignItems: 'center',  // Centers contents vertically
        paddingLeft: '3% !important',
        paddingRight: '3% !important',  // Adjusts padding. Change values as necessary
        boxShadow: '0 2px 5px rgba(100, 100, 100, 0.7), 0 10px 20px rgba(0, 0, 0, 0.1) !important',
        transition: 'box-shadow 0.3s ease !important',
        '@media (max-width:600px)': {
            width: '90%',
        },
    },
    botOuterGrid: {
        marginTop: '2% !important', 
        marginBottom: '2% !important', 
        marginLeft: 'auto !important', 
        marginRight: 'auto !important'
    },
    botSummary: {
        paddingTop: '50px', 
        paddingLeft: '20%', 
        paddingRight: '20%',
        '@media (max-width:600px)': {
            paddingLeft: '2%',
            paddingRight: '2%',
        },
    },
    botWorkflowHelper: {
        width: '60%', 
        marginTop: '2%', 
        marginBottom: '2%',
        '@media (max-width:600px)': {
            width: '100%',
        },
    },
    botWorkflowTextfield: {
        marginRight: '5px !important',
        width: '100% !important',
        borderRadius: '5px !important',
        border: '1px solid #5B798E !important',
        '& .MuiInputBase-input': {
          color: '#E0E0E0 !important',
          backgroundColor: '#1976D20A !important',
          paddingLeft: '3% ',
          paddingRight: '3%',
        },
        '& .MuiInputLabel-root': {
          color: '#B0B0B0 !important',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#E0E0E0 !important',
        }
      },
    botWorkflowButtonIcon: {
        fontSize: '3em', 
        marginRight: '18px',
        padding: '5px',
        background: 'linear-gradient(45deg, #5B798E 30%, #3B7994 90%)',  // Example gradient colors
        borderRadius: '50px'
    },
    botWorkflowMainSelect: {
        '& .MuiSelect-select': {
            border: '1px solid #5B798E',
            marginRight: '5px',
            color: 'white',
            height: '90 !important',
            alignItems: 'center',
            paddingLeft: '3%',
            paddingRight: '3%',
            width: '100% !important',
            borderRadius: 4,  
        },
        '& .MuiSelect-icon': {
            color: 'white',  // Adjusts the color of the dropdown icon
        }
    },
    customDropdownStyle: {
        backgroundColor: "#161519 !important",  
        display: 'block !important',
        alignItems: 'center',
        color: 'white !important'
    },
    menuItemStyle: {
        color: "white !important",
    },
    
    botWorkflowSignalButton: {
        backgroundColor: '#161519 !important', 
        color: 'white !important',   
        marginTop: '7% !important',
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        borderRadius: '8px !important',
        border: '1px solid #5B798E !important', 
        boxShadow: '0 2px 5px rgba(100, 100, 100, 0.7), 0 10px 20px rgba(0, 0, 0, 0.1) !important',
        transition: 'box-shadow 0.3s ease !important',
    },
    botWorkflowNavigationButton: {
        backgroundColor: '#161519 !important', 
        color: 'white !important', 
        height: '100% !important',  
        marginRight: 'auto !important',
        borderRadius: '8px !important',
        border: '1px solid #5B798E !important', 
        marginLeft: '2%',
        marginRight: '2%',
        marginTop: '40px !important',
        boxShadow: '0 2px 5px rgba(100, 100, 100, 0.7), 0 10px 20px rgba(0, 0, 0, 0.1) !important',
        transition: 'box-shadow 0.3s ease !important',
    },
    botBox: {
        marginTop: '50px',
        marginBottom: '50px',
        padding: '10px',
        paddingBottom: '50px',
        borderRadius: '8px'
    },
    botNoteIcon: {
        fontSize: '3em', 
        marginRight: '18px',
        padding: '5px',
        background: 'linear-gradient(45deg, #5B798E 30%, #3B7994 90%)',  
        borderRadius: '50px'
    },
    botWorkflowNavigationButton3: {
        backgroundColor: '#5B798E !important',
        color: '#FFFFFF !important',
        height: '58px',
        width: '20px !important',
        borderRadius: '8px',
        border: '1px solid #5B798E !important',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
      },
    botWorkflowNavigationButtonSpecial: {
        backgroundColor: '#161519 !important', 
        color: 'white !important', 
        height: '58px !important',  
        paddingTop: '3%',
        
        marginRight: 'auto !important',
        borderRadius: '8px !important',
        border: '1px solid #5B798E !important', 
        boxShadow: '0 2px 5px rgba(100, 100, 100, 0.7), 0 10px 20px rgba(0, 0, 0, 0.1) !important',
        transition: 'box-shadow 0.3s ease !important',
        '@media (max-width:600px)': {
            textAlign: 'center !important',
        },
    },
    botWorkflowNavigationButton2: 
    {
        backgroundColor: '#161519 !important', 
        color: 'white !important', 
        paddingTop: '3%',
        textTransform: 'none'
    },
    botBottomDiv: {
        marginTop: '50px',
        textAlign: 'center'
    },
    botWorkflowNextButton: {
        backgroundColor: '#5B798E !important', 
        color: 'white !important',   
    },
    botWorkflowTitleText: {
        fontSize: '1em !important',
        margin: '10px !important',
        textAlign: 'center !important',
        marginBottom: '70px !important'
    },
    botWorkflowTitleText2: 
    {
        fontSize: '1em !important',
        margin: '10px !important',
        textAlign: 'center !important',
        marginBottom: '40px !important'
    },
    root: 
    {
        display: 'flex',
        backgroundColor: '#161519'
    },
    drawerOpen: 
    {
        width: '100%',
        height: '100vh',
        transition: theme.transitions.create('height', 
        {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        borderRight: '0px solid !important',
        backgroundColor: '#161519',
        paddingTop: '2%',
        paddingLeft: '10px'
    },
    drawerClose: 
    {
        transition: theme.transitions.create('height', 
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        height: '0vh',  
        overflowX: 'hidden',
        width: '0px !important',
        backgroundColor: '#161519', 
        border: '0px'
    },
    contentHidden: 
    {
        display: 'none',
    },
    appBar: 
    {
        zIndex: 1000,
        border: '0px',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        paddingTop: '5px',
        paddingBottom: '5px'
    
    },
    mobileStickySelect: 
    {
        position: 'sticky',
        top: 'calc(100% + 50px)',  // You can adjust this value based on your specific layout
        zIndex: theme.zIndex.drawer,
        alignSelf: 'flex-start'
    },
    drawer: 
    {
        height: '100vh', 
        flexShrink: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
    },
    drawerPaper: 
    {
        height: '100vh',
    },
    drawerContainer: 
    {
        overflow: 'auto',
    },
      MuiDrawerPaperAnchorDockedLeft: {
        borderRight: '1px solid rgba(255, 255, 255, 0.12'
      },
      content: {
        flexGrow: 1,
        paddingLeft: '50px !important'
      },
      footer: {
        padding: theme.spacing(3),
        backgroundColor: '#161519',
        borderTop: '1px solid #333',
        display: 'block',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'right',
        color: 'white'
      },
      footerLink: {
        color: 'white',
        lineHeight: '1.5',
        fontWeight: '500',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      icon: {
          color: 'inherit',  
      },
      iconButton :{
        color: 'white',
        backgroundColor: '#5B798E',
        borderRadius: '50%',
        padding: '10px',
        marginLeft: '10px'
      },
      preferencesMulti: 
      {
        padding: '10px',
        fontSize: '16px',
        backgroundColor: '#333',
        color: '#fff',
        border: '1px solid #555',
        width: '100% !important ',
        height: '250px'
      },
      preferencesButton: {
        backgroundColor: '#007BFF',
        color: 'white', 
        border: 'none', 
        borderRadius: '5px', 
        paddingLeft: '40px', 
        paddingRight: '40px',
        paddingTop: '15px',
        paddingBottom: '15px',
        cursor: 'pointer',
      },
      dotsDiv: {
        display: 'flex !important', 
        justifyContent: 'center !important', 
        alignItems: 'center !important', 
        paddingBottom: '15px !important'
      },
      dotsSpan: {
        width: '10px !important', 
        height: '10px !important', 
        borderRadius: '50% !important', 
        margin: '0 5px !important',
      },
      bottomNav: {
        position: 'fixed',
        bottom: '0',
        width: '100%',
        zIndex: '1000', // Ensure it is above other content
      }
    }   
));

export default mainStyles;


export const List = styled(MuiList)({
    '.MuiListItem-button:hover': {
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        display: 'flex',
        color: 'white',
        fontWeight: '900',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '8px'
      },
      '.MuiListItem-button:active': {
        backgroundColor: 'rgba(30, 32, 37, 0.95)',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.1) !important',
        transition: 'box-shadow 0.3s ease',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '25px',
        borderRadius: '8px'
      },
  });
  