import React, { useState, useEffect, useContext } from "react";
import { Snackbar, Paper, Grid, Chip, Avatar, Typography, IconButton } from "@material-ui/core";
import mainStyles from '../styles/mainstyles';
import Alert from '@mui/material/Alert';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CloseIcon from '@material-ui/icons/Close'; // Import CloseIcon from Material-UI icons
import newUi from "../styles/newUi";
import { longColor, shortColor } from '../data/basics';
import CreateBot from "./bots/createBot";
import BotTable from "../elements/botTable";
import botBlofin from "../images/bot_blofin.png";
import botMexc from "../images/bot_mexc.png";
import botBinance from "../images/bot_binance.png";
import botBybit from "../images/bot_bybit.png";
import { AuthContext } from '../AuthContext';

function Bots() {
  const classes2 = mainStyles();
  const [botData, setBotData] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const { auth, logout } = useContext(AuthContext);
  let email = auth?.user?.email || '';
  const hasAccess = auth?.isAuthenticated;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const newUiClass = newUi();
  const handleUpdate = () => { setUpdateTrigger(prev => !prev); };
  
  useEffect(() => { window.scrollTo(0, 0); }, []);

  useEffect(() => {
    if (email  == '') { return; }
      fetch('/api/getBots', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email })
    })
    .then(response => response.json())
    .then(responseData => { setBotData(responseData.data);  });
  }, [email, updateTrigger]);
  
  const handleDelete = (row) => {
    if(window.confirm('Do you really want to delete this bot?')) {
        fetch('/api/deleteBot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
          symbol: row.symbol,
          direction: row.direction,
          signal: row.alert,
          timeframe: row.timeframe
        })
      })
      .then(response => response.json())
      .then(data => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
        if (data.message === 'Bot deleted successfully.') {
          setBotData(botData.filter(bot => bot.symbol !== row.symbol || bot.timeframe !== row.timeframe));
          handleUpdate();
        }
      })
      .catch(err => {
        setSnackbarMessage('Failed to delete bot.');
        setSnackbarOpen(true);
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setSnackbarOpen(false);
  };
    

    return (
        <>
        <div style={{paddingLeft: '30px', paddingTop:'15px', marginBottom: '25px'}}>
        <Typography className={newUiClass.componentTitle}>Bot Management</Typography>
        <Typography className={newUiClass.componentSubtitle}><i>Set up new and manage your existing trading bots. <br /> Integrated with the world's leading exchanges.</i></Typography>
        <Grid container spacing={2} style={{ width: '100%', marginBottom: '2%', marginLeft: 'auto', marginRight: 'auto', padding: '10px', }}>
        <Grid item xs={12} md={4}>
        <Grid container spacing={2} style={{ width: '100%'}}>
          <Grid item xs={4} md={4}>
            <a href="https://accounts.binance.com/register?ref=WU0WXFA0" target="_blank">
              <img style={{maxWidth:'100%' }}src={botBinance} />
            </a>
          </Grid>
          <Grid item xs={4} md={4}>
            <a href="https://partner.blofin.com/d/CryptoOS" target="_blank">
              <img style={{maxWidth:'100%' }}src={botBlofin} />
            </a>
          </Grid>
          <Grid item xs={4} md={4}>
            <a href="https://bybit.com" target="_blank">
              <img style={{maxWidth:'100%' }}src={botBybit} />
            </a>
          </Grid>
          
          </Grid>
        </Grid>
        </Grid>
        </div>
           <Grid container spacing={2} style={{ width: '100%', marginBottom: '2%', marginLeft: 'auto', marginRight: 'auto', padding: '10px', }}>
           <div style={{width: '100%', color: '#1a1a1a', fontWeight: '600', fontSize:'1.4em', textAlign: 'left', paddingLeft:'20px', paddingTop:'40px', marginBottom: '15px' }}>Set Up A New Bot</div><br />
           <CreateBot onEventTrigger={handleUpdate} />
           <div style={{width: '100%', color: '#1a1a1a', fontWeight: '600', fontSize:'1.4em', textAlign: 'left', paddingLeft:'20px', paddingTop:'40px', marginBottom: '15px' }}>Manage Your Bots</div><br />
          {(botData?.length == 0) && (
                <Grid item xs={12} md={12}>
                  <Paper className={classes2.myBoxNew} style={{ padding: '10px', position: 'relative', textAlign: 'center' }}> 
                      <SentimentVeryDissatisfiedIcon style={{color: '#CD5C5B'}}/>
                      <Typography style={{color: '#1a1a1a'}}><b>You don't have any active bots.</b></Typography>
                      <Typography style={{color: '#1a1a1a'}}> Set up your first bot now.</Typography>
                  </Paper>
              </Grid>
          )}
                {botData && botData.map((row, index) => {
                    const detailsArray = JSON.parse(row.details);
                    const details = detailsArray[0];
                    return (
                        <Grid key={index} item xs={12} md={6}>
                            <Paper className={classes2.myBoxNew} style={{ padding: '10px', position: 'relative' }}> {/* Add position: 'relative' */}
                           
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                        <img src={row.logo_url} alt={row.symbol} style={{ width: '50px', marginRight: '10px' }} />
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize:'1.3em', color: '#1a1a1a' }}>{row.symbol}</Typography>
                                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'right', justifyContent: 'center', color: '#1a1a1a', width: '50px', height: '50px', paddingTop: '12px', borderRadius: '10px' }}>
                                            <IconButton onClick={() => handleDelete(row)} style={{ marginTop: '15px', marginRight: '15px', position: 'absolute', top: 0, right: 0, backgroundColor: 'rgba(255,255,255,0.4' }}>
                                                <CloseIcon style={{width: '15px', height: '15px'}}/>
                                            </IconButton>
                                        </div>
                                    </div>                    
                                    <div style={{ marginTop: '5px', marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <Chip className={newUiClass.standardChip} style={{marginTop: '10px'}} label={`Chart: ${row.timeframe}`} />
                                        <Chip className={newUiClass.standardChip} style={{marginTop: '10px'}} label={`Signal: ${row.alert}`} />
                                        <Chip className={newUiClass.standardChip} style={{marginTop: '10px'}} label={`Order Amount (USD): ${details.amount}`} />
                                        <Chip className={newUiClass.standardChip} style={{marginTop: '10px', background: (details.direction == 'Short' ? shortColor : longColor)}} label={`Direction ${details.direction}`} />
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    )})}    
                </Grid>
                <BotTable />
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
            </>
        );
    }
    
    export default Bots;