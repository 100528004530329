import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Box, Chip } from '@material-ui/core';
import { Button } from '@mui/material';
import LinkIcon from '@material-ui/icons/Link';
import { formatTimestampShort } from "../functions/customFormat";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import SwapVertIcon from '@mui/icons-material/SwapVert';


const getButtonStyles = (isActive) => ({ marginRight: '5px', marginLeft: '10px', fontSize: '0.7em', fontWeight: '600', border: '0px',
    color: isActive ? '#3961FB' : 'grey', backgroundColor: isActive ? '#F0F6FF' : '#F8F8F8', borderColor: 'transparent', boxShadow: '0',
    '&:hover': { backgroundColor: '#F0F6FF', color: '#3961FB', border: '0px', },
});

const RenderSignals = ({ timeframe, signals }) => {
    const [filter, setFilter] = useState(null);

    const toggleFilter = (type) => {
        setFilter(prevFilter => prevFilter === type ? null : type);
    };

    const filteredSignals = filter ? signals.filter(signal => signal.type === filter) : signals;

    return (
        <>
            <Box style={{ marginBottom: '10px', marginTop: '15px' }}>
                <Button
                    onClick={() => toggleFilter('ta')}
                    sx={getButtonStyles(filter === 'ta')}
                    variant={filter === 'ta' ? 'contained' : 'outlined'}
                    startIcon={<ShowChartIcon style={{ fontSize: '16px' }} />}
                >
                    Technical Analysis
                </Button>
                <Button
                    onClick={() => toggleFilter('chart')}
                    sx={getButtonStyles(filter === 'chart')}
                    variant={filter === 'chart' ? 'contained' : 'outlined'}
                    startIcon={<CandlestickChartIcon style={{ fontSize: '16px' }} />}
                >
                    Chart Pattern
                </Button>
                <Button
                    onClick={() => toggleFilter('supres')} // New button for "Support/Resistance"
                    sx={getButtonStyles(filter === 'supres')}
                    variant={filter === 'supres' ? 'contained' : 'outlined'}
                    startIcon={<SwapVertIcon style={{ fontSize: '16px' }} />} // New icon for "Support/Resistance"
                >
                    Support/Resistance
                </Button>
            </Box>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: '#1a1a1a', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}></TableCell>
                            <TableCell style={{ color: '#1a1a1a', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}>Signal</TableCell>
                            <TableCell style={{ color: '#1a1a1a', textAlign: 'center', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}>Timeframe</TableCell>
                            <TableCell style={{ textAlign: 'center', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}>Direction</TableCell>
                            <TableCell style={{ color: '#1a1a1a', textAlign: 'right', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}>Profit Potential</TableCell>
                            <TableCell style={{ color: '#1a1a1a', textAlign: 'right', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}>Timestamp</TableCell>
                            <TableCell style={{ color: '#1a1a1a', textAlign: 'center', fontSize: '0.7em', fontWeight: '800', paddingTop: '0', paddingBottom: '0px' }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSignals.map((signal, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ color: '#1a1a1a', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                    {signal.type === 'ta' && <ShowChartIcon style={{ color: '#3558FF' }} />}
                                    {signal.type === 'chart' && <CandlestickChartIcon style={{ color: '#3558FF' }} />}
                                    {signal.type === 'supres' && <SwapVertIcon style={{ color: '#3558FF' }} />} {/* New icon for "Support/Resistance" */}
                                </TableCell>
                                <TableCell style={{ color: '#1a1a1a', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                    <b>{signal.trade_signal}</b>
                                </TableCell>
                                <TableCell style={{ color: '#1a1a1a', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                    {signal.timeframe}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                   {signal.direction &&
                                   <Chip
                                        label={signal.direction}
                                        style={{
                                            backgroundColor: signal.direction === 'Long' ? '#006400' : '#8B0000',
                                            fontWeight: '600',
                                            color: 'white',
                                            height: '20px',
                                            fontSize: '0.7em',
                                            borderRadius: '5px'
                                        }}
                                    />
                                    }
                                </TableCell>
                                <TableCell style={{ color: '#1a1a1a', textAlign: 'right', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                   {(signal.profit_potential) &&
                                    <span>{signal.profit_potential}%</span>
                                   }
                                </TableCell>
                                <TableCell style={{ color: '#1a1a1a', textAlign: 'right', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                    {formatTimestampShort(signal.timestamp)}
                                </TableCell>
                                <TableCell style={{ color: '#1a1a1a', textAlign: 'center', paddingTop: '0px', paddingBottom: '0px', fontSize: '0.9em' }}>
                                    {signal.unique_link != null && 
                                        <IconButton component="a" href={signal.unique_link} target="_blank" rel="noopener noreferrer">
                                            <LinkIcon />
                                        </IconButton>
                                    }
                                    {signal.unique_link == null && <div style={{ marginTop: '40px' }} />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RenderSignals;