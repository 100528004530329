import React, { useState } from 'react';
import { Grid, Paper, Link, Typography, Box, Tooltip, LinearProgress, Chip, IconButton } from '@mui/material';
import newUi from '../styles/newUi';
import { longColor, shortColor } from '../data/basics';
import { formatTimestampUTCOnlyHoursAndMinutes } from '../functions/customFormat';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { MinimizedTokenInfo } from '../forms/others/minimizedTokenInfo';
import InfoIcon from '@mui/icons-material/Info';
import mainStyles from '../styles/mainstyles';


export function BacktestTable({ signals, data }) {
  const classes2 = mainStyles();
  const newUiClass = newUi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState({});

  function determineDirection(signal) {
    let direction, chipColor;
    if(signal.signal_value == "Short") {
      direction = "Short";
      chipColor = shortColor;
    } else {
      direction = "Long";
      chipColor = longColor;
    }
    return { direction, chipColor };
  }

  return (
    <Grid container alignItems="flex-start" spacing={2} className={newUiClass.headerGrid}>
      {signals.map((row, index) => {
        const isFirstInRow = index % 3 === 0;
        const isThirdInRow = index % 3 === 2;
        return (
          <Grid item xs={12} md={4} key={row.symbol} style={{
            paddingLeft: (!isMobile && isFirstInRow) ? '0px' : '12px', 
            paddingRight: (!isMobile && isThirdInRow) ? '0px' : '12px',
            alignItems: 'stretch'  
          }}>
            <Paper className={classes2.myBoxNew} style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
              setExpandedRows(prevState => ({
              ...prevState,
              [row.symbol]: !prevState[row.symbol]
              }));
            }}>
              <div className={newUiClass.resultCardHeader} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexShrink: 0 }}>
                      <img src={row.logo_url} alt={row.symbol} style={{ width: "35px" }} />
                    </div>
                    <div style={{ flexGrow: 0, flexBasis: 'auto', marginLeft: "10px", marginRight: "10px" }}>
                      <Typography style={{ fontSize: '1em', lineHeight: '1.2em' }} className={classes2.myBoxText}>
                        <b>{row.symbol}</b><br />
                        <span style={{ color:'#AAAAAA', fontSize:'0.8em' }}>
                          {row.fullName}
                        </span>
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right", width: '100%' }}>
                      <Typography style={{ color: '#333', fontSize: '2em', fontWeight: '800' }}>
                        {row.backtest_value}%
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Box alignItems="left" style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, paddingTop: '10px' }}>
                      <Chip className={newUiClass.standardChip} style={{background: '#F5F7FE'}} label={row.trade_signal} />
                      <Chip className={newUiClass.standardChipColorless} style={{ background: row.signal_value === "Short" ? "darkred" : "darkgreen" }} label={row.signal_value}/>
                    </Box>
                  </div>
                  {expandedRows[row.symbol] && (
                    <>
                      <div style={{ marginTop: '15px' }}>
                        <Typography style={{ fontSize: '0.7em', color: '#333' }}><i>Historical Win Rate</i></Typography>
                        <Tooltip title={row.backtest_value}>
                        <LinearProgress style={{ width: '100%' }} classes={{ colorPrimary: newUiClass.colorPrimary, barColorPrimary: newUiClass.barColorPrimary }} variant="determinate" value={row.backtest_value} />
                        </Tooltip>
                       </div>
                      <div style={{ marginTop: '25px' }}>
                      <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Backtest Details</i></Typography>
                  <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', color: '#1A1A1A' }}>
                    The backtest results are based on {row.backtest_amount} historical signals on the the {row.timeframe} chart. 
                  </Typography>
                        <MinimizedTokenInfo token={row.symbol} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography className={classes2.detectedText} style={{ marginTop: '10px', fontSize: '0.8em', paddingLeft: '10px', marginBottom: '10px' }}>
                            Detected: {formatTimestampUTCOnlyHoursAndMinutes(row.created_at)} UTC
                          </Typography>
                          <div>
                            
                            <Link to={`/tokens/${row.symbol}`} style={{ textDecoration: 'none' }}>
                              <IconButton style={{ boxShadow: '#ebebeb 1px 1px 1px 1px' }} aria-label="account-balance">
                                <InfoIcon style={{ color: '#333' }} />
                              </IconButton>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      
    );
    
}


export default BacktestTable;

/*
backtest_value
backtest_amount
direction
trade_signal 
*/