import React from 'react';
import { Paper, LinearProgress, Typography, Tooltip } from '@mui/material';
import mainStyles from '../styles/mainstyles';
import { customFormat, formatTimestampUTC } from '../functions/customFormat';
import newUi from '../styles/newUi';


function SingleTokenForecast({ rows, symbol }) {
  const classes2 = mainStyles();
  const newUiClass = newUi();  
  const tokenData = rows.find(row => row.symbol === symbol);

  function MapeProgressBar({ mape }) {
    const progress = getProgress(mape);
    const color = getMapeColor(mape);
    const tooltipValue = getTooltipValue(mape);

    return (
        <Tooltip title={tooltipValue}>
          <LinearProgress style={{ width: '100%' }} variant="determinate" value={progress} sx={{ '& .MuiLinearProgress-barColorPrimary': { backgroundColor: color } }} />
        </Tooltip>
    );
}

  function getProgress(mape) {
    if (mape == null) { return 0; }
    if (mape < 2) { return 95; } 
    else if (mape < 4) { return 80; } 
    else if (mape < 6) { return 60; }
    else if (mape < 8) { return 40; } 
    else if (mape < 100) { return 20; } 
    else { return 0; }
    }

  function getTooltipValue(mape) {
      if (mape == null) { return "No data available"; }
      if (mape < 2) { return "Excellent Accuracy"; } 
      else if (mape < 4) { return "Good Accuracy"; } 
      else if (mape < 6) { return "Average Accuracy"; }
      else if (mape < 8) { return "Poor Accuracy"; } 
      else if (mape < 100) { return "Very Poor Accuracy"; } 
      else { return "n.a."; }
      }

  function interpolateColor(value, max, min, color1, color2) {
      const ratio = Math.max(Math.min((value - min) / (max - min), 1), 0);
      const hex = function(x) {
          x = x.toString(16);
          return (x.length === 1) ? '0' + x : x;
      };
  
    let r = Math.ceil(parseInt(color2.substring(1, 3), 16) * ratio + parseInt(color1.substring(1, 3), 16) * (1 - ratio));
    let g = Math.ceil(parseInt(color2.substring(3, 5), 16) * ratio + parseInt(color1.substring(3, 5), 16) * (1 - ratio));
    let b = Math.ceil(parseInt(color2.substring(5, 7), 16) * ratio + parseInt(color1.substring(5, 7), 16) * (1 - ratio));
    return `#${hex(r)}${hex(g)}${hex(b)}`;
  }
  

  function getMapeColor(mape) {
    const minMape = 2;   // Best performance boundary
    const maxMape = 100; // Worst performance boundary
    const colorGood = '#006400'; // Dark green
    const colorPoor = '#8B0000'; // Dark red
    return interpolateColor(mape, maxMape, minMape, colorGood, colorPoor);
  }

  return (
    <div>
      {tokenData && (
        <Paper className={newUiClass.tokenPaperNew} >
          <Typography className={classes2.myBoxText} style={{fontWeight:'400', paddingTop:'5px', paddingBottom: '10px'}}>Forecast Data</Typography>
          <div style={{ marginTop: '5px' }}>
            <Typography style={{fontSize: '0.7em', color: '#333'}}><i>24H Forecast</i></Typography>
            <Typography className={classes2.myBoxTextThree} style={{  fontSize: '0.9em', fontWeight: '500' }}>
              <span style={{ paddingRight: '5px', fontWeight: '600' }}>{tokenData['24_hour_forecast']}</span>
            </Typography>
          </div>
          <div style={{ marginTop: '5px' }}>
            <Typography style={{fontSize: '0.7em', color: '#333'}}><i>7 Day Forecast</i></Typography>
            <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(tokenData.sevenDayPercentageDiff) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
              <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(tokenData.seven_day_forecast)}</span>({customFormat(tokenData.sevenDayPercentageDiff)}%)
            </Typography>
            <Tooltip titel={tokenData.mape_7}>
              <MapeProgressBar mape={tokenData.mape_7} />
            </Tooltip>
          </div>
          <div style={{ marginTop: '25px', marginBottom: '35px' }}>
            <Typography style={{fontSize: '0.7em', color: '#333'}}><i>30 Day Forecast</i></Typography>
            <Typography className={classes2.myBoxTextThree} style={{ color: customFormat(tokenData.thirtyDayPercentageDiff) >= 0 ? '#639963' : '#D14E45', fontSize: '0.9em', fontWeight: '500' }}>
              <span style={{paddingRight: '5px', fontWeight: '600'}}>${customFormat(tokenData.thirty_day_forecast)}</span>({customFormat(tokenData.thirtyDayPercentageDiff)}%)
            </Typography>
            <Tooltip titel={tokenData.mape_30}>
              <MapeProgressBar mape={tokenData.mape_30} />
            </Tooltip>
            <Typography className={classes2.detectedText} style={{  marginTop: '5px', fontSize: '0.8em',  marginBottom: '5px' }}>
              Last Updated: {formatTimestampUTC(tokenData.timestamp)} UTC
            </Typography>
          </div>
        </Paper>  
      )}
    </div>
  );
}

export default SingleTokenForecast;