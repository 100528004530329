// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDC_NAJzi78KYaG8DdZKZ-2hMeTz3ibrGc",
  authDomain: "cryptoknowledge-3fc6c.firebaseapp.com",
  projectId: "cryptoknowledge-3fc6c",
  storageBucket: "cryptoknowledge-3fc6c.appspot.com",
  messagingSenderId: "729231507465",
  appId: "1:729231507465:web:3a4d23362961a3227078d8",
  measurementId: "G-KZLSBQWSBG"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { app, messaging, auth, firestore };
