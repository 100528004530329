
export const getChartData = async (symbol, timeframe) => {
  let response = '';
    try {
      if (timeframe == '1D') {
       response = await fetch(`/api/getChartData/${encodeURIComponent(symbol)}`);
      }
      else if (timeframe == '1H') 
       {
        response = await fetch(`/api/getChartDataOneHour/${encodeURIComponent(symbol)}`);
      }
      else { return null; }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Could not fetch the chart data: ", error);
      throw error;
    }
  };
  