import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Button, CircularProgress, Chip } from '@mui/material';
import newUi from "../../styles/newUi";
import { useNavigate } from 'react-router-dom';
import { getChartData } from '../../functions/getChartData';
import MinimalLineChart from '../../functions/lineChartMinimal';
import { Typography } from '@material-ui/core';
import FilterListIcon from '@mui/icons-material/FilterList';
import { addMultiplePrices2 } from '../../functions/addPrices';


const paperStyles = { boxShadow: 'none', margin: 'auto', width: '100%', borderRadius: '5px', overflow: 'hidden', background: 'rgba(255,255,255,0)', };

function formatMarketCap(marketCap) {
  if (marketCap === null || marketCap === undefined) { return '-'; }
  return marketCap.toLocaleString('en-US');
}

const Visualization = ({ symbol, logo, marketCap, weeklyLong, weeklyShort, dailyLong, dailyShort, fourHourLong, fourHourShort, hourlyLong, hourlyShort, curated, chartSignals }) => {
  const classes = newUi();
  const navigate = useNavigate();
  const handleClick = () => { navigate(`/tokens/${symbol}`); };
  const timeframe = '1D';
  const [chartData, setChartData] = useState(null);
  const [priceData, setPriceData] = useState({ price: null, pctChange: null, fullName: '' });

  // Fetch chart data and price data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [chartDataResult, priceDataResult] = await Promise.all([
          getChartData(symbol, timeframe),
          addMultiplePrices2([{ symbol }])
        ]);

        setChartData(chartDataResult);

        if (priceDataResult.length > 0) {
          const { price, pctChange, fullName } = priceDataResult[0];
          setPriceData({ price, pctChange, fullName });
        } else {
          console.error(`Price data for ${symbol} is not available.`);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [symbol]);

  const renderChips = (longCount, shortCount) => (
    <>
      {(longCount > 0) && <Chip label={longCount} className={classes.standardChipColorless2} style={{background: '#D4EDDA', color: 'rgb(120,120,120)', fontWeight: '600'}}/> }
      {(shortCount > 0) && <Chip label={shortCount} className={classes.standardChipColorless2} style={{background: '#F8D7DA', color: 'rgb(120,120,120)', fontWeight: '600'}}/>}
    </>
  );

  return (
    <Grid item container direction="row" onClick={handleClick} alignItems="center" className={classes.gridItem} style={{ cursor: 'pointer' }}>
      <Grid item xs={1} md={1} className={classes.tableCellStyles2} style={{ fontWeight: '500', textAlign: 'left' }}>
        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
          <img style={{ width: '25px', marginRight: '8px' }} src={logo} alt={`${symbol} logo`} />
          <Typography style={{ fontWeight: '500', fontSize: '1em', paddingTop: '3px' }}>{symbol}</Typography>
        </div>
      </Grid>
      <Grid item xs={2} md={2} className={classes.tableCellStylesRight2} style={{ fontWeight: 500, fontSize: '0.9em' }}>
      ${priceData.price}
      {priceData.pctChange && (
        <span style={{ color: priceData.pctChange < 0 ? '#FF0000' : '#008000', marginLeft: '5px' }}>
          {priceData.pctChange < 0 ? '▼' : '▲'} {Math.abs(priceData.pctChange)}%
        </span>
      )}
    </Grid>
      <Grid item xs={2} md={2} className={classes.tableCellStylesRight2} style={{fontWeight: 500, fontSize: '0.9em'}}>
        {formatMarketCap(marketCap)}
      </Grid>
      <Grid item xs={1} md={1} className={classes.tableCellStylesRight2} style={{textAlign: 'center'}}>
        {renderChips(weeklyLong, weeklyShort)}
      </Grid>
      <Grid item xs={1} md={1} className={classes.tableCellStylesRight2} style={{textAlign: 'center'}}>
        {renderChips(dailyLong, dailyShort)}
      </Grid>
      <Grid item xs={1} md={1} className={classes.tableCellStylesRight2} style={{textAlign: 'center'}}>
        {renderChips(fourHourLong, fourHourShort)}
      </Grid>
      <Grid item xs={1} md={1} className={classes.tableCellStylesRight2} style={{textAlign: 'center'}}>
        {(chartSignals > 0) &&
        <Chip label={chartSignals} className={classes.standardChipColorless2} style={{background: '#EDEDED', color: 'rgb(120,120,120)', fontWeight: '600'}}/>
        }
      </Grid>
      <Grid item xs={1} md={1} className={classes.tableCellStylesRight2} style={{textAlign: 'center'}}>
        {(curated > 0) &&
        <Typography> 🔥<span style={{fontWeight: '600', paddingLeft:'3px', fontSize: '0.9em', color: '#1a1a1a'}}> {curated}</span></Typography>}
      </Grid>
      <Grid item xs={2} md={2} className={classes.tableCellStylesRight2} style={{textAlign: 'center', padding: '0px'}}>
        <div style={{height: '55px', textAlign: 'center'}}>   
          {chartData ? <MinimalLineChart data={chartData}  /> : <p>Loading...</p>}
        </div>
      </Grid>
    </Grid>
  );
};


const MainOverview = () => {
  const [allData, setAllData] = useState([]);
  const [visibleRows, setVisibleRows] = useState(50);
  const [done, setDone] = useState(false);
  const [filters, setFilters] = useState({ '1W': false, '1D': false, '4H': false, 'Curated': false, 'ChartPattern': false });
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc'); 
  const classes = newUi();
  const handleLoadMore = () => {  setVisibleRows((prevVisibleRows) => prevVisibleRows + 50); };

  const toggleFilter = (filterKey) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey]
    }));
  };

  const filteredData = allData.filter(data => {
    return (
      (!filters['1W'] || (data.count_1W_Long > 0 || data.count_1W_Short > 0)) &&
      (!filters['1D'] || (data.count_1D_Long > 0 || data.count_1D_Short > 0)) &&
      (!filters['4H'] || (data.count_4H_Long > 0 || data.count_4H_Short > 0)) &&
      (!filters['Curated'] || data.messageCount > 0) &&
      (!filters['ChartPattern'] || data.screenerCount > 0)
    );
  });

  const handleSort = (column) => {
    let newSortOrder = 'asc';
    if (sortColumn === column && sortOrder === 'asc') {  newSortOrder = 'desc'; }
    setSortColumn(column);
    setSortOrder(newSortOrder);

    const sortedData = [...allData].sort((a, b) => {
      let aValue, bValue;

      if (column === '1W') {
        aValue = a.count_1W_Long + a.count_1W_Short;
        bValue = b.count_1W_Long + b.count_1W_Short;
      } else if (column === '1D') {
        aValue = a.count_1D_Long + a.count_1D_Short;
        bValue = b.count_1D_Long + b.count_1D_Short;
      } else if (column === '4H') {
        aValue = a.count_4H_Long + a.count_4H_Short;
        bValue = b.count_4H_Long + b.count_4H_Short;
      } else {
        aValue = a[column];
        bValue = b[column];
      }

      if (newSortOrder === 'desc') { return aValue > bValue ? 1 : -1;
      } else { return aValue < bValue ? 1 : -1; }
    });
    setAllData(sortedData);
  };

  useEffect(() => {
    fetch(`/api/get-trade-signals-summary`)
      .then((res) => res.json())
      .then((tradeData) => {
        if (tradeData.error) {
          console.error(tradeData.error);
        } else {
          const filteredTradeData = tradeData.filter(
            (item) =>
              item.cmc_market_cap !== null &&
              item.cmc_market_cap !== undefined &&
              item.cmc_market_cap !== '' &&
              item.cmc_market_cap !== 0
          );
          fetch(`/api/get-message-counts`)
            .then((res) => res.json())
            .then((messageData) => {
              if (messageData.error) {
                console.error(messageData.error);
              } else {
                fetch(`/api/get-screener-counts`)
                  .then((res) => res.json())
                  .then((screenerData) => {
                    if (screenerData.error) {
                      console.error(screenerData.error);
                    } else {
                      const mergedData = filteredTradeData.map(item => ({
                        ...item,
                        messageCount: messageData[item.symbol] || 0, // Add messageCount to each item
                        screenerCount: screenerData[item.symbol] || 0 // Add screenerCount to each item
                      }));

                      setAllData(mergedData);
                      setDone(true);
                      console.log(mergedData);
                    }
                  })
                  .catch(error => {  console.error('Error fetching screener counts:', error);});
              }
            })
            .catch(error => {console.error('Error fetching message counts:', error);});
        }
      })
      .catch(error => {console.error('Error fetching trade signals summary:', error); });
  }, []);

  if (!done) { return ( <div> <CircularProgress style={{ color: '#fff', marginTop: '5px', marginLeft: 'auto', marginRight: 'auto' }} /></div>);}

  return (
    <div style={{ marginLeft: '15px', marginTop: '50px' }}>
      <Box style={{ marginBottom: '10px',  }}>
        <Button  onClick={() => toggleFilter('1W')}  sx={getButtonStyles(filters['1W'])} variant={filters['1W'] ? 'contained' : 'outlined'} startIcon={<FilterListIcon style={{ fontSize: '16px' }} />}>1W</Button>
        <Button onClick={() => toggleFilter('1D')} sx={getButtonStyles(filters['1D'])} variant={filters['1D'] ? 'contained' : 'outlined'} startIcon={<FilterListIcon style={{ fontSize: '16px' }} />}>1D</Button>
        <Button onClick={() => toggleFilter('4H')} sx={getButtonStyles(filters['4H'])} variant={filters['4H'] ? 'contained' : 'outlined'} startIcon={<FilterListIcon style={{ fontSize: '16px' }} />}>4H</Button>
        <Button onClick={() => toggleFilter('Curated')} sx={getButtonStyles(filters['Curated'])} variant={filters['Curated'] ? 'contained' : 'outlined'} startIcon={<FilterListIcon style={{ fontSize: '16px' }} />}>Curated</Button>
        <Button onClick={() => toggleFilter('ChartPattern')} sx={getButtonStyles(filters['ChartPattern'])} variant={filters['ChartPattern'] ? 'contained' : 'outlined'} startIcon={<FilterListIcon style={{ fontSize: '16px' }} />}>Chart Pattern</Button>
      </Box>

      <Paper elevation={3} style={paperStyles}>
      <Box style={{ overflowX: 'auto' }}> {/* Enable horizontal scrolling */}

        <Grid container spacing={2} style={{ marginBottom: '5px', marginTop: '5px', paddingRight: '5px', paddingLeft: '5px', minWidth: '1200px' }}>
          
          <Grid item xs={1} md={1} className={classes.tableFrontPageStyle} onClick={() => handleSort('symbol')} style={{ cursor: 'pointer' }}>
            Token
          </Grid>
          <Grid item xs={2} md={2} className={classes.tableFrontPageStyle} style={{ textAlign: 'right',}}>
            Price
          </Grid>
          <Grid item xs={2} md={2} className={classes.tableFrontPageStyle} onClick={() => handleSort('cmc_market_cap')} style={{ textAlign: 'right', cursor: 'pointer' }}>
            Market Cap
          </Grid>
          <Grid item xs={1} md={1} className={classes.tableFrontPageStyle} onClick={() => handleSort('1W')} style={{ textAlign: 'center', cursor: 'pointer' }}>
            1W
          </Grid>
          <Grid item xs={1} md={1} className={classes.tableFrontPageStyle} onClick={() => handleSort('1D')} style={{ textAlign: 'center', cursor: 'pointer' }}>
            1D
          </Grid>
          <Grid item xs={1} md={1} className={classes.tableFrontPageStyle} onClick={() => handleSort('4H')} style={{ textAlign: 'center', cursor: 'pointer' }}>
            4H
          </Grid>
          <Grid item xs={1} md={1} className={classes.tableFrontPageStyle} style={{ textAlign: 'center',  }}>
            Chart Pattern
          </Grid>
          <Grid item xs={1} md={1} className={classes.tableFrontPageStyle} style={{ textAlign: 'center',  }}>
            Curated
          </Grid>
          <Grid item xs={2} md={2} className={classes.tableFrontPageStyle} style={{ textAlign: 'center' }}>Last 3 Days</Grid>

          {filteredData.slice(0, visibleRows).map((data, index) => (
            <Visualization
              key={index}
              symbol={data.symbol}
              logo={data.logo_url}
              marketCap={data.cmc_market_cap}
              weeklyLong={data.count_1W_Long}
              weeklyShort={data.count_1W_Short}
              dailyLong={data.count_1D_Long}
              dailyShort={data.count_1D_Short}
              fourHourLong={data.count_4H_Long}
              fourHourShort={data.count_4H_Short}
              hourlyLong={data.count_1H_Long}
              hourlyShort={data.count_1H_Short}
              curated={data.messageCount}
              chartSignals={data.screenerCount}
            />
          ))}
        </Grid>
        {visibleRows < filteredData.length && (
          <Box textAlign="center" my={2}>
            <Button variant="contained" color="primary" onClick={handleLoadMore}>
              Load More
            </Button>
          </Box>
        )}
        </Box>
      </Paper>
    </div>
  );
};

const getButtonStyles = (isActive) => ({
  marginRight: '5px', 
  marginLeft: '10px',
  fontSize: '0.7em', 
  fontWeight: '600',
  border: '0px',
  color: isActive ? '#3961FB' : 'grey',
  backgroundColor: isActive ? '#F0F6FF' : '#F8F8F8',
  borderColor: 'transparent',
  boxShadow: '0',
  
  '&:hover': {
    backgroundColor: '#F0F6FF',
    color: '#3961FB',
    border: '0px',
  },
});

export default MainOverview;
