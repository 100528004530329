import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {  Grid, Box, Link } from '@material-ui/core';
import { AuthContext } from '../AuthContext';
import newUi from '../styles/newUi';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RecommendedFeatures from './dashboards/recommendedFeatures';
import LatestFiveTrades from './dashboards/latestTrades';
import MainOverview from './dashboards/mainOverview';
import BullBearIndex from './dashboards/bullBearIndex';
import LatestBlogPosts from './dashboards/latestBlogPosts';


function NewStart() {
  const classes2 = newUi();
  const [reloadKey, setReloadKey] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { auth } = useContext(AuthContext);
  let email = auth?.user?.email || '';

  const [loading, setLoading] = useState(true);
  const [componentsLoaded, setComponentsLoaded] = useState({
    adsBox: false,
    latestTrades: false
  });

  const hasLoadedRef = useRef({
    adsBox: false,
    latestTrades: false
  });

  const handleComponentLoaded = useCallback((componentName) => {
    if (!hasLoadedRef.current[componentName]) {
      hasLoadedRef.current[componentName] = true;
      setComponentsLoaded((prevState) => ({
        ...prevState,
        [componentName]: true,
      }));
    }
  }, []);

  useEffect(() => {
    const allLoaded = Object.values(componentsLoaded).every((loaded) => loaded);
    if (allLoaded) {
      setLoading(false);
    }
  }, [componentsLoaded]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div key={reloadKey} className={classes2.newStartPadding} style={{ paddingTop: '0px', display: 'flex', flexDirection: 'row' }}>
      <Grid container spacing={2} className={classes2.newStartMargin} style={{marginTop: '30px'}}>
        <Grid item xs={12} md={4}>
          <BullBearIndex />
        </Grid>
        <Grid item xs={12} md={4}>
          <LatestBlogPosts />
        </Grid>
        <Grid item xs={12} md={4}>
          <LatestFiveTrades onLoad={() => handleComponentLoaded('latestTrades')} />
        </Grid>
         <MainOverview />
      </Grid>
    </div>
  );
}

export default NewStart;
