import React, { useState, useEffect } from "react";
import { Box, Chip, Typography } from "@material-ui/core";
import mainStyles from '../../styles/mainstyles';
import { getPrice } from '../../functions/cryptoPrices';
import { useParams } from 'react-router-dom';
import { createMappings, emaMappings } from '../../data/tokensightData';
import { longColor, shortColor } from '../../data/basics';
import { getChartData } from '../../functions/getChartData';
import newUi from "../../styles/newUi";
import LineChart from "../../functions/lineChart";
import LinearProgress from '@mui/material/LinearProgress';

export function MinimizedTokenInfo(props) {
    const newUiClass = newUi();
    const classes2 = mainStyles();
    const [chartData, setChartData] = useState(null);
    const { trendClassMapping, adxClassMapping, rsi1ClassMapping, rsiClassMapping, macdClassMapping, patternClasseMapping } = createMappings(classes2);
    const [coreData, setCoreData] = useState([]);
    const [coreData2, setCoreData2] = useState([]);
    const [supportResistanceData, setSupportResistanceData] = useState([]);
    const [supportResistanceData2, setSupportResistanceData2] = useState([]);
    const { tokenSymbol } = useParams();
    const [dataReady, setDataReady] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const token = props.token;  // Use props.token directly
    const [fullData, setFullData] = useState([]);
    let timeframe1 = '1D';

    useEffect(() => {
        const symbol = token;
        const timeframe = timeframe1;
        getChartData(symbol, timeframe)
          .then(data => {  setChartData(data); })
          .catch(error => {  console.error(error); });
    }, [token]);

    function EMAGridItem({ label, value, trendMapping }) { return (  <Chip label={label} className={trendMapping[value]} /> ); }

    useEffect(() => {
        const symbol = token;
        const timeframe = '1D';
        getChartData(symbol, timeframe)
          .then(data => {  setChartData(data); })
          .catch(error => {  console.error(error); });
    }, [token]);

    useEffect(() => {
        setIsLoading1(true);
        Promise.all([
            fetch(`/api/single-indicators-daily?token=${token}`).then((res) => res.json()),
            fetch(`/api/single-supportResistance?token=${token}`).then((res) => res.json())
        ]).then(([indicatorsData, supportResistanceData]) => {
            if (indicatorsData.error) { console.error(indicatorsData.error); } 
            else { setCoreData(indicatorsData); }
            if (supportResistanceData.error) { console.error(supportResistanceData.error); } 
            else {
                setSupportResistanceData(supportResistanceData);
                setSupportResistanceData2(supportResistanceData);
            }
            const core = indicatorsData;
            setCoreData2(core);
            addPrices(token);
            setDataReady(true);
        }).catch((error) => { console.error('Error fetching data:', error); });
    }, [token]);

    const addPrices = async (symbol) => {
        const priceData = await getPrice([symbol]);  
        const price = priceData[symbol].price;
        const pctChange = priceData[symbol].percentChange24h;
        const fullName = priceData[symbol].name;
        const addedPrice = { symbol: symbol, price: price, pctChange: pctChange, fullName: fullName };
        setFullData(addedPrice);  
    };

    return (
        <>
        {(!dataReady) && <LinearProgress />}
        {(dataReady) &&
            <>
                <div style={{marginTop: '15px'}}>
                    <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Trend Indicator</i></Typography>
                    {coreData2 && coreData2.length > 0 ? (
                        emaMappings.map(mapping => (
                            <EMAGridItem key={mapping.key} label={mapping.label} value={coreData2[0][mapping.key]} trendMapping={trendClassMapping} />
                        ))
                    ) : null}
                    {(coreData2.length > 0) && <Chip label={`ADX: ${coreData2[0].adx}`} className={adxClassMapping[coreData2[0].adx]} />}
                </div>
                <div style={{marginTop: '25px'}}>   
                    <Typography style={{fontSize: '0.7em', color: '#333'}}><i>Momentum Indicator</i></Typography>     
                    {(coreData2.length > 0) && <Chip label={'RSI'} className={rsi1ClassMapping[coreData2[0].rsi]} />}
                    {(coreData2.length > 0) && <Chip label={'MACD'} className={macdClassMapping[coreData2[0].macd]} />}
                    {(coreData2.length > 0) && <Chip label={'Stochastic Oscillator'} className={rsiClassMapping[coreData2[0].stochastic_oscillator]} />}
                    {(coreData2.length > 0) && <Chip label={'WilliamsR'} className={rsiClassMapping[coreData2[0].willr]} />}
                    {(coreData2.length > 0) && <Chip label={'MFI'} className={rsiClassMapping[coreData2[0].mfi]} />}
                    {(coreData2.length > 0) && <Chip label={'Stochastic RSI'} className={rsi1ClassMapping[coreData2[0].stoch_rsi]} />}    
                </div>
                <div style={{marginTop: '25px'}}>   
                    <Typography style={{fontSize: '0.7em', color: '#333', marginBottom: '10px'}}><i>Performance</i></Typography>     
                    <Chip label={`7D Change: ${(coreData2.length > 0) && `${coreData2[0].change_7d}%`}`}
                      className={newUiClass.standardChip1}
                      style={{  backgroundColor: (coreData2.length > 0) ? (coreData2[0].change_7d < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`30D Change: ${(coreData2.length > 0) && `${coreData2[0].change_30d}%`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].change_30d < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`365D Change: ${(coreData2.length > 0) && `${coreData2[0].change_365d}%`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].change_365d < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`1Y High: ${(coreData2.length > 0) && `${coreData2[0].highest_value}`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].highest_value < 0 ? shortColor : longColor) : 'transparent',}}
                    />
                    <Chip label={`1Y Low: ${(coreData2.length > 0) && `${coreData2[0].lowest_value}`}`}
                        className={newUiClass.standardChip1}
                        style={{ backgroundColor: (coreData2.length > 0) ? (coreData2[0].lowest_value < 0 ?shortColor : longColor) : 'transparent',}}
                    /> 
                </div>
                <div style={{height: '300px', marginTop: '30px', paddingBottom: '30px'}}>
                    <Typography style={{fontSize: '0.7em', color: '#333', marginBottom: '10px' }}><i>Price Chart</i></Typography>     
                    {chartData ? <LineChart data={chartData}  /> : <p>Loading...</p>}
                </div>
            </>
        }
        </>
    );
}
